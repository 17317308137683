import * as React from 'react';
import styled from 'styled-components';
import CatalogMenuHome from '../../components/Menu/MenuComponents/CatalogHome/CatalogMenuHome';
import SortByFilter from '../../components/Menu/MenuComponents/SortByFilter/SortByFilter';
import FilterBy from '../../components/Menu/MenuComponents/FilterBy/FilterBy';
import { RouteComponentProps } from 'react-router';

const Root = styled.div`
  margin-top: 50px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const FiltersContainer = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
`;

const MenuContentContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
`;

type Props = RouteComponentProps<{id: string}>;

class CatalogMenuScene extends React.Component<Props> {
  public render () {
    return (
      <Root>
        <FiltersContainer>
          {/*<BrowseData id={this.props.match.params.id}/>*/}
          <SortByFilter/>
          <FilterBy/>
        </FiltersContainer>
        <MenuContentContainer>
          <CatalogMenuHome/>
        </MenuContentContainer>
      </Root>
    )
  }
}

export default CatalogMenuScene;
