const sortObjectStringsByKey = (key: string) => <T>(dataA: T, dataB: T) => {
  const a = typeof dataA[key] === 'string' ? dataA[key].toLowerCase() : dataA[key];
  const b = typeof dataB[key] === 'string' ? dataB[key].toLowerCase() : dataB[key];

  if (a === '' || a === null) { return 1; }
  if (b === '' || b === null) { return -1; }
  if (a === b) { return 0; }
  return a < b ? -1 : 1;
};

export default sortObjectStringsByKey;
