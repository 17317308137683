import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { landingPageActions } from './actions';
import { TreeMapDataDto } from '../../types/dto/TreeMapDataDto';
import mapTreeMapDataDtoToTreeMapData from './utils/mapTreeMapDataDtoToTreeMapData';
import directAPI from '../../services/directServices';
import { AxiosResponse } from 'axios';

export function* initializeTreeMapData() {
  try {
    const treeMapDataDto: AxiosResponse<TreeMapDataDto[]> = yield call(directAPI.fetchLandingPageTreeMapData);
    const treeMapData = yield mapTreeMapDataDtoToTreeMapData(treeMapDataDto.data);
    yield put(landingPageActions.addTreeMapData(treeMapData));
  } catch (e) {
    console.error(e);
  }
}

export default function* landingPageSagaWatcher() {
  yield all([
    takeLatest(getType(landingPageActions.getTreeMapData), initializeTreeMapData),
  ]);
}
