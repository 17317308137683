import * as React from 'react';
import Modal from './Modal';
import styled from 'styled-components';
import color from '../../../utils/color';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import ContentSearch from '../../AdminPanel/adminPanel/ContentSearch';
import { SortOrder } from '../../../utils/enums';
import SortUpIcon from '../../../icons/SortUpIcon';
import SortDownIcon from '../../../icons/SortDownIcon';
import MinusIcon from '../../../icons/MinusIcon';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Header = styled.div`
  padding: 15px;
  font-size: 20px;
  color: ${color.TEXT_PRIMARY};
`;

const SearchContainer = styled.div`
  margin-top: 15px;
  margin-right: 40px;
  font-size: 16px;
  font-weight: lighter;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  margin: 20px 10px 10px 10px;

  & > button {
    margin-left: 7px;
  }
`;

const ListTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ListTitle = styled.div`
  margin: 0 15px 5px 15px;
  font-size: 18px;

  svg {
    color: ${color.PRIMARY};
    font-size: 16px;
  }
`;

const ClearSelect = styled.div`
  font-size: 14px;
  color: ${color.PRIMARY};
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 15px;
  cursor: pointer;

  svg {transform: translateY(2px);}
`;

const ListContainer = styled.div`
  padding-top: 5px;
  border-top: ${color.NEUTRAL} solid 1px;
  border-bottom: ${color.NEUTRAL} solid 1px;
  margin: 0 15px;
  overflow-y: scroll;
  font-weight: lighter;
`;

const ListItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 5px;

  &:last-child {
    margin-bottom: 5px;
  }

  p {
    font-size: 16px;
    padding-right: 10px;
  }

  div {
    transform: translateY(-3px);
  }
`;

interface Props {
  name: string,
  onClose: () => void,
  onListItemClick: (id: string | number) => () => void,
  list: Array<{selected: boolean, name: string, id: string | number}>
  onClearSelectClick: () => void,
  search: string,
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  sortOrder: SortOrder,
  onSortClick: () => void,
}

const CatalogSelectModal: React.FC<Props> =
  ({ onClose, name, list, search, onSearchChange, onListItemClick, onClearSelectClick, sortOrder, onSortClick }) => (
  <Modal isOpen={true} closeButton={true} onClose={onClose} width={800}>
    <HeaderContainer>
      <Header>Select {name}</Header>
      <SearchContainer>
        {name}:
        <ContentSearch type="search" value={search} onChange={onSearchChange} placeholder="Search" width={350} style={{ marginLeft: '5px' }}/>
      </SearchContainer>
    </HeaderContainer>
    <ListTitleContainer>
      <ListTitle>
        List of {name} {sortOrder === SortOrder.ASCENDING ? <SortUpIcon onClick={onSortClick}/> : <SortDownIcon onClick={onSortClick}/>}
      </ListTitle>
      <ClearSelect onClick={onClearSelectClick}><MinusIcon /> Clear Select</ClearSelect>
    </ListTitleContainer>
    <ListContainer>
      {list.map(({ selected, name: itemName, id }) =>
        (<ListItem key={itemName + id}>
          <div><Checkbox selected={selected} onClick={onListItemClick(id)}/></div>
          <p>{itemName}</p>
        </ListItem>))}
    </ListContainer>
    <ButtonContainer>
      <Button onClick={onClose} filled>Ok</Button>
    </ButtonContainer>
  </Modal>
);

export default CatalogSelectModal;
