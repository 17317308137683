import { Area as AreaDto, TreeMapDataDto } from '../../../types/dto/TreeMapDataDto';
import { Area, Product, TreeMapData } from '../../../types/TreeMapData';

const mapTreeMapDataDtoToTreeMapData = (data: TreeMapDataDto[]): TreeMapData => {
  const products: Product[] = data.map((productDto): Product => ({
    codeName: productDto.Name.replace(/[^a-zA-Z0-9]/g, ''),
    name: productDto.Name.slice(42),
    size: Number(String(productDto.Areas.reduce((value, area) => area.Value + value, 0)).slice(0, 3)),
    areas: productDto.Areas.map((area: AreaDto): Area => ({
      id: area.Name, value: area.Value, year: area.Year,
      yoYChangePct: Number((area.YoYChangePercentage * 100).toFixed(2)),
    })),
  }));
  return {name: 'data', products};
};

export default mapTreeMapDataDtoToTreeMapData;
