import * as React from 'react';
import { connect } from 'react-redux';
import InputField from '../../components/shared/InputField/InputField';
import { ChangeEvent } from 'react';
import styled from 'styled-components';
import color from '../../utils/color';

const ErrorMessage = styled.p`
  color: ${color.NEGATIVE};
`;

interface State {
  username: string,
  password: string,
}

// TODO: implement submit button and checkbox for 'Keep me logged in' and everything else
class LoginContainer extends React.Component<{}, State> {
  public state = {
    username: '',
    password: '',
  };

  public render() {
    const { username, password } = this.state;
    return (
      <>
        <InputField name="username" type="text" value={username} placeholder="Username" onChange={this.onChangeHandler}/>
        <InputField name="password" type="password" value={password} placeholder="Password" onChange={this.onChangeHandler}/>
        <ErrorMessage />
      </>
    );
  }

  private onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newState = {[event.target.name]: event.target.value};
    this.setState(newState as any)
  };
}

export default connect(null)(LoginContainer);
