import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 20000,
});

export const setAccessToken = (type: string, token: string) => {
  const authToken = `${type} ${token}`;
  api.defaults.headers.common.Authorization = authToken;
  orgApi.defaults.headers.common.Authorization = authToken;
  directApi.defaults.headers.common.Authorization = authToken;
};

export const orgApi = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_API_URL}/organization/`,
  timeout: 20000,
});

export const directApi = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_API_URL}/direct/`,
  timeout: 20000,
});
