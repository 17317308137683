enum color {
  PRIMARY = '#6EB23F',
  SECONDARY = '#F8B335',
  NEGATIVE = '#F03',
  POSITIVE = '#29a45c',

  DRP_INPUT = '#749CFD',

  CHART_RED = '#ff335c',
  NEUTRAL_DARKEST = '#333',
  NEUTRAL_DARK = '#909090',
  NEUTRAL = '#CCC',
  NEUTRAL_LIGHT = '#DDD',
  BACKGROUND = '#FFF',
  BACKGROUND_SECONDARY = '#F3F4F8',
  TEXT_PRIMARY = '#333',
  ICONS_PRIMARY = '#DADCE7',
  LANDING_PAGE_SECONDARY = '#777777',
  ICONS_SECONDARY = '#D4E5F6',
  ICONS_HEADER = '#707070',
  TABLE_POSITIVE = '#00ac4e',
}

export const treeMapColors = {
  0: '#df0d3f',
  1: '#ea5f80',
  2: '#f4afbf',
  3: '#a7a7a7',
  4: '#9ae5b9',
  5: '#34cc73',
  6: '#29a45c',
};

export default color;
