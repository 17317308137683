import * as React from 'react';
import CheckIcon from './CheckIcon';
import BoxIcon from './BoxIcon';

interface Props extends React.SVGAttributes<SVGElement> {
  selected?: boolean;
  size?: string | number;
  color?: string;
}

const CheckboxIcon: React.FC<Props> = ({ selected = false, children, ...props}) =>
  selected ? <CheckIcon {...props} /> : <BoxIcon {...props} />;

export default CheckboxIcon;
