import * as React from 'react';
import styled from 'styled-components';
import color from '../../utils/color';
import ContentLoaderRectangle from '../shared/ContentLoaders/ContentLoaderRectangle';
import SummaryIcon from '../../icons/SummaryIcon';
import Module from '../Product/Module/Module';
import EventNoteIcon from '../../icons/EventNoteIcon';
import SettingsIcon from '../../icons/SettingsIcon';
import GraphIcon from '../../icons/GraphIcon';
import numberRange from '../../utils/numberRange';
import CubesIcon from 'src/icons/CubesIcon';

const Root = styled.div`
   padding-top: 14px;
`;

const ProductHeader = styled.div`
  margin-bottom: 30px;
  border-bottom: ${color.NEUTRAL} solid 2px;
`;

const Header = styled.div`
  margin-bottom: 10px;
`;

const IconContainer = styled(CubesIcon)`
  color: ${color.ICONS_PRIMARY};
  font-size: 25px;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
`;

const Row = styled.div`
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const SettingsRowWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 37px;
`;

const SummaryCalendarContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 194px;
`;

const ChartContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 15px 0 0 80px;
`;

const ChartPicker = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 10px 0 0;
`;

const ChartContents = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 70%;
`;

const MiniChart = styled.div`
  padding: 7px;
`;

const ChartSelectWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

const ChartSelectItem = styled.div`
  margin: 15px 15px 10px 20px;
  width: 30%;
`;

const ProductPageSkeleton: React.FC = () => (
  <Root>
    <ProductHeader>
      <Header>
        <IconContainer/>  <ContentLoaderRectangle width={'40%'} height={30}/>
      </Header>
    </ProductHeader>
    <SummaryCalendarContainer>
      <Content style={{width: '77%'}}>
        <Module
          icon={<SummaryIcon />}
          title="Summary"
          gridArea="summary"
        >
          <Content>
            <Row><ContentLoaderRectangle width={'50%'} height={20}/></Row>
            <Row><ContentLoaderRectangle width={'20%'} height={20}/></Row>
            {numberRange(0, 3).map((key: number) =>
              <Row key={key}>
              <ContentLoaderRectangle width={'25%'} height={20}/>
              <ContentLoaderRectangle width={'25%'} height={20}/>
              <ContentLoaderRectangle width={'25%'} height={20}/>
            </Row>)}
          </Content>
        </Module>
      </Content>
      <Content style={{width: '20%'}}>
        <Module
          icon={<EventNoteIcon />}
          title="Calendar"
          gridArea="calendar">
          <Content>
            {numberRange(0, 5).map((key: number) =>
              <Row key={key}>
              <ContentLoaderRectangle width={'75%'} height={20}/>
            </Row>,
            )}
          </Content>
        </Module>
      </Content>
    </SummaryCalendarContainer>
    <Module
    icon={<SettingsIcon />}
    title="Settings"
    gridArea="settings"
    disableLastLine>
      <SettingsRowWrapper>
        <ContentLoaderRectangle width={'20%'} height={30}/>
        <ContentLoaderRectangle width={'20%'} height={30}/>
        <ContentLoaderRectangle width={'20%'} height={30}/>
        <ContentLoaderRectangle width={'20%'} height={30}/>
      </SettingsRowWrapper>
    </Module>
    <Module
      icon={<GraphIcon/>}
      title="Charts"
      gridArea="chartFullView"
    >
      <Content>
        <ChartContainer>
          <ChartContents>
            <ContentLoaderRectangle width={'100%'} height={350}/>
            <ChartSelectWrapper>
              <ChartSelectItem>
               <ContentLoaderRectangle width={'100%'} height={30}/>
              </ChartSelectItem>
              <ChartSelectItem>
                <ContentLoaderRectangle width={'100%'} height={30}/>
              </ChartSelectItem>
            </ChartSelectWrapper>
          </ChartContents>
          <ChartPicker>
            {numberRange(0, 3).map((chartID: number) =>
              <MiniChart key={chartID}><ContentLoaderRectangle width={149} height={64}/></MiniChart> )}
          </ChartPicker>
        </ChartContainer>
      </Content>
    </Module>
  </Root>
);

export default ProductPageSkeleton;
