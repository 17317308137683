import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { recordsActions } from '../actions';
import { ActionType, getType } from 'typesafe-actions';
import { FrequencyRecord, FrequencyRecordRaw } from '../../../types/FrequencyRecord';
import createFrequencyRecord from './utils/createFrequencyRecord';
import { getDisplayDateRange } from '../../productPage/selectors';
import dataAPI from '../../../services/dataServices';
import { AxiosResponse } from 'axios';
import mapFrequencyRecordsDtoToFrequencyRecords from './utils/mapFrequencyRecordsDtoToFrequencyRecords';
import { FrequencyRecordDto } from '../../../types/dto/FrequencyRecordDto';

export function* initializeFrequencyRecord(action: ActionType<typeof recordsActions.frequency.initializeFrequency>) {
  try {
    const DateDisplayRange = yield select(getDisplayDateRange);
    const toDateString = new Date().toISOString().slice(0, 10);
    const fromDateString = new Date(DateDisplayRange.to.getFullYear() - 12, DateDisplayRange.to.getMonth()).toISOString().slice(0, 10);

    const recordsDto: AxiosResponse<FrequencyRecordDto> =
      yield call(dataAPI.fetchFrequencyRecord, action.payload, action.meta.frequency, fromDateString, toDateString, action.meta.areaID);
    const rawRecords: FrequencyRecordRaw[] = yield mapFrequencyRecordsDtoToFrequencyRecords(recordsDto);
    const records: FrequencyRecord = yield call(createFrequencyRecord, rawRecords, action.meta.frequency);
    yield put(recordsActions.frequency.addFrequency(records, {
      productID: action.payload,
      frequency: action.meta.frequency,
    }));

    if (action.meta.hasUsaMap) {
      const usaRecordsDto: AxiosResponse<FrequencyRecordDto> =
        yield call(dataAPI.fetchUsaFrequencyRecord, action.payload, action.meta.frequency, fromDateString, toDateString);

      const rawUsaRecords: FrequencyRecordRaw[] = yield mapFrequencyRecordsDtoToFrequencyRecords(usaRecordsDto);
      const usaRecords: FrequencyRecord = yield call(createFrequencyRecord, rawUsaRecords, action.meta.frequency);
      yield put(recordsActions.frequency.addFrequency(usaRecords, {
        productID: action.payload,
        frequency: action.meta.frequency,
      }));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* fetchFrequencyRecord(action: ActionType<typeof recordsActions.frequency.fetchFrequency>) {
  try {
    const DateDisplayRange = yield select(getDisplayDateRange);
    let from = DateDisplayRange.from;
    let to = DateDisplayRange.to;
    const toYear = DateDisplayRange.to.getFullYear();
    const fromYear = DateDisplayRange.from.getFullYear();
    if ((toYear - fromYear) < 12) {
       to = to.toISOString().slice(0, 10);
       from = new Date(toYear - 12, DateDisplayRange.to.getMonth()).toISOString().slice(0, 10);
    }
    else {
      from = from.toISOString().slice(0, 10);
      to = to.toISOString().slice(0, 10);
    }
    const recordsDto: AxiosResponse<FrequencyRecordDto> = yield call(dataAPI.fetchFrequencyRecord,
      action.payload, action.meta.frequency, from, to, action.meta.areaID);
    const rawRecords: FrequencyRecordRaw[] = yield mapFrequencyRecordsDtoToFrequencyRecords(recordsDto);
    const records: FrequencyRecord = yield call(createFrequencyRecord, rawRecords, action.meta.frequency);
    yield put(recordsActions.frequency.addFrequency(records, {
      productID: action.payload,
      frequency: action.meta.frequency,
    }));
  } catch (e) {
    console.error(e);
  }
}

export function* initializeUnitsForUnitConversion(action: ActionType<typeof recordsActions.frequency.initializeUnitsForFrequency>) {
  try {
    const recordDto: AxiosResponse<FrequencyRecordDto> = yield call(dataAPI.fetchRecordsForUnitConversion, action.payload, action.meta);
    const rawRecords = yield mapFrequencyRecordsDtoToFrequencyRecords(recordDto);
    const records: FrequencyRecord = yield call(createFrequencyRecord, rawRecords, action.meta);
    yield put(recordsActions.frequency.addFrequency(records, { productID: action.payload, frequency: action.meta }));
  } catch (e) {
    console.error(e);
  }
}

function* frequencyRecordWatcher() {
  yield all([
    takeEvery(getType(recordsActions.frequency.initializeFrequency), initializeFrequencyRecord),
    takeEvery(getType(recordsActions.frequency.initializeUnitsForFrequency), initializeUnitsForUnitConversion),
    takeEvery(getType(recordsActions.frequency.fetchFrequency), fetchFrequencyRecord),
  ]);
}

export default frequencyRecordWatcher;
