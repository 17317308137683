import * as React from 'react';
import styled from 'styled-components';
import color from '../../utils/color';
import LoginContainer from '../../containers/LoginContainer/LoginContainer';

const Container = styled.div`
  width: 300px;
  margin: auto;
`;

const LoginFieldName = styled.p`
  margin: 0;
  color: ${color.NEUTRAL_DARK};
  font-size: 14px;
  font-weight: normal;
`;

const LoginFieldContainer = styled.div`
  border-bottom: ${color.NEUTRAL} solid 2px;
  border-top: ${color.NEUTRAL} solid 2px;
  padding: 30px 0 15px 0;
`;

const RegisterField = styled.p`
  margin: 10px 0 0 0;
  color: ${color.NEUTRAL_DARK};
  font-size: 10px;
`;

// TODO: finish login scene
const LoginPageScene = () => (
  <Container>
    <LoginFieldName>Log in to Your Account</LoginFieldName>
    <LoginFieldContainer>
      <LoginContainer />
    </LoginFieldContainer>
    <RegisterField>
      Not a member? <a href="google.com">Request Access</a>
    </RegisterField>
  </Container>
);

export default LoginPageScene;
