import { AxiosResponse } from 'axios';
import { CatalogReportsDto } from '../../../types/dto/CatalogReportsDto';
import { CatalogReport } from '../../../types/CatalogReport';

const mapCatalogReportsDtoToCatalogReports = ({ data }: AxiosResponse<CatalogReportsDto>): CatalogReport[] => data.value
  .filter(({ Active }) => Active)
  .map(({ ReportName, ReportNameCode, ReportNameTranslated, ReportUrl, PublisherID }) =>
    ({ publisher: PublisherID, id: ReportNameCode, name: ReportName, nameTranslated: ReportNameTranslated, url: ReportUrl }));

export default mapCatalogReportsDtoToCatalogReports;
