import { PeriodFrequency } from '../../../types/PeriodFrequency';

const getFrequencyID = (frequency: PeriodFrequency): number => {
  switch (frequency) {
    case 'Daily': return 1;
    case 'Weekly': return 2;
    case 'Monthly': return 3;
    case 'Quarterly': return 4;
    case 'Annual': return 5;
    default: return 0;
  }
};

export default getFrequencyID;
