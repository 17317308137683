import { RootState } from '../../store';
import { FrequencyRecordProduct } from './reducers';
import { createSelector } from 'reselect';
import { getAreaIDFromProps, getFrequencyFromProps, getProductIDFromProps } from '../../fromPropsSelector';

export const getFrequencyRecordsProductList = (state: RootState): number[] => state.records.frequency.allProducts;
export const getAllFrequencyRecords = (state: RootState): FrequencyRecordProduct => state.records.frequency.productsById;

export const getFrequencyRecord = createSelector(
  getAllFrequencyRecords,
  getProductIDFromProps,
  getFrequencyFromProps,
  (allRecords, productId, frequency) => {
    if (allRecords[productId] && allRecords[productId][frequency]) {
      return allRecords[productId][frequency];
    }
    return null;
  },
);

export const getFrequencyAreaRecords = createSelector(
  getFrequencyRecord,
  getAreaIDFromProps,
  (records, areaID) => {
    if (records && records[areaID] ) {
      return records[areaID];
    }
    return null;
  },
);
