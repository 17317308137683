import produce from 'immer';
import { getType } from 'typesafe-actions';
import { UnitsState } from '../../types/Units';
import { UnitsActions, unitsActions } from './actions';
import createUniqueArray from '../../utils/createUniqueArray';

export const unitReducer = produce<UnitsState, UnitsActions>(
  (state, action) => {
    switch (action.type) {
      case getType(unitsActions.add):
          Object.keys(action.payload).forEach((key) => {
            if (!state[key]) {
              state[key] = { allIDs: [] };
            }
            state[key] = {
              ...state[key],
              ...action.payload[key],
            };
            state[key].allIDs = createUniqueArray([...state[key].allIDs, ...Object.keys(action.payload[key]).map(Number)]);
          });
        return;
    }
  }, {},
);
