import { all, call, put, takeLatest } from 'redux-saga/effects';
import { menuActions } from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { NavMenuDto } from '../../types/dto/NavMenuDto';
import { MenuDto } from '../../types/dto/MenuDto';
import dataAPI from '../../services/dataServices';
import { AxiosResponse } from 'axios';

export function* fetchNavigationMenu() {
  try {
    const navMenuDto: AxiosResponse<NavMenuDto[]> = yield call(dataAPI.fetchNavigationMenu);
    yield put(menuActions.navMenu.add(navMenuDto.data));
  } catch (e) {
    console.error(e);
  }
}

export function* fetchMenu(action: ActionType<typeof menuActions.menu.get>) {
  try {
    const menu: AxiosResponse<MenuDto[]> = yield call(dataAPI.fetchMenu, action.payload);
    yield put(menuActions.menu.add(menu.data, action.payload));
  } catch (e) {
    console.error(e);
  }
}

function* menuSagaWatcher() {
  yield all([
    takeLatest(getType(menuActions.navMenu.initialize), fetchNavigationMenu),
    takeLatest(getType(menuActions.menu.get), fetchMenu),
  ]);
}

export default menuSagaWatcher;
