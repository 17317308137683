import * as React from 'react';
import color from '../../../utils/color';
import styled from 'styled-components';
import { ReactNode, ReactText } from 'react';
import { ReactElement } from 'react';
import emptyFunc from '../../../utils/emptyFunc';
import SelectField from '../../shared/SelectField/SelectField';

const Root = styled.div<{ area: string, disableLastLine: boolean }>`
  grid-area: ${({ area }) => area};
  ${({ disableLastLine }) => disableLastLine ?
    '' : `border-bottom: ${color.NEUTRAL} solid 2px;`}
`;

const Header = styled.div`
  display: flex;
`;

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  margin-bottom: 15px;
  border-bottom: ${color.NEUTRAL} solid 2px;
`;

const RightContainer = styled.div`
  display: flex;
  margin: 0 0 15px 25px;
`;

const SelectSection = styled.div`
  border-bottom: ${color.NEUTRAL} solid 2px;
  padding-left: 15px;
`;

const ModuleButton = styled.div<{ active: boolean }>`
  padding: 5px 15px 10px 15px;
  border-bottom:
  ${({ active }) => active ? color.PRIMARY : color.NEUTRAL} solid 2px;
  ${({ active }) => active ? `box-shadow: 0 5px 5px -4px ${color.PRIMARY};` : ''}

  &:hover {
    box-shadow: 0 5px 5px -4px ${({ active }) => active ? color.PRIMARY : color.NEUTRAL}
    cursor: ${({ active }) => active ? 'default' : 'pointer'}
`;

const Text = styled.h3`
  margin: 0 0 10px 5px;
  padding-right: 25px;
  color: ${color.TEXT_PRIMARY};
`;

const IconContainer = styled.div`
  color: ${color.ICONS_PRIMARY};
  font-size: 25px;
  transform: translateY(-3px);
`;

interface Button {
  onClick: () => void,
  title: string,
  active?: boolean,
}

interface Select {
  onChange: (value: string | number) => void,
  options: Array<{ id: number | string, name: string }>,
  selected: string,
}

interface Props {
  icon?: ReactElement<SVGElement>,
  title: ReactText,
  children: ReactNode,
  gridArea: string,
  buttons?: Button[],
  select?: Select,
  disableLastLine?: boolean,
}

const Module = ({ icon, title, children, buttons, select, gridArea, disableLastLine = false }: Props) => (
  <Root area={gridArea} disableLastLine={disableLastLine}>
    <Header>
      <TextContainer>
        {icon && <IconContainer>{icon}</IconContainer>}
        <Text>{title}</Text>
      </TextContainer>
      {buttons || select ?
        <RightContainer>
          {select &&
            <SelectSection>
              <SelectField options={select.options}
                value={select.selected}
                onChange={select.onChange}
                width={380} />
            </SelectSection>}
          {buttons && buttons.map(
            ({ title: buttonTitle, onClick: onClickHandler, active }) => (
              <ModuleButton
                key={buttonTitle}
                onClick={!!active ? emptyFunc : onClickHandler}
                active={!!active}
              >{buttonTitle}</ModuleButton>),
          )}
        </RightContainer>
        : null}
    </Header>
    {children}
  </Root>
);

export default Module;
