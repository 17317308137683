import produce from 'immer';
import { getType } from 'typesafe-actions';
import { TreeMapData } from '../../types/TreeMapData';
import { landingPageActions, LandingPageActions } from './actions';

interface LandingPageState {
  treeMapData: TreeMapData,
}

const initialState: LandingPageState = {
  treeMapData: {name: 'data', products: []},
};

export const landingPageReducer = produce<LandingPageState, LandingPageActions>(
  (state, action) => {
    switch (action.type) {
      case getType(landingPageActions.addTreeMapData):
        state.treeMapData = action.payload;
        return;
    }
  }, initialState,
);
