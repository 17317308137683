 import produce from 'immer';
import { getType } from 'typesafe-actions';
import { chartActions, ChartActions } from './actions';
import createUniqueArray from '../../utils/createUniqueArray';
import { Chart } from '../../types/Chart';

export interface ChartState {
  allCharts: number[],
  chartById: ProductCharts,
}

export interface ProductCharts {
  [productID: number] : ProductChart,
}

interface ProductChart {
  [frequency: string] : Charts,
}

interface Charts {
  [chartID: number] : Chart,

  chartList: number[],
}

export const initialState: ChartState = {
  allCharts: [],
  chartById: {},
};

export const chartReducer = produce<ChartState, ChartActions>(
  (state: ChartState, action) => {
    switch (action.type) {
      case getType(chartActions.add): {
        const chartList = createUniqueArray(action.payload.map(chart => chart.id));
        const {productID, frequency} = action.meta;

        state.allCharts = createUniqueArray([...state.allCharts, productID]);
        if (!state.chartById[productID]) {
          state.chartById[productID] = {};
        }
        if (!state.chartById[productID][frequency]) {
          state.chartById[productID][frequency] = {chartList};
        } else {
          state.chartById[productID][frequency].chartList = createUniqueArray([...state.chartById[productID][frequency].chartList, ...chartList]);
        }

        action.payload.forEach(chart => {
          state.chartById[productID][frequency][chart.id] = chart;
        });
        return;
      }
      case getType(chartActions.changeNormalization): {
        const {productID, frequency, chartID, selectedNormalizationID} = action.payload;
        state.chartById[productID][frequency][chartID].selectedNormalization = selectedNormalizationID;
        return;
      }
      case getType(chartActions.changeTransformation): {
        const {productID, frequency, chartID, selectedTransformationID} = action.payload;
        state.chartById[productID][frequency][chartID].selectedTransformation = selectedTransformationID;
        return;
      }
    }
  }, initialState,
);
