import { all } from 'redux-saga/effects';
import organizationSagaWatcher from './organization/sagas';
import usersSagaWatcher from './users/sagas';
import organizationReportsSagaWatcher from './organizationReports/sagas';
import reportSagaWatcher from './report/sagas';
import viewSagaWatcher from './view/sagas';
import { organizationViewSagaWatcher } from './organizationViews/sagas';
import calendarReportsSagaWatcher from './calendarReports/sagas';

export default function* adminRootSaga() {
  yield all([
    organizationSagaWatcher(),
    usersSagaWatcher(),
    organizationReportsSagaWatcher(),
    reportSagaWatcher(),
    viewSagaWatcher(),
    organizationViewSagaWatcher(),
    calendarReportsSagaWatcher(),
  ]);
}
