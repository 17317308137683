import * as React from 'react';
import { Redirect } from 'react-router';

// const Header = styled.div`
//   height: 34px;
//   font-weight: 600;
//   line-height: 1.36;
//   padding: 20px 0;
//   color: ${color.TEXT_PRIMARY};
//   font-size: 25px;
//   border-bottom: 2px solid ${color.NEUTRAL};
// `;

// const DataContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin-top: 20px;
// `;
//
// const CommoditiesContainer = styled.div`
//   width: 60%;
//   display: flex;
//   flex-direction: column;
// `;
//
// const NewsContainer = styled.div`
//   width: 35%;
//   display: flex;
//   flex-direction: column;
// `;

// const TreeMapChartWrapper = styled.div`
//   height: 500px;
//   width: 100%;
//   padding-top: 20px;
//   position: relative;
// `;

// type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

class DashboardScene extends React.Component {
  public componentDidMount() {
    // this.props.getTreeMapData();
  }

  public render() {
    return <>
      <Redirect to="/product/3714"/>
      {/*<Header>Global Dairy Markets (2018)</Header>*/}
      {/*<TreeMapChartWrapper>*/}
        {/*<TreeMapChart treeMapData={this.props.treeMapData}/>*/}
      {/*</TreeMapChartWrapper>*/}
      {/*<TreeMapFooter/>*/}
      {/*<DataContainer>*/}
        {/*<CommoditiesContainer>*/}
          {/*<Commodities/>*/}
          {/*<Analytics/>*/}
        {/*</CommoditiesContainer>*/}
        {/*<NewsContainer>*/}
          {/*<News/>*/}
          {/*<LatestReports/>*/}
          {/*<LandingPageCalendar/>*/}
        {/*</NewsContainer>*/}
      {/*</DataContainer>*/}
    </>
  }
}

// const mapStateToProps = (state: RootState) => ({
//   treeMapData: getTreeMapData(state),
// });
//
// const mapDispatchToProps = {
//   getTreeMapData: landingPageActions.getTreeMapData,
// };
//
// export default connect(mapStateToProps, mapDispatchToProps)(DashboardScene);

export default DashboardScene;
