import * as React from 'react';
import styled from 'styled-components';
import color from 'src/utils/color';

const SelectOptionWrapper = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: lighter;
  padding: 5px 0 5px 0;
  color: ${color.TEXT_PRIMARY};
`;

const Dot = styled.div`
  height: 13px;
  width: 13px;
  margin-right: 10px;
  border-radius: 50%;
  border: ${color.NEUTRAL_DARK} solid 1px;
`;

const ActiveDot = styled.div`
  margin: 1px;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background-color: ${color.DRP_INPUT};
`;

interface Props {
  type: string,
  name: string,
  checked: boolean,
  value: string | number,
  label?: string,
  onClick?(value: string | number): void,
}

function onClickHandler(value: string | number, onClick?: (value: string | number) => void ) {
  return () => onClick ? onClick(value) : null;
}
const SelectOption: React.FC<Props> = ({ type, name, checked, value, label, onClick }) => (
  <SelectOptionWrapper onClick={onClickHandler(value, onClick)}>
    <Dot>{ checked && <ActiveDot /> }</Dot>{label}
  </SelectOptionWrapper>
);

export default SelectOption;
