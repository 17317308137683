import styled from 'styled-components';
import CheckboxIcon from '../../../icons/CheckboxIcon';
import color from '../../../utils/color';

const Checkbox = styled(CheckboxIcon)<{selected?: boolean}>`
  &&& {
    color: ${({ selected = false }) => selected ? color.PRIMARY : color.NEUTRAL_DARK};
    font-size: 20px;
    transform: translateY(4px);
    margin-right: 15px;
    cursor: pointer;
  }
`;

export default Checkbox;
