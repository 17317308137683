import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { unitsActions } from './actions';
import { Units } from '../../types/Units';
import dataAPI from '../../services/dataServices';
import mapUnitDtoToUnit from './utils/mapUnitDtoToUnit';
import { AxiosResponse } from 'axios';
import { UnitDto } from '../../types/dto/UnitDto';

export function* fetchUnits() {
  try {
    const unitDto: AxiosResponse<UnitDto[]> = yield call(dataAPI.fetchUnits);
    const units: Units = yield mapUnitDtoToUnit(unitDto);
    yield put(unitsActions.add(units));
    yield put(unitsActions.update());
  } catch (e) {
    console.error(e);
  }
}

function* unitSagaWatcher() {
  yield all([
    takeLatest(getType(unitsActions.initialize), fetchUnits),
  ]);
}

export default unitSagaWatcher;
