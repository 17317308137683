import styled from 'styled-components';
import color from '../../../../utils/color';

const PaginationBox = styled.div`
  height: 35px;
  text-align: center;
  line-height: 35px;
  color: ${color.ICONS_HEADER};
  font-size: 14px;
  width: 65px;
  font-weight: 600;
  background-color: ${color.BACKGROUND_SECONDARY};
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
`;

export default PaginationBox;
