import styled from 'styled-components';
import color from '../../../../utils/color';

const SeriesLink = styled.div`
  margin-top: 8px;

  a {
    font-weight: lighter;
    color: ${color.PRIMARY};
    font-size: 18px;
    text-decoration: none;
  }
`;

export default SeriesLink;
