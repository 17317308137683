import { ReportDto } from '../../../../types/dto/ReportDto';
import { Report } from '../../../../types/Report';

const mapReportDtoToReport = (data: ReportDto[]): Report[] =>
  data.map(({ Name, NameTranslated, Code, RequestAccess }) => ({
    name: Name, nameTranslated: NameTranslated, code: Code || '', requestAccess: RequestAccess,
  })).sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if ( a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

export default mapReportDtoToReport;
