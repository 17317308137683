export default {
  'Component': {
    'ButterPrice': {
      'ExpectedPrice': 2.3024,
      'ActualPrice': 2.3024,
      'MonthPrices': [
        {'Sigma': 0.0, 'Date': '2019-04-01T00:00:00', 'ExpectedPrice': 2.282, 'ActualPrice': 2.282},
        {'Sigma': 0.0, 'Date': '2019-05-01T00:00:00', 'ExpectedPrice': 2.3073, 'ActualPrice': 2.3073},
        {'Sigma': 0.0, 'Date': '2019-06-01T00:00:00', 'ExpectedPrice': 2.3178, 'ActualPrice': 2.3178}],
    },
    'CheesePrice': {
      'ExpectedPrice': 1.5667,
      'ActualPrice': 1.5667,
      'MonthPrices': [
        {'Sigma': 0.0, 'Date': '2019-04-01T00:00:00', 'ExpectedPrice': 1.539, 'ActualPrice': 1.539},
        {'Sigma': 0.0, 'Date': '2019-05-01T00:00:00', 'ExpectedPrice': 1.561, 'ActualPrice': 1.561},
        {'Sigma': 0.0, 'Date': '2019-06-01T00:00:00', 'ExpectedPrice': 1.6, 'ActualPrice': 1.6}],
    },
    'DryWheyPrice': {
      'ExpectedPrice': 0.3818,
      'ActualPrice': 0.3818,
      'MonthPrices': [
        {'Sigma': 0.0, 'Date': '2019-04-01T00:00:00', 'ExpectedPrice': 0.3828, 'ActualPrice': 0.3828},
        {'Sigma': 0.0, 'Date': '2019-05-01T00:00:00', 'ExpectedPrice': 0.385, 'ActualPrice': 0.385},
        {'Sigma': 0.0, 'Date': '2019-06-01T00:00:00', 'ExpectedPrice': 0.3775, 'ActualPrice': 0.3775}],
    },
    'ButterfatPrice': {
      'ExpectedPrice': 2.5804,
      'ActualPrice': 2.5805,
      'MonthPrices': [
        {'Sigma': 0.0, 'Date': '2019-04-01T00:00:00', 'ExpectedPrice': 2.5558, 'ActualPrice': 2.5558},
        {'Sigma': 0.0, 'Date': '2019-05-01T00:00:00', 'ExpectedPrice': 2.5865, 'ActualPrice': 2.5865},
        {'Sigma': 0.0, 'Date': '2019-06-01T00:00:00', 'ExpectedPrice': 2.5992, 'ActualPrice': 2.5992}],
    },
    'ProteinPrice': {
      'ExpectedPrice': 1.6857,
      'ActualPrice': 1.6854,
      'MonthPrices': [
        {'Sigma': 0.0, 'Date': '2019-04-01T00:00:00', 'ExpectedPrice': 0.0, 'ActualPrice': 1.6223},
        {'Sigma': 0.0, 'Date': '2019-05-01T00:00:00', 'ExpectedPrice': 0.0, 'ActualPrice': 1.6608},
        {'Sigma': 0.0, 'Date': '2019-06-01T00:00:00', 'ExpectedPrice': 0.0, 'ActualPrice': 1.7732}],
    },
    'OtherSolidsPrice': {
      'ExpectedPrice': 0.1881,
      'ActualPrice': 0.1882,
      'MonthPrices': [
        {'Sigma': 0.0, 'Date': '2019-04-01T00:00:00', 'ExpectedPrice': 0.0, 'ActualPrice': 0.1892},
        {'Sigma': 0.0, 'Date': '2019-05-01T00:00:00', 'ExpectedPrice': 0.0, 'ActualPrice': 0.1915},
        {'Sigma': 0.0, 'Date': '2019-06-01T00:00:00', 'ExpectedPrice': 0.0, 'ActualPrice': 0.1838}],
    }, 'NonfatDryMilk': {
      'ExpectedPrice': 0.0,
      'ActualPrice': 0.0,
      'MonthPrices': [
        {'Sigma': 0.0, 'Date': '2019-04-01T00:00:00', 'ExpectedPrice': 0.9743, 'ActualPrice': 1.1005},
        {'Sigma': 0.0, 'Date': '2019-05-01T00:00:00', 'ExpectedPrice': 0.9711, 'ActualPrice': 1.111},
        {'Sigma': 0.0, 'Date': '2019-06-01T00:00:00', 'ExpectedPrice': 1.002, 'ActualPrice': 1.1205}],
    },
    'ExpectedRevenue': 165300.0,
    'ExpectedRevenueCwt': 16.53,
    'ActualRevenue': 165300.0,
    'ActualRevenueCwt': 16.53,
    'RevenueGuarantee': 157035.0,
    'RevenueGuaranteeCwt': 15.7,
    'Premium': 1051.0,
    'PremiumCwt': 0.1051,
    'Subsidy': 462.0,
    'SubsidyCwt': 0.0462,
    'ProducerPreimum': 589.0,
    'ProducerPreimumCwt': 0.06,
    'ProbabilityOfPostiveIndemnity': 0.2,
    'ProbabilityOfPositiveNetBenefit': 0.18,
    'GrossIndemnity': 0.0,
    'GrossIndemnityCwt': 0.0,
  },
  'Class': {
    'ClassIIIPrice': {
      'ExpectedPrice': 15.14,
      'ActualPrice': 15.15,
      'MonthPrices': [
        {'Sigma': 0.0, 'Date': '2019-04-01T00:00:00', 'ExpectedPrice': 14.88, 'ActualPrice': 14.88},
        {'Sigma': 0.0, 'Date': '2019-05-01T00:00:00', 'ExpectedPrice': 15.11, 'ActualPrice': 15.11},
        {'Sigma': 0.0, 'Date': '2019-06-01T00:00:00', 'ExpectedPrice': 15.44, 'ActualPrice': 15.45}],
    },
    'ClassIVPrice': {
      'ExpectedPrice': 16.04,
      'ActualPrice': 17.14,
      'MonthPrices': [
        {'Sigma': 0.0, 'Date': '2019-04-01T00:00:00', 'ExpectedPrice': 15.88, 'ActualPrice': 16.96},
        {'Sigma': 0.0, 'Date': '2019-05-01T00:00:00', 'ExpectedPrice': 15.96, 'ActualPrice': 17.16},
        {'Sigma': 0.0, 'Date': '2019-06-01T00:00:00', 'ExpectedPrice': 16.27, 'ActualPrice': 17.29}],
    },
    'ExpectedRevenue': 151400.0,
    'ExpectedRevenueCwt': 15.14,
    'ActualRevenue': 151500.0,
    'ActualRevenueCwt': 15.15,
    'RevenueGuarantee': 143830.0,
    'RevenueGuaranteeCwt': 14.38,
    'Premium': 799.0,
    'PremiumCwt': 0.0799,
    'Subsidy': 352.0,
    'SubsidyCwt': 0.0352,
    'ProducerPreimum': 447.0,
    'ProducerPreimumCwt': 0.04,
    'ProbabilityOfPostiveIndemnity': 0.18,
    'ProbabilityOfPositiveNetBenefit': 0.17,
    'GrossIndemnity': 0.0,
    'GrossIndemnityCwt': 0.0,
  },
  'MilkProduction': {
    'ExpectedPrice': 5315,
    'ActualPrice': 5315,
  },
};
