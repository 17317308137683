import * as React from 'react';
import styled from 'styled-components';
import color from '../../../utils/color';
import dropDownIcon from '../../../media/svg/arrowDropDown.svg';
import { ChangeEvent } from 'react';

const Select = styled.select`
   border: 1px solid ${color.NEUTRAL_LIGHT};
   border-radius: 6px;
   display: inline-block;
   position: relative;
   width: 55px;
   line-height: 27px;
   appearance: none;
   background: transparent url("${dropDownIcon}") no-repeat right;
   background-color: ${color.BACKGROUND_SECONDARY};
   margin: 3px 10px 0 7px;
   font-weight: lighter;
   font-size: 14px;
   padding-left: 5px;
   cursor: pointer;
   color: ${color.ICONS_HEADER};
`;

interface Props {
  dataLength: number,
  perPage: number,
  perPageClickHandler(event: ChangeEvent<HTMLSelectElement>): void,
}

const PerPage: React.FC<Props> = ({ dataLength, perPage, perPageClickHandler }) => (dataLength > 10) ?
  <div>
    Show
    <Select onChange={perPageClickHandler} value={perPage}>
      <option>10</option>
      <option>25</option>
      <option>50</option>
      <option>100</option>
    </Select>per page
  </div>
  : null;

export default PerPage;
