import * as React from 'react';
import styled from 'styled-components';
import color from '../../../utils/color';
import { ChangeEvent } from 'react';

interface InputProps {
  error: boolean,
}

const Input = styled.input`
  width: calc(100% - 20px);
  background-color: ${color.BACKGROUND_SECONDARY};
  color: ${color.NEUTRAL_DARK};
  border-radius: 4px;
  padding: 5px 10px;
  border: ${({error}: InputProps) => error ? `${color.NEGATIVE} solid 1px` : 'none'};
`;

interface Props {
  type: 'number' | 'text' | 'password' | 'email',
  value: string | number,
  placeholder: string,
  error?: boolean,
  name: string,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const InputField: React.FC<Props> = ({ type, name, value, placeholder, error = false, onChange }) => (
  <Input error={error}
    type={type}
    name={name}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
  />
);

export default InputField;
