import * as React from 'react';
import styled from 'styled-components';
import dropDownIcon from 'src/media/svg/arrowDropDown.svg';
import SelectOption from './SelectOption';
import Scrollbars from 'react-custom-scrollbars';
import color from '../../utils/color';

const Select = styled.div<{width?: number}>`
  border-radius: 6px;
  display: inline-block;
  position: relative;
  padding: 0 15px 0 10px;
  width: calc(${({ width = 185 }) => width}px - 20px);
  line-height: 29px;
  appearance: none;
  background: transparent url("${dropDownIcon}") no-repeat right;
  margin-top: 3px;
  margin-right: 10px;
  font-weight: lighter;
  font-size: 16px;
  cursor: pointer;
  color: ${color.DRP_INPUT};
  background-color: ${color.BACKGROUND_SECONDARY};
`;

const SelectDropDown = styled.div<{isOpened: boolean, width?: number}>`
  display: ${({isOpened}) => isOpened ? 'block' : 'none'};
  position: absolute;
  padding-left: 5px;
  border: 1px solid ${color.NEUTRAL_LIGHT};
  background-color: ${color.BACKGROUND_SECONDARY};
  border-radius: 6px;
  width: ${({ width = 185 }) => width}px;
  z-index: 20;
  cursor: pointer;
`;

const SelectFieldWrapper = styled.div`
  display: inline-block;
  z-index: 10;
`;

const Input = styled.input<{ width?: number }>`
  font-family: 'Open Sans', sans-serif;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: lighter;
  color: ${color.DRP_INPUT};
  margin: 0 0 0 -1px;
  cursor: pointer;
  caret-color: transparent;
  width: calc(${({ width = 185 }) => width}px - 30px);
  background-color: ${color.BACKGROUND_SECONDARY};

  &::placeholder {
    color: ${color.DRP_INPUT};
  }
`;

interface Props {
  options: Array<{ id: number | string, name: string }>,
  value: string,
  width?: number,
  onChange: (value: string | number) => void,
}

interface State {
  isOpened: boolean,
  filter: string,
}

class SelectField extends React.Component<Props, State> {
  public state = {
    isOpened: false,
    filter: '',
  };

  public render() {
    const { filter, isOpened } = this.state;
    const { value, options, width, onChange } = this.props;
    const SelectOptions = options
      .filter(option => option.name.toLowerCase().includes(filter.toLowerCase()))
      .map((option) =>
      <SelectOption type="radio"
                    key={option.id}
                    name={option.name}
                    checked={value === option.name}
                    value={option.id}
                    label={option.name}
                    onClick={value !== option.name ? onChange : undefined}
      />);

    return (
      <SelectFieldWrapper onMouseLeave={this.handleMouseLeave}>
        <Select onClick={this.handleSelectDropDown} width={width}>
          {!isOpened ? value :
            <Input type="text" placeholder={value} value={filter} onChange={this.onInputChange} width={width} autoFocus/>}
        </Select>
        <SelectDropDown isOpened={isOpened} width={width}>
          <Scrollbars hideTracksWhenNotNeeded={true} autoHeightMax={115} autoHeight={true}>
            <div onClick={this.handleSelectDropDown}>
              {SelectOptions}
            </div>
          </Scrollbars>
        </SelectDropDown>
      </SelectFieldWrapper>
    )
  }

  private onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ filter: event.target.value });
  };

  private handleSelectDropDown = () => {
    this.setState({isOpened: !this.state.isOpened, filter: ''});
  };

  private handleMouseLeave = () => {
    this.setState({isOpened: false, filter: ''});
  };
}

export default SelectField;
