import { all, call, takeLatest, put } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { contextTableActions } from './actions';
import directAPI from 'src/services/directServices';
import { AxiosResponse } from 'axios';
import { SeriesContextDto } from '../../types/dto/SeriesContextDto';
import mapSeriesContextDtoToSeriesContext from './utils/mapSeriesContextDtoToSeriesContext';
import { TableContextDto } from 'src/types/dto/TableContextDto';
import mapContextTableDtoToContextTable from './utils/mapContextTableDtoToContextTable';
import { productPageActions } from '../productPage/actions';
import { PeriodFrequency } from '../../types/PeriodFrequency';

export function* initSeriesContext(action: ActionType<typeof contextTableActions.series.get>) {
  try {
    const seriesContextDto: AxiosResponse<SeriesContextDto[]> = yield call(directAPI.fetchSeriesContext, action.payload);
    const seriesContext = mapSeriesContextDtoToSeriesContext(seriesContextDto.data);
    yield put(contextTableActions.series.add(seriesContext, action.payload));

    if (seriesContext.length !== 0) {
      yield put(productPageActions.setContextTable(seriesContext[0].type.id));
    }

    yield all(seriesContext.map(({ id }) => call(fetchContextTable, id, action.meta)));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchContextTable(tableId: number, frequency: PeriodFrequency) {
  try {
    const contextTableDto: AxiosResponse<TableContextDto[]> = yield call(directAPI.fetchTableContext, tableId, frequency);
    const contextTable = mapContextTableDtoToContextTable(contextTableDto.data);
    yield put(contextTableActions.table.add(contextTable, tableId));
  } catch (e) {
    console.log(e);
  }
}

function* contextTableSagaWatcher() {
  yield all([
    takeLatest(getType(contextTableActions.series.get), initSeriesContext),
  ]);
}

export default contextTableSagaWatcher;
