import produce from 'immer';
import { getType } from 'typesafe-actions';
import { authActions, AuthActions } from './actions';

interface AuthenticationState {
  authState: AuthState,
  expiresAt: number | null,
}

export enum AuthState {
  initialization = 'initialization',
  guest = 'guest',
  member = 'member',
  moderator = 'moderator',
  administrator = 'administrator',
  expired = 'expired',
}

const initialState: AuthenticationState = {
  authState: AuthState.initialization,
  expiresAt: null,
};

export const authenticationPageReducer = produce<AuthenticationState, AuthActions>(
  (state, action) => {
    switch (action.type) {
      case getType(authActions.set.authState):
        state.authState = action.payload;
        return;
      case getType(authActions.set.expiresAt):
        state.expiresAt = action.payload;
        return;
    }
  }, initialState);
