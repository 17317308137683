import * as React from 'react';
import styled from 'styled-components';
import color from '../../utils/color';
import Accordion from '../../components/RevenuePrediction/Accordion/Accordion';
import Slider from '../../components/shared/Slider/Slider';
import InputContainer from '../../components/RevenuePrediction/InputContainer/InputContainer';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import TableHeader from 'src/components/RevenuePrediction/TableHeader/TableHeader';
import stateCodeList from './stateCodeList';
import practiceCodeList from './practiceCodeList';
import riskPremiumAPI from '../../services/riskPremiumService';
import apiData from './apiData';
import { format } from 'date-fns';
import formatValue from '../../utils/formatValue';
import SelectField from './SelectField';

const Root = styled.div``;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 25px;
  width: 100%;
  border-bottom: ${color.NEUTRAL} solid 2px;
`;

const SettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  margin-bottom: 40px;
  width: 100%;
  border-bottom: ${color.NEUTRAL} solid 2px;
`;

const SettingsRow = styled.div`
  display: flex;
`;

const WhatIfContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

const WhatIfInputContainer = styled.div`
  flex-grow: 1;
  margin-right: 20px;
  width: 100%;
`;

const WhatIfResultContainer = styled.div`
  flex-grow: 1;
  margin-left: 20px;
  width: 100%;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 30px;
`;

const Column = styled.td`
  text-align: right;
  padding: 5px 0;
  color: ${color.PRIMARY}
`;

const ColumnExpected = styled(Column)`
  color: ${color.SECONDARY};
  padding: 8px 0;
`;

const ColumnMonth = styled(Column)`
  color: ${color.TEXT_PRIMARY};
`;

const ColumnLabel = styled(Column)`
  text-align: left;
  color: ${color.TEXT_PRIMARY};
`;

const ColumnImportant = styled(Column)`
  font-weight: bolder;
  color: ${color.PRIMARY};
  font-size: 18px;
`;

const ColumnImportantLabel = styled(ColumnImportant)`
  text-align: left;
`;

const TableInput = styled.input`
  color: ${color.DRP_INPUT};
  background-color: ${color.BACKGROUND_SECONDARY};
  border-radius: 6px;
  font-size: 16px;
  line-height: 27px;
  font-weight: lighter;
  border: none;
  text-align: right;
  width: 100px;

  -moz-appearance:textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
`;

const DMPInput = styled(TableInput)`
  margin-top: 2px;
  width: 200px;
  padding-right: 5px;
`;

const RevenuePredictionContainer: React.FC = () => {
  // const [ isHistoricalAccordionOpen, setHistoricalAccordionOpenStatus ] = React.useState(false);
  // const onHistoricalAccordionClick = () => {
  //   setHistoricalAccordionOpenStatus(!isHistoricalAccordionOpen);
  // };
  const [ isWhatIfOpen, setWhatIfOpenStatus ] = React.useState(true);
  const onWhatIfAccordionClick = () => {
    setWhatIfOpenStatus(!isWhatIfOpen);
  };

  const [ coverageLevel, setCoverageLevel ] = React.useState(0.95);
  const onCoverageLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => { setCoverageLevel(Number(event.target.value)) };
  const [ butterfatTest, setButterfatTest ] = React.useState(3.9);
  const onButterfatTestChange = (event: React.ChangeEvent<HTMLInputElement>) => { setButterfatTest(Number(event.target.value)) };
  const [ protectionFactor, setProtectionFactor ] = React.useState(1);
  const onProtectionFactorChange = (event: React.ChangeEvent<HTMLInputElement>) => { setProtectionFactor(Number(event.target.value)) };
  const [ proteinTest, setProteinTest ] = React.useState(3.2);
  const onProteinTestChange = (event: React.ChangeEvent<HTMLInputElement>) => { setProteinTest(Number(event.target.value)) };
  const [ classPriceWeightingFactor, setClassPriceWeightingFactor ] = React.useState(1);
  const onClassPriceWeightingFactorChange = (e: React.ChangeEvent<HTMLInputElement>) => { setClassPriceWeightingFactor(Number(e.target.value)) };

  const [ salesEffectiveDate, setSalesEffectiveDate ] = React.useState(new Date('June 07, 2019'));
  const onDateChange = (date: Date) => { setSalesEffectiveDate(date) };
  const [ coveredMilkProduction, setCoveredMilkProduction ] = React.useState(10000000);
  const onCoveredMilkProductionChange = (event: React.ChangeEvent<HTMLInputElement>) => { setCoveredMilkProduction(Number(event.target.value))};
  const [ stateCode, setStateCode ] = React.useState(stateCodeList[0].id);
  const [ stateCodeName, setStateCodeName ] = React.useState(stateCodeList[0].name);
  const onStateCodeChange = (value: number) => {
    const index = stateCodeList.findIndex(({ id }) => id === value);
    if (index !== -1) {
      setStateCode(stateCodeList[index].id);
      setStateCodeName(stateCodeList[index].name);
    }
  };
  const [ practiceCode, setPracticeCode ] = React.useState(practiceCodeList[0].id);
  const [ practiceCodeName, setPracticeCodeName ] = React.useState(practiceCodeList[0].name);
  const onPracticeCodeChange = (value: number) => {
    const index = practiceCodeList.findIndex(({ id }) => id === value);
    if (index !== -1) {
      setPracticeCode(practiceCodeList[index].id);
      setPracticeCodeName(practiceCodeList[index].name);
    }
  };

  const [ inputFields, mutateInputFields ] = React.useState({
    butter: { a: 0, b: 0, c: 0 },
    cheese: { a: 0, b: 0, c: 0 },
    dryWhey: { a: 0, b: 0, c: 0 },
    noFatDryMilk: { a: 0, b: 0, c: 0 },
    milkProd: { a: 0 },
  });
  const updateInputField = (fieldName: string, field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const object = {...inputFields};
    object[fieldName][field] = Number(event.target.value);
    mutateInputFields(object);
  };

  const returnToZero = (num: number) => num < 0 ? 0 : num;
  const roundOn = (num: number, decimals: number) => parseFloat(num.toFixed(decimals));
  const roundOn4 = (num: number) => roundOn(num, 4);
  const [ results, mutateResults ] = React.useState({
    actualCalculatedPrices: { butterfat: 0, protein: 0, otherSolids: 0, nonfatSolid: 0, classIIIMilk: 0, classIVMilk: 0, dryWhey: 0 },
    revenue: {
      guaranteeDPRPerCwt: { components: 0, class: 0 },
      actual: { components: 0, class: 0 },
      actualPerCwt: { components: 0, class: 0 },
      grossIndemnity: { components: 0, class: 0 },
      grossIndemnityPerCwt: { components: 0, class: 0 },
    },
    table: {
      class3: { a: 0, b: 0, c: 0 },
      class4: { a: 0, b: 0, c: 0 },
    },
  });
  const calculateResults = () => {
    const object = {...results};

    const calculateClassIIIActual = (month: string) => {
      const butterfatPrice = calculateButterfatPrices(inputFields.butter[month]);
      return roundOn(
        3.5 * butterfatPrice + 0.965 * (calculateProteinPrices(inputFields.cheese[month], butterfatPrice)
        * 3.1 + calculateOtherSolidPrices(inputFields.dryWhey[month]) * 5.9), 2);
    };
    const calculateNonfatSolidsPrices = (price: number) => roundOn4((price - 0.1678) * 0.99);
    const calculateClassIVActual = (month: string) => roundOn(
        roundOn((calculateNonfatSolidsPrices(inputFields.noFatDryMilk[month]) * 9), 2)
        * 0.965 + calculateButterfatPrices(inputFields.butter[month]) * 3.5, 2);

    const calculateButterfatPrices = (price: number) => (price - 0.1715) * 1.211;
    const calculateOtherSolidPrices = (price: number) => roundOn4((price - 0.1991) * 1.03);
    const calculateProteinPrices = (price: number, butterfatPrice: number) =>
      roundOn4((price - 0.2003) * 1.383) + roundOn4(roundOn4(roundOn4((price - 0.2003) * 1.572) - butterfatPrice * 0.9) * 1.17);

    object.actualCalculatedPrices.butterfat = roundOn4((
      calculateButterfatPrices(inputFields.butter.a) +
      calculateButterfatPrices(inputFields.butter.b) +
      calculateButterfatPrices(inputFields.butter.c)) / 3);
    object.actualCalculatedPrices.protein = roundOn4((
      calculateProteinPrices(inputFields.cheese.a, object.actualCalculatedPrices.butterfat) +
      calculateProteinPrices(inputFields.cheese.b, object.actualCalculatedPrices.butterfat) +
      calculateProteinPrices(inputFields.cheese.c, object.actualCalculatedPrices.butterfat)) / 3);
    object.actualCalculatedPrices.otherSolids = roundOn4((
      calculateOtherSolidPrices(inputFields.dryWhey.a) +
      calculateOtherSolidPrices(inputFields.dryWhey.b) +
      calculateOtherSolidPrices(inputFields.dryWhey.c)) / 3);

    object.table.class3.a = calculateClassIIIActual('a');
    object.table.class3.b = calculateClassIIIActual('b');
    object.table.class3.c = calculateClassIIIActual('c');
    object.actualCalculatedPrices.classIIIMilk = roundOn4((object.table.class3.a + object.table.class3.b + object.table.class3.c) / 3);

    object.table.class4.a = calculateClassIVActual('a');
    object.table.class4.b = calculateClassIVActual('b');
    object.table.class4.c = calculateClassIVActual('c');
    object.actualCalculatedPrices.classIVMilk = roundOn4((object.table.class4.a + object.table.class4.b + object.table.class4.c) / 3);

    object.revenue.guaranteeDPRPerCwt.components = data.Component.RevenueGuarantee / (coveredMilkProduction / 100);
    object.revenue.guaranteeDPRPerCwt.class = data.Class.RevenueGuarantee / (coveredMilkProduction / 100);

    object.revenue.actual.components = roundOn(
      roundOn(object.actualCalculatedPrices.butterfat * butterfatTest + object.actualCalculatedPrices.protein
        * proteinTest + object.actualCalculatedPrices.otherSolids * 5.7, 2)
      * coveredMilkProduction / 100 * inputFields.milkProd.a / data.MilkProduction.ExpectedPrice, 0);
    object.revenue.actual.class = roundOn(roundOn(
      object.actualCalculatedPrices.classIIIMilk * classPriceWeightingFactor + object.actualCalculatedPrices.classIVMilk
      * (1 - classPriceWeightingFactor), 2) * coveredMilkProduction
      / 100 * inputFields.milkProd.a / data.MilkProduction.ExpectedPrice, 0);

    object.revenue.actualPerCwt.components = object.revenue.actual.components / ( coveredMilkProduction / 100 );
    object.revenue.actualPerCwt.class = object.revenue.actual.class / ( coveredMilkProduction / 100 );

    object.revenue.grossIndemnity.components = returnToZero(
      roundOn(Math.max(data.Component.RevenueGuarantee - object.revenue.actual.components), 0));
    object.revenue.grossIndemnity.class = returnToZero(roundOn(Math.max(data.Class.RevenueGuarantee - object.revenue.actual.class), 0));

    object.revenue.grossIndemnityPerCwt.components = returnToZero(object.revenue.grossIndemnity.components / (coveredMilkProduction / 100));
    object.revenue.grossIndemnityPerCwt.class = returnToZero(object.revenue.grossIndemnity.class / (coveredMilkProduction / 100));

    mutateResults(object);
  };
  React.useEffect(calculateResults, [inputFields]);

  const [ data, setData ] = React.useState(apiData);
  React.useEffect(() => {
    (async () => {
      const response = await riskPremiumAPI.get({
        reinsuranceYear: 2019, share: 1,
        stateCode, practiceCode, salesEffectiveDate, butterfatTest, proteinTest, classPriceWeightingFactor,
        coverageLevel, coveredMilkProduction, protectionFactor,
      });
      setData(response.data as any);
      mutateInputFields({
        butter: {
          a: response.data.Component.ButterPrice.MonthPrices[0].ActualPrice,
          b: response.data.Component.ButterPrice.MonthPrices[1].ActualPrice,
          c: response.data.Component.ButterPrice.MonthPrices[2].ActualPrice,
        },
        cheese: {
          a: response.data.Component.CheesePrice.MonthPrices[0].ActualPrice,
          b: response.data.Component.CheesePrice.MonthPrices[1].ActualPrice,
          c: response.data.Component.CheesePrice.MonthPrices[2].ActualPrice,
        },
        dryWhey: {
          a: response.data.Component.DryWheyPrice.MonthPrices[0].ActualPrice,
          b: response.data.Component.DryWheyPrice.MonthPrices[1].ActualPrice,
          c: response.data.Component.DryWheyPrice.MonthPrices[2].ActualPrice,
        },
        noFatDryMilk: {
          a: response.data.Component.NonfatDryMilk.MonthPrices[0].ActualPrice,
          b: response.data.Component.NonfatDryMilk.MonthPrices[1].ActualPrice,
          c: response.data.Component.NonfatDryMilk.MonthPrices[2].ActualPrice,
        },
        milkProd: { a: response.data.MilkProduction.ActualPrice },
      });
    })();
  }, [
    stateCode, practiceCode, salesEffectiveDate, butterfatTest, proteinTest, classPriceWeightingFactor,
    coverageLevel, coveredMilkProduction, protectionFactor,
  ]);
  const formatDate = (d: any, s: any, o: any ) => format(d, s);

  return (
    <Root>
      <ContentHeader>Dairy Revenue Protection</ContentHeader>
      <SettingContainer>
        <SettingsRow>
          <InputContainer label="State">
            <SelectField options={stateCodeList} value={stateCodeName} onChange={onStateCodeChange} />
          </InputContainer>
          <InputContainer label="Quarter">
            <SelectField options={practiceCodeList} value={practiceCodeName} onChange={onPracticeCodeChange} />
          </InputContainer>
          <InputContainer label="Declared Milk Production">
            <DMPInput type="number" value={coveredMilkProduction} onChange={onCoveredMilkProductionChange} placeholder="" name=""/>
          </InputContainer>
          <InputContainer label="Sales Effective Date">
            <DayPickerInput
              value={salesEffectiveDate}
              onDayChange={onDateChange}
              format="MMMM DD, YYYY"
              formatDate={formatDate}
              dayPickerProps={{
                selectedDays: salesEffectiveDate,
              }}
            />
          </InputContainer>
        </SettingsRow>
        <SettingsRow>
          <InputContainer label="Coverage Level" value={`${coverageLevel * 100}%`}>
            <Slider value={coverageLevel} onChange={onCoverageLevelChange} min={0.7} max={0.95} step={0.05}/>
          </InputContainer>
          <InputContainer label="Declared Butterfat Test" value={butterfatTest}>
            <Slider value={butterfatTest} onChange={onButterfatTestChange} min={3.5} max={5} step={0.05}/>
          </InputContainer>
          <InputContainer label="" />
        </SettingsRow>
        <SettingsRow>
          <InputContainer label="Protection Factor" value={protectionFactor}>
            <Slider value={protectionFactor} onChange={onProtectionFactorChange} min={1} max={1.5} step={0.05}/>
          </InputContainer>
          <InputContainer label="Declared Protein Test" value={proteinTest}>
            <Slider value={proteinTest} onChange={onProteinTestChange} min={3} max={4} step={0.05}/>
          </InputContainer>
          <InputContainer label="Declared Class Price Weighting Factor" value={classPriceWeightingFactor}>
            <Slider value={classPriceWeightingFactor} onChange={onClassPriceWeightingFactorChange} min={0} max={1} step={0.05}/>
          </InputContainer>
        </SettingsRow>
      </SettingContainer>

      {/*<Table>*/}
      {/*  <thead>*/}
      {/*    <tr>*/}
      {/*      <TableHeader>Premiums</TableHeader>*/}
      {/*      <TableHeader>Components (4.0% BF, 3.2% PR)</TableHeader>*/}
      {/*      <TableHeader>Class III</TableHeader>*/}
      {/*      <TableHeader>Class IV</TableHeader>*/}
      {/*      <TableHeader>Class Blend (75% Class III, 25% Class IV)</TableHeader>*/}
      {/*    </tr>*/}
      {/*  </thead>*/}
      {/*  <tbody>*/}
      {/*    <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>*/}
      {/*      <td>Protected Pounds</td>*/}
      {/*      <Column>b</Column>*/}
      {/*      <Column>c</Column>*/}
      {/*      <Column>d</Column>*/}
      {/*      <Column>d</Column>*/}
      {/*    </tr>*/}

      {/*    <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>*/}
      {/*      <td>Revenue Guarantee</td>*/}
      {/*      <Column>b</Column>*/}
      {/*      <Column>c</Column>*/}
      {/*      <Column>d</Column>*/}
      {/*      <Column>d</Column>*/}
      {/*    </tr>*/}

      {/*    <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>*/}
      {/*      <td>Expected Basis</td>*/}
      {/*      <Column>b</Column>*/}
      {/*      <Column>c</Column>*/}
      {/*      <Column>d</Column>*/}
      {/*      <Column>d</Column>*/}
      {/*    </tr>*/}

      {/*    <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>*/}
      {/*      <td>Protected Revenue</td>*/}
      {/*      <Column>b</Column>*/}
      {/*      <Column>c</Column>*/}
      {/*      <Column>d</Column>*/}
      {/*      <Column>d</Column>*/}
      {/*    </tr>*/}

      {/*    <tr style={{ borderBottom: `solid 2px ${color.NEUTRAL}`}}>*/}
      {/*      <td>Premium</td>*/}
      {/*      <Column>b</Column>*/}
      {/*      <Column>c</Column>*/}
      {/*      <Column>d</Column>*/}
      {/*      <Column>d</Column>*/}
      {/*    </tr>*/}
      {/*  </tbody>*/}
      {/*</Table>*/}

      <Table>
        <thead>
        <tr>
          <TableHeader>Price</TableHeader>
          <TableHeader>Components</TableHeader>
          <TableHeader>Class III & IV</TableHeader>
        </tr>
        </thead>
        <tbody>
        <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
          <ColumnLabel>Expected Revenue</ColumnLabel>
          <Column>${formatValue(0)(data.Component.ExpectedRevenue)}</Column>
          <Column>${formatValue(0)(data.Class.ExpectedRevenue)}</Column>
        </tr>

        <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
          <ColumnLabel>Revenue Guarantee ($)</ColumnLabel>
          <Column>${formatValue(0)(data.Component.RevenueGuarantee)}</Column>
          <Column>${formatValue(0)(data.Class.RevenueGuarantee)}</Column>
        </tr>

        <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
          <ColumnLabel>Revenue Guarantee (per cwt)</ColumnLabel>
          <Column>${formatValue(2)(data.Component.RevenueGuaranteeCwt)}</Column>
          <Column>${formatValue(2)(data.Class.RevenueGuaranteeCwt)}</Column>
        </tr>

        <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
          <ColumnLabel>Premium before Subsidy ($)</ColumnLabel>
          <Column>${formatValue(0)(data.Component.Premium)}</Column>
          <Column>${formatValue(0)(data.Class.Premium)}</Column>
        </tr>

        <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
          <ColumnLabel>Premium before Subsidy (per cwt)</ColumnLabel>
          <Column>${formatValue(4)(data.Component.PremiumCwt)}</Column>
          <Column>${formatValue(4)(data.Class.PremiumCwt)}</Column>
        </tr>

        <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
          <ColumnLabel>Subsidy ($)</ColumnLabel>
          <Column>${formatValue(0)(data.Component.Subsidy)}</Column>
          <Column>${formatValue(0)(data.Class.Subsidy)}</Column>
        </tr>

        <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
          <ColumnLabel>Subsidy (per cwt)</ColumnLabel>
          <Column>${formatValue(4)(data.Component.SubsidyCwt)}</Column>
          <Column>${formatValue(4)(data.Class.SubsidyCwt)}</Column>
        </tr>

        <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
          <ColumnImportantLabel>Producer Premium Amount ($)</ColumnImportantLabel>
          <ColumnImportant>${formatValue(0)(data.Component.ProducerPreimum)}</ColumnImportant>
          <ColumnImportant>${formatValue(0)(data.Class.ProducerPreimum)}</ColumnImportant>
        </tr>

        <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
          <ColumnImportantLabel>Producer Premium Amount (per cwt)</ColumnImportantLabel>
          <ColumnImportant>${formatValue(2)(data.Component.ProducerPreimumCwt)}</ColumnImportant>
          <ColumnImportant>${formatValue(2)(data.Class.ProducerPreimumCwt)}</ColumnImportant>
        </tr>

        <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
          <ColumnLabel>Probability of Positive Indemnity</ColumnLabel>
          <Column>{formatValue(2)(data.Component.ProbabilityOfPostiveIndemnity)}%</Column>
          <Column>{formatValue(2)(data.Class.ProbabilityOfPostiveIndemnity)}%</Column>
        </tr>

        <tr style={{ borderBottom: `solid 3px ${color.NEUTRAL}`}}>
          <ColumnLabel>Probability of Positive Net Benefit</ColumnLabel>
          <Column>{formatValue(2)(data.Component.ProbabilityOfPositiveNetBenefit)}%</Column>
          <Column>{formatValue(2)(data.Class.ProbabilityOfPositiveNetBenefit)}%</Column>
        </tr>
        </tbody>
      </Table>

      {/*<Accordion title="Historical Analysis" visible={isHistoricalAccordionOpen} onClick={onHistoricalAccordionClick}>*/}
      {/*  Testing Component*/}
      {/*</Accordion>*/}
      <Accordion title="What-If Analysis" visible={isWhatIfOpen} onClick={onWhatIfAccordionClick}>
        <WhatIfContainer>
          <WhatIfInputContainer>
            <Table>
              <thead>
                <tr>
                  <TableHeader>Dairy</TableHeader>
                  <TableHeader>Month</TableHeader>
                  <TableHeader>Expected</TableHeader>
                  <TableHeader>Actual</TableHeader>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={3} style={{ borderBottom: `solid 2px ${color.NEUTRAL}`}}>Butter</td>
                  <ColumnMonth>{format(data.Component.ButterPrice.MonthPrices[0].Date, 'MMM-YY')}</ColumnMonth>
                  <ColumnExpected>${formatValue(4)(data.Component.ButterPrice.MonthPrices[0].ExpectedPrice)}</ColumnExpected>
                  <Column>
                    <TableInput type="number" value={inputFields.butter.a} onChange={updateInputField('butter', 'a')}/>
                  </Column>
                </tr>
                <tr>
                  <ColumnMonth>{format(data.Component.ButterPrice.MonthPrices[1].Date, 'MMM-YY')}</ColumnMonth>
                  <ColumnExpected>${formatValue(4)(data.Component.ButterPrice.MonthPrices[1].ExpectedPrice)}</ColumnExpected>
                  <Column>
                    <TableInput type="number" value={inputFields.butter.b} onChange={updateInputField('butter', 'b')}/>
                  </Column>
                </tr>
                <tr style={{ borderBottom: `solid 2px ${color.NEUTRAL}`}}>
                  <ColumnMonth>{format(data.Component.ButterPrice.MonthPrices[2].Date, 'MMM-YY')}</ColumnMonth>
                  <ColumnExpected>${formatValue(4)(data.Component.ButterPrice.MonthPrices[2].ExpectedPrice)}</ColumnExpected>
                  <Column>
                    <TableInput type="number" value={inputFields.butter.c} onChange={updateInputField('butter', 'c')}/>
                  </Column>
                </tr>

                <tr>
                  <td rowSpan={3} style={{ borderBottom: `solid 2px ${color.NEUTRAL}`}}>Cheese</td>
                  <ColumnMonth>{format(data.Component.CheesePrice.MonthPrices[0].Date, 'MMM-YY')}</ColumnMonth>
                  <ColumnExpected>${formatValue(4)(data.Component.CheesePrice.MonthPrices[0].ExpectedPrice)}</ColumnExpected>
                  <Column>
                    <TableInput type="number" value={inputFields.cheese.a} onChange={updateInputField('cheese', 'a')}/>
                  </Column>
                </tr>
                <tr>
                  <ColumnMonth>{format(data.Component.CheesePrice.MonthPrices[1].Date, 'MMM-YY')}</ColumnMonth>
                  <ColumnExpected>${formatValue(4)(data.Component.CheesePrice.MonthPrices[1].ExpectedPrice)}</ColumnExpected>
                  <Column>
                    <TableInput type="number" value={inputFields.cheese.b} onChange={updateInputField('cheese', 'b')}/>
                  </Column>
                </tr>
                <tr style={{ borderBottom: `solid 2px ${color.NEUTRAL}`}}>
                  <ColumnMonth>{format(data.Component.CheesePrice.MonthPrices[2].Date, 'MMM-YY')}</ColumnMonth>
                  <ColumnExpected>${formatValue(4)(data.Component.CheesePrice.MonthPrices[2].ExpectedPrice)}</ColumnExpected>
                  <Column>
                    <TableInput type="number" value={inputFields.cheese.c} onChange={updateInputField('cheese', 'c')}/>
                  </Column>
                </tr>

                <tr>
                  <td rowSpan={3} style={{ borderBottom: `solid 2px ${color.NEUTRAL}`}}>Dry Whey</td>
                  <ColumnMonth>{format(data.Component.DryWheyPrice.MonthPrices[0].Date, 'MMM-YY')}</ColumnMonth>
                  <ColumnExpected>${formatValue(4)(data.Component.DryWheyPrice.MonthPrices[0].ExpectedPrice)}</ColumnExpected>
                  <Column>
                    <TableInput type="number" value={inputFields.dryWhey.a} onChange={updateInputField('dryWhey', 'a')}/>
                  </Column>
                </tr>
                <tr>
                  <ColumnMonth>{format(data.Component.DryWheyPrice.MonthPrices[1].Date, 'MMM-YY')}</ColumnMonth>
                  <ColumnExpected>${formatValue(4)(data.Component.DryWheyPrice.MonthPrices[1].ExpectedPrice)}</ColumnExpected>
                  <Column>
                    <TableInput type="number" value={inputFields.dryWhey.b} onChange={updateInputField('dryWhey', 'b')}/>
                  </Column>
                </tr>
                <tr style={{ borderBottom: `solid 2px ${color.NEUTRAL}`}}>
                  <ColumnMonth>{format(data.Component.DryWheyPrice.MonthPrices[2].Date, 'MMM-YY')}</ColumnMonth>
                  <ColumnExpected>${formatValue(4)(data.Component.DryWheyPrice.MonthPrices[2].ExpectedPrice)}</ColumnExpected>
                  <Column>
                    <TableInput type="number" value={inputFields.dryWhey.c} onChange={updateInputField('dryWhey', 'c')}/>
                  </Column>
                </tr>

                <tr>
                  <td rowSpan={3} style={{ borderBottom: `solid 2px ${color.NEUTRAL}`}}>Nonfat Dry Milk</td>
                  <ColumnMonth>{format(data.Component.NonfatDryMilk.MonthPrices[0].Date, 'MMM-YY')}</ColumnMonth>
                  <ColumnExpected>${formatValue(4)(data.Component.NonfatDryMilk.MonthPrices[0].ExpectedPrice)}</ColumnExpected>
                  <Column>
                    <TableInput type="number" value={inputFields.noFatDryMilk.a} onChange={updateInputField('noFatDryMilk', 'a')}/>
                  </Column>
                </tr>
                <tr>
                  <ColumnMonth>{format(data.Component.NonfatDryMilk.MonthPrices[1].Date, 'MMM-YY')}</ColumnMonth>
                  <ColumnExpected>${formatValue(4)(data.Component.NonfatDryMilk.MonthPrices[1].ExpectedPrice)}</ColumnExpected>
                  <Column>
                    <TableInput type="number" value={inputFields.noFatDryMilk.b} onChange={updateInputField('noFatDryMilk', 'b')}/>
                  </Column>
                </tr>
                <tr style={{ borderBottom: `solid 2px ${color.NEUTRAL}`}}>
                  <ColumnMonth>{format(data.Component.NonfatDryMilk.MonthPrices[2].Date, 'MMM-YY')}</ColumnMonth>
                  <ColumnExpected>${formatValue(4)(data.Component.NonfatDryMilk.MonthPrices[2].ExpectedPrice)}</ColumnExpected>
                  <Column>
                    <TableInput type="number" value={inputFields.noFatDryMilk.c} onChange={updateInputField('noFatDryMilk', 'c')}/>
                  </Column>
                </tr>

                <tr>
                  <td rowSpan={3} style={{ borderBottom: `solid 2px ${color.NEUTRAL}`}}>Class III Milk</td>
                  <ColumnMonth>{format(data.Class.ClassIIIPrice.MonthPrices[0].Date, 'MMM-YY')}</ColumnMonth>
                  <ColumnExpected>${formatValue(2)(data.Class.ClassIIIPrice.MonthPrices[0].ExpectedPrice)}</ColumnExpected>
                  <Column>${formatValue(2)(results.table.class3.a)}</Column>
                </tr>
                <tr>
                  <ColumnMonth>{format(data.Class.ClassIIIPrice.MonthPrices[1].Date, 'MMM-YY')}</ColumnMonth>
                  <ColumnExpected>${formatValue(2)(data.Class.ClassIIIPrice.MonthPrices[1].ExpectedPrice)}</ColumnExpected>
                  <Column>${formatValue(2)(results.table.class3.b)}</Column>
                </tr>
                <tr style={{ borderBottom: `solid 2px ${color.NEUTRAL}`}}>
                  <ColumnMonth>{format(data.Class.ClassIIIPrice.MonthPrices[2].Date, 'MMM-YY')}</ColumnMonth>
                  <ColumnExpected>${formatValue(2)(data.Class.ClassIIIPrice.MonthPrices[2].ExpectedPrice)}</ColumnExpected>
                  <Column>${formatValue(2)(results.table.class3.c)}</Column>
                </tr>

                <tr>
                  <td rowSpan={3} style={{ borderBottom: `solid 2px ${color.NEUTRAL}`}}>Class IV Milk</td>
                  <ColumnMonth>{format(data.Class.ClassIVPrice.MonthPrices[0].Date, 'MMM-YY')}</ColumnMonth>
                  <ColumnExpected>${formatValue(2)(data.Class.ClassIVPrice.MonthPrices[0].ExpectedPrice)}</ColumnExpected>
                  <Column>${formatValue(2)(results.table.class4.a)}</Column>
                </tr>
                <tr>
                  <ColumnMonth>{format(data.Class.ClassIVPrice.MonthPrices[1].Date, 'MMM-YY')}</ColumnMonth>
                  <ColumnExpected>${formatValue(2)(data.Class.ClassIVPrice.MonthPrices[1].ExpectedPrice)}</ColumnExpected>
                  <Column>${formatValue(2)(results.table.class4.b)}</Column>
                </tr>
                <tr style={{ borderBottom: `solid 2px ${color.NEUTRAL}`}}>
                  <ColumnMonth>{format(data.Class.ClassIVPrice.MonthPrices[2].Date, 'MMM-YY')}</ColumnMonth>
                  <ColumnExpected>${formatValue(2)(data.Class.ClassIVPrice.MonthPrices[2].ExpectedPrice)}</ColumnExpected>
                  <Column>${formatValue(2)(results.table.class4.c)}</Column>
                </tr>

                <tr style={{ borderBottom: `solid 2px ${color.NEUTRAL}`}}>
                  <ColumnLabel>Milk Production<br />Per Cow</ColumnLabel>
                  <ColumnMonth>Oct - Dec,<br />2019</ColumnMonth>
                  <ColumnExpected>{formatValue(0)(data.MilkProduction.ExpectedPrice)}</ColumnExpected>
                  <Column>
                    <TableInput type="number" value={inputFields.milkProd.a} onChange={updateInputField('milkProd', 'a')}/>
                  </Column>
                </tr>
              </tbody>
            </Table>
          </WhatIfInputContainer>
          <WhatIfResultContainer>
            <Table>
              <thead>
                <tr>
                  <TableHeader>Price</TableHeader>
                  <TableHeader>Expected</TableHeader>
                  <TableHeader>Actual</TableHeader>
                </tr>
              </thead>
              <tbody>
                <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
                  <ColumnLabel>Butterfat</ColumnLabel>
                  <Column>${formatValue(4)(data.Component.ButterfatPrice.ExpectedPrice)}</Column>
                  <Column>${formatValue(4)(results.actualCalculatedPrices.butterfat)}</Column>
                </tr>

                <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
                  <ColumnLabel>Protein</ColumnLabel>
                  <Column>${formatValue(4)(data.Component.ProteinPrice.ExpectedPrice)}</Column>
                  <Column>${formatValue(4)(results.actualCalculatedPrices.protein)}</Column>
                </tr>

                <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
                  <ColumnLabel>Other Solids Price</ColumnLabel>
                  <Column>${formatValue(4)(data.Component.OtherSolidsPrice.ExpectedPrice)}</Column>
                  <Column>${formatValue(4)(results.actualCalculatedPrices.otherSolids)}</Column>
                </tr>

                <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
                  <ColumnLabel>Class III Price</ColumnLabel>
                  <Column>${formatValue(2)(data.Class.ClassIIIPrice.ExpectedPrice)}</Column>
                  <Column>${formatValue(2)(results.actualCalculatedPrices.classIIIMilk)}</Column>
                </tr>

                <tr style={{ borderBottom: `solid 2px ${color.NEUTRAL}`}}>
                  <ColumnLabel>Class IV Price</ColumnLabel>
                  <Column>${formatValue(2)(data.Class.ClassIVPrice.ExpectedPrice)}</Column>
                  <Column>${formatValue(2)(results.actualCalculatedPrices.classIVMilk)}</Column>
                </tr>
              </tbody>
            </Table>

            <Table>
              <thead>
                <tr>
                  <TableHeader>Price</TableHeader>
                  <TableHeader>Components</TableHeader>
                  <TableHeader>Class III & IV</TableHeader>
                </tr>
              </thead>
              <tbody>
                <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
                  <ColumnLabel>Expected DRP<br />Revenue (per cwt)</ColumnLabel>
                  <Column>${formatValue(2)(data.Component.ExpectedRevenueCwt)}</Column>
                  <Column>${formatValue(2)(data.Class.ExpectedRevenueCwt)}</Column>
                </tr>

                <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
                  <ColumnLabel>DRP Revenue<br />Guarantee ($)</ColumnLabel>
                  <Column>${formatValue(0)(data.Component.RevenueGuarantee)}</Column>
                  <Column>${formatValue(0)(data.Class.RevenueGuarantee)}</Column>
                </tr>

                <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
                  <ColumnLabel>DRP Revenue<br />Guarantee (per cwt)</ColumnLabel>
                  <Column>${formatValue(2)(results.revenue.guaranteeDPRPerCwt.components)}</Column>
                  <Column>${formatValue(2)(results.revenue.guaranteeDPRPerCwt.class)}</Column>
                </tr>

                <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
                  <ColumnLabel>Actual Revenue<br />($)</ColumnLabel>
                  <Column>${formatValue(0)(results.revenue.actual.components)}</Column>
                  <Column>${formatValue(0)(results.revenue.actual.class)}</Column>
                </tr>

                <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
                  <ColumnLabel>Actual Revenue<br />(per cwt)</ColumnLabel>
                  <Column>${formatValue(2)(results.revenue.actualPerCwt.components)}</Column>
                  <Column>${formatValue(2)(results.revenue.actualPerCwt.class)}</Column>
                </tr>

                <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`}}>
                  <ColumnLabel>Gross Indemnity<br />($)</ColumnLabel>
                  <Column>${formatValue(0)(results.revenue.grossIndemnity.components)}</Column>
                  <Column>${formatValue(0)(results.revenue.grossIndemnity.class)}</Column>
                </tr>

                <tr style={{ borderBottom: `solid 3px ${color.NEUTRAL}`}}>
                  <ColumnLabel>Gross Indemnity<br />(per cwt)</ColumnLabel>
                  <Column>${formatValue(2)(results.revenue.grossIndemnityPerCwt.components)}</Column>
                  <Column>${formatValue(2)(results.revenue.grossIndemnityPerCwt.class)}</Column>
                </tr>
              </tbody>
            </Table>
          </WhatIfResultContainer>
        </WhatIfContainer>
      </Accordion>
    </Root>
  );
};

export default RevenuePredictionContainer;
