import styled from 'styled-components';
import color from '../../../../utils/color';

const ContentTitle = styled.div`
  display: flex;
  margin: 0 0 10px 5px;

  font-size: 16px;
  font-weight: 400;
  color: ${color.TEXT_PRIMARY};
  opacity: 30%;

  svg {
    color: ${color.PRIMARY}99;
    padding-right: 5px;
    transform: translateY(2px);
  }
`;

export default ContentTitle;
