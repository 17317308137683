import * as React from 'react';
import FilterContentHeader from '../../../components/Catalog/styles/FilterContentHeader/FilterContentHeader';
import ContentTitle from '../../../components/Catalog/styles/ContentTitle/ContentTitle';
import FilterIcon from '../../../icons/FilterIcon';
import ClearFilterContainer from '../../../components/Catalog/styles/ClearFilterContainer/ClearFilterContainer';
import MinusIcon from '../../../icons/MinusIcon';
import FilterContent from '../../../components/Catalog/styles/FilterContent/FilterContent';
import FilterLabel from '../../../components/Catalog/FilterLabel/FilterLabel';
import { RootState } from '../../../ducks/store';
import { getCatalogSort, getCategoryId, getCountryId, getMeasureId } from '../../../ducks/catalog/selectors';
import { catalogActions } from '../../../ducks/catalog/actions';
import { connect } from 'react-redux';
import CatalogSortContainer from '../../../containers/CatalogSortContainer/CatalogSortContainer';
import PaginationWrapper from '../../../components/AdminPanel/adminPanel/PaginationWrapper';
import PerPage from '../../../components/shared/Pagination/perPage';
import PaginationContainer from '../../../components/Catalog/styles/PaginationContainer/PaginationContainer';
import PaginationBox from '../../../components/Catalog/styles/PaginationBox/PaginationBox';
import { ChangeEvent } from 'react';
import CategoriesCatalogContainer from '../../../containers/catalog/CategoriesCatalogContainer/CategoriesCatalogContainer';
import MeasuresCatalogContainer from '../../../containers/catalog/MeasuresCatalogContainer/MeasuresCatalogContainer';
import catalogAPI from '../../../services/catalogServices';
import SeriesLink from '../../../components/Catalog/styles/SeriesLink/SeriesLink';
import { Link } from 'react-router-dom';
import { RouterState } from '../CatalogScene';
import CountriesCatalogContainer from '../../../containers/catalog/CountriesCatalogContainer/CountriesCatalogContainer';
import LangPicker from '../../../components/shared/LangPicker/LangPicker';

enum ModalView {
  none,
  countries,
  categories,
  measures,
}

interface OwnProps {
  search: string;
  locationState: RouterState,
}

type Props = OwnProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const CatalogPublishersScene: React.FC<Props> =
  ({ search, sort, countryList, categoryList, measureList, clearAll, resetFilterSearch }) => {
    const [ translated, setTranslation ] = React.useState(false);
    const switchClick = () => { setTranslation(!translated); };

    const [ pageSize, setPageSize ] = React.useState(25);
    const onPageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => { setPageSize(Number(event.target.value)) };

    const [ page, setPage ] = React.useState(1);
    const setNextPage = () => { setPage(page - 1) };
    const setPreviousPage = () => { setPage(page + 1) };

    const [ seriesList, setList ] = React.useState<Array<{id: number, name: string}>>([]);
    const [ hasNext, setHasNext ] = React.useState(false);

    const [ modalView, setModalView ] = React.useState(ModalView.none);
    const onFilterClick = (filter: ModalView) => () => { resetFilterSearch(); setModalView(filter) };
    const getModalView = () => {
      const modalProps = { onClose: () => { setModalView(ModalView.none) }, isTranslated: translated };
      switch (modalView) {
        case ModalView.countries: return <CountriesCatalogContainer {...modalProps} />;
        case ModalView.categories: return <CategoriesCatalogContainer {...modalProps} />;
        case ModalView.measures: return <MeasuresCatalogContainer {...modalProps} />;
        default: return null;
      }
    };
    const clearAllFilters = () => {
      clearAll();
    };

    React.useEffect(() => {
      if (modalView === ModalView.none) {
        (async () => {
          const response = await catalogAPI.searchPublishers({
            pageSize, translated,
            page: page - 1,
            contains: search.length >= 3 ? search : '',
            startsWith: sort === 'All' || sort === 'Recent' ? '' : sort,
          }, {
              countryId: countryList,
              categoryId: categoryList,
              measureId: measureList,
            });
          setList(response.data);
          setHasNext(response.hasNext);
        })();
      }
    }, [translated, search, pageSize, page, sort, modalView, countryList, categoryList, measureList]);
    return (
      <>
        <FilterContentHeader>
          <ContentTitle>
            <FilterIcon size={16} /> Sort & Filter
          </ContentTitle>
          <ClearFilterContainer onClick={clearAllFilters}><MinusIcon /> Clear Filters</ClearFilterContainer>
        </FilterContentHeader>
        <FilterContent>
          <FilterLabel isActive={countryList.length > 0} onClick={onFilterClick(ModalView.countries)}>Countries</FilterLabel>
          <FilterLabel isActive={categoryList.length > 0} onClick={onFilterClick(ModalView.categories)}>Categories</FilterLabel>
          <FilterLabel isActive={measureList.length > 0} onClick={onFilterClick(ModalView.measures)}>Measures</FilterLabel>
        </FilterContent>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <CatalogSortContainer />
          <LangPicker active={translated} onClick={switchClick} />
        </div>
        {seriesList.map(({ id, name }) => (<SeriesLink key={id}>
          <Link to={{ state: { nested: [{ name: 'Publisher', forName: 'Reports', description: name, id }] } }}>{name}</Link></SeriesLink>))}
        <PaginationWrapper>
          {/*TODO: use dataLength from backend*/}
          <PerPage dataLength={100} perPage={pageSize} perPageClickHandler={onPageSizeChange} />
          <PaginationContainer>
            { page > 1 && <PaginationBox onClick={setNextPage}>Previous</PaginationBox>}
            { hasNext && <PaginationBox onClick={setPreviousPage}>Next</PaginationBox>}
          </PaginationContainer>
        </PaginationWrapper>
        {getModalView()}
      </>
    );
  };

const mapStateToProps = (state: RootState) => ({
  sort: getCatalogSort(state),
  countryList: getCountryId(state),
  categoryList: getCategoryId(state),
  measureList: getMeasureId(state),
});

const mapDispatchToProps = {
  clearAll: catalogActions.filter.clearAll,
  resetFilterSearch: catalogActions.filterUtils.reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogPublishersScene);
