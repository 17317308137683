import axios, { AxiosResponse } from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_API_URL}/direct/catalog/`,
  timeout: 20000,
});

const addQueryParameter = (currentParams: string, query: string, param: string | number | boolean | number[]) =>
  `${currentParams && `${currentParams}&`}${query}=${param}`;

const createSearchCriteriaQuery = ({page, pageSize, translated, startsWith, contains}: SearchCriteria) => {
  let query = '';
  if (page) { query = addQueryParameter(query, 'searchCriteria.page', page); }
  if (pageSize) { query = addQueryParameter(query, 'searchCriteria.pageSize', pageSize); }
  if (translated !== undefined) { query = addQueryParameter(query, 'searchCriteria.translated', translated); }
  if (startsWith) { query = addQueryParameter(query, 'searchCriteria.startsWith', startsWith); }
  if (contains) { query = addQueryParameter(query, 'searchCriteria.contains', contains); }
  return query ? `?${query}` : '';
};

const createSearchSeriesQuery =
  (searchCriteria: SearchCriteria, {publisherId, areaId, categoryId, measureId, reportCode, countryId}: CatalogFilters) => {
  let query = createSearchCriteriaQuery(searchCriteria);
  if (publisherId && publisherId.length > 0) { query = addQueryParameter(query, 'publisherId', publisherId.toString()); }
  if (areaId && areaId.length > 0) { query = addQueryParameter(query, 'areaId', areaId.toString()); }
  if (categoryId && categoryId.length > 0) { query = addQueryParameter(query, 'categoryId', categoryId.toString()); }
  if (measureId && measureId.length > 0) { query = addQueryParameter(query, 'measureId', measureId.toString()); }
  if (reportCode && reportCode.length > 0) { query = addQueryParameter(query, 'reportCode', reportCode.toString()); }
  if (countryId && countryId.length > 0) { query = addQueryParameter(query, 'countryId', countryId.toString()); }
  return query ? query : '';
};

interface CatalogFilters {
  publisherId?: number[],
  areaId?: number[],
  categoryId?: number[],
  measureId?: number[],
  reportCode?: string[],
  countryId?: number[],
}

interface SearchCriteria {
  page?: number,
  pageSize?: number,
  translated?: boolean,
  contains?: string,
  startsWith?: string,
}

const catalogAPI = {
  searchSeries: async (searchCriteria: SearchCriteria, catalogSeries: CatalogFilters) =>
    api.get(`series${createSearchSeriesQuery(searchCriteria, catalogSeries)}`)
      .then((response: AxiosResponse<{ HasNext: boolean, Data: Array<{ Id: number, Name: string }> }>) => ({
        data: response.data.Data ? response.data.Data.map(({Id, Name}: {Id: number, Name: string}) => ({id: Id, name: Name})) : [],
        hasNext: response.data.HasNext,
      })),
  searchReports: async (searchCriteria: SearchCriteria, catalogSeries: CatalogFilters) =>
    api.get(`report${createSearchSeriesQuery(searchCriteria, catalogSeries)}`)
      .then((response: AxiosResponse<{ HasNext: boolean, Data: Array<{ Id: number, Name: string, Code: string }> }>) => ({
        data: response.data.Data ? response.data.Data.map(({Code, Name}: {Code: string, Name: string}) => ({id: Code, name: Name})) : [],
        hasNext: response.data.HasNext,
      })),
  searchPublishers: async (searchCriteria: SearchCriteria, catalogSeries: CatalogFilters) =>
    api.get(`publisher${createSearchSeriesQuery(searchCriteria, catalogSeries)}`)
      .then((response: AxiosResponse<{ HasNext: boolean, Data: Array<{ Id: number, Name: string }> }>) => ({
        data: response.data.Data ? response.data.Data.map(({Id, Name}: {Id: number, Name: string}) => ({id: Id, name: Name})) : [],
        hasNext: response.data.HasNext,
      })),
};

export default catalogAPI;
