import produce from 'immer';
import { getType } from 'typesafe-actions';
import { ContextTableActions, contextTableActions } from './actions';
import { SeriesContext } from '../../types/SeriesContext';
import createUniqueArray from '../../utils/createUniqueArray';
import { TableContext } from '../../types/TableContext';

export interface ContextTableState {
  allSeriesTables: number[],
  seriesTableById: { [seriesID: string] : SeriesContext[] },
  allTables: number[],
  tablesById: { [tableID: number] : TableContext[] },
}

export const initialState: ContextTableState = {
  allSeriesTables: [],
  seriesTableById: {},
  allTables: [],
  tablesById: {},
};

export const contextTableReducer = produce<ContextTableState, ContextTableActions>(
  (state, action) => {
    switch (action.type) {
      case getType(contextTableActions.series.add):
        state.allSeriesTables =
          createUniqueArray<number>([...state.allSeriesTables, Number(action.meta)]);
        state.seriesTableById[action.meta] = action.payload;
        return;
      case getType(contextTableActions.table.add):
        state.allTables =
          createUniqueArray<number>([...state.allTables, action.meta]);
        state.tablesById[action.meta] = action.payload;
        return;
    }
  }, initialState,
);
