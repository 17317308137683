import { PeriodFrequency } from '../types/PeriodFrequency';
import { ExploreData } from '../utils/enums';
import { Product } from '../types/Product';
import { DisplayDateRange } from './productPage/reducers';
import { UnitConversionFunction } from '../utils/createUnitConversionFunction';
import { match } from 'react-router';

export const getProductIDFromProps = <P>(_: any, props: P & { productID: string}): string => props.productID;

export const getFrequencyFromProps = <P>(_: any, props: P & { frequency: PeriodFrequency}): PeriodFrequency => props.frequency;

export const getDisplayDateRangeFromProps = <P>(_: any, props: P & { fromDate: Date, toDate: Date}): DisplayDateRange =>
  ({ from: props.fromDate, to: props.toDate });

export const getAreaIDFromProps = <P>(_: any, props: P & { areaID: number}): number => props.areaID;

export const getExploreDataViewFromProps = <P>(_: any, props: P & { exploreDataView: ExploreData}): ExploreData => props.exploreDataView;

export const getProductFromProps = <P>(_: any, props: P & { product: Product}): Product => props.product;

export const getUnitIDFromProps = <P>(_: any, props: P & { unitID: number }): number => props.unitID;

export const getSelectedUnitIDFromProps = <P>(_: any, props: P & { selectedUnitID: number }): number => props.selectedUnitID;

export const getUnitConversionFunctionFromProps = <P>(_: any,
  props: P & { unitConversionFunction: UnitConversionFunction },
  ): UnitConversionFunction => props.unitConversionFunction;

export const getChartIDFromProps = <P>(_: any, props: P & { chartID: number}): number => props.chartID;

export const getActivePageFromProps = <P>(_: any, props: P & {activePage: number}): number => props.activePage;

export const getPerPageFromProps = <P>(_: any, props: P & {perPage: number}): number => props.perPage;

export const getSelectedCalendarFromProps = <P>(_: any, props: P & {selectedCalendar: string}): string => props.selectedCalendar;

export const getSearchFromProps = <P>(_: any, props: P & {search: string}): string => props.search;

export const getOrganizationNameFromProps = <P>(_: any, props: P & {selectedOrganization: string}): string => props.selectedOrganization;

export const getSortFromProps = <P>(_: any, props: P & {sort: string}): string => props.sort;

export const getDirectionFromProps = <P>(_: any, props: P & {direction: string}): string => props.direction;

export const getMenuIDFromProps = <P>(_: any, props: P & {match: match<{id: string}>}): string => props.match.params.id;

export const getIsTranslatedFromProps = <P>(_: any, props: P & {isTranslated: boolean}): boolean => props.isTranslated;
