import { all, call, put, takeLatest, delay } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { adminActions } from '../actions';
import mapReportDtoToReport from './utils/mapReportDtoToReport';
import { toast } from 'react-toastify';
import { authSaga } from '../../authentication/getAuthStatus';
import UdmDB from '../../../utils/db';
import directAPI from '../../../services/directServices';
import { AxiosResponse } from 'axios';
import { ReportDto } from '../../../types/dto/ReportDto';

let getReportsFirstError = true;
let cachedData = false;

export function* getReports() {
  try {
    const cache = yield call(UdmDB.getAllReports);
    if (cache.length !== 0) {
      cachedData = true;
    }
    yield put(adminActions.report.add(cache));

    const reports: AxiosResponse<ReportDto[]> = yield call(directAPI.fetchReports);
    const mappedReports = yield mapReportDtoToReport(reports.data);
    yield call(UdmDB.clearAllReports);
    yield call(UdmDB.addAllReports, mappedReports);
    getReportsFirstError = true;

    // find duplicates in array...
    // const uniq = mappedReports
    //   .map((c: any) => ({count: 1, name: c.name}))
    //   .reduce((a: any, b: any) => {
    //     a[b.name] = (a[b.name] || 0) + b.count;
    //     return a
    //   }, {});
    //
    // console.log('d', Object.keys(uniq).filter((a) => uniq[a] > 1));
    yield put(adminActions.report.add(mappedReports));
  } catch (error) {
    const timeoutError = error.message.split(' ')[0] === 'timeout';
    if  (timeoutError && getReportsFirstError) {
      getReportsFirstError = false;
      yield delay(40000);
      yield put(adminActions.report.get());
    } else if (!cachedData || !timeoutError) {
      toast.error(error.message, {
        position: 'top-center',
      });
    }
  }
}

function* reportSagaWatcher() {
  yield all([
    takeLatest(getType(adminActions.report.get), authSaga(getReports)),
  ]);
}

export default reportSagaWatcher;
