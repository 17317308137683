import { RootState } from '../store';
import { createSelector } from 'reselect';
import { getMenuIDFromProps } from '../fromPropsSelector';

export const getNavMenuItems = (state: RootState) => state.menu.navMenu;

export const getSelectedMenu = (state: RootState) => state.menu.selectedMenu;

export const getAllMenus = (state: RootState) => state.menu.menuById;

export const getSelectedNavMenuContent = createSelector(
  getNavMenuItems,
  getMenuIDFromProps,
  (navMenuItems, selectedNavMenuID) => {
    if (!getNavMenuItems) {
      return [];
    }
    const selectedNavMenuItem = navMenuItems.find((navMenuItem) => navMenuItem.id === Number(selectedNavMenuID));
    if (!selectedNavMenuItem) {
      return [];
    }

    return selectedNavMenuItem.children;
  },
);

export const getSelectedSubmenu = createSelector(
  getNavMenuItems,
  getMenuIDFromProps,
  getSelectedMenu,
  (navMenuItems, selectedMenuID, selectedSubmenu) => {
    if (selectedSubmenu) {
      return selectedSubmenu;
    }
    const selectedNavMenuItem = navMenuItems.findIndex((navMenuItem) => navMenuItem.id === Number(selectedMenuID));

    return navMenuItems[selectedNavMenuItem];
  },
);

export const getMenuReports = createSelector(
  getAllMenus,
  getSelectedSubmenu,
  (allMenus, selectedMenu) => {
    if (!allMenus || !selectedMenu) {
      return null;
    }

    if (!allMenus[selectedMenu.id]) {
      return null;
    }
    return allMenus[selectedMenu.id];
  },
);
