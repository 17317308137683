import { ActionType, createStandardAction } from 'typesafe-actions';
import { ProductWithAttributes } from '../../types/Product';
import { PeriodFrequency } from '../../types/PeriodFrequency';

interface Meta {
  frequency?: PeriodFrequency,
  area?: number
}

export const productActions = {
  initialize: createStandardAction('@product/INITIALIZE_PRODUCT_SCENE')<string, Meta>(),
  changeProductFrequency: createStandardAction('@product/CHANGE_PRODUCT_FREQUENCY')<string, Meta>(),
  changeProductArea: createStandardAction('@product/CHANGE_PRODUCT_AREA')<string, Meta>(),
  addProduct: createStandardAction('@product/ADD_PRODUCT')<ProductWithAttributes>(),
};

export type ProductActions = ActionType<typeof productActions>;
