import * as oidc from 'oidc-client';
import store from '../store';
import { authActions } from './actions';
import { AuthState } from './reducers';

// TODO: set ENV file....
const url = window.location.origin;

const userManager = new oidc.UserManager({
  authority: `${process.env.REACT_APP_ADMIN_API_URL}/identity`,
  // authority: 'https://identityserver4-udm-dev.azurewebsites.net',
  client_id: 'udmjs',
  // redirect_uri: 'http://localhost:3000/callback.html',
  redirect_uri: `${url}/callback/login`,
  silent_redirect_uri: `${url}/callback/refresh`,
  post_logout_redirect_uri: `${url}/callback/logout`,
  automaticSilentRenew: true,
  response_type: 'id_token token',
  scope: 'openid profile roles dairymarkets_organization_api dairymarkets_identity_api dairymarkets_direct_api',
});

userManager.events.addAccessTokenExpired( () => {
  store.dispatch(authActions.token.expired());
  store.dispatch(authActions.set.authState(AuthState.expired));
});

userManager.events.addAccessTokenExpiring( () => {
  store.dispatch(authActions.token.expiring());
});

userManager.events.addUserLoaded(() => {
  store.dispatch(authActions.token.update());
});

export default userManager;
