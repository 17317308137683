import { getAuthState, getExpiresAt } from './selectors';
import store from '../store';
import { AuthState } from './reducers';
import userManager from './userManager';
import { call } from 'redux-saga/effects';

type HelperFunc<A> = (action: A) => any;

export const authSaga = <Action>(saga: HelperFunc<Action>) => function* (action: Action) {
  const result = yield call(getAuthStatus);
  if (result === AuthState.administrator || result === AuthState.moderator || result === AuthState.member) {
    yield* saga(action);
  }
};

const getAuthStatus = () => new Promise((resolve) => {
  const authState = getAuthState(store.getState());
  const expiresAtState = getExpiresAt(store.getState());
  const isExpired = expiresAtState ? expiresAtState < Math.floor(Date.now() / 1000) : true;
  if (((authState === AuthState.administrator || authState === AuthState.moderator || authState === AuthState.member) &&
      !isExpired) || authState === AuthState.guest) {
    resolve(authState);
  }

  const timeoutTimer = setTimeout(() => {
    unsubscribe();
    // store.dispatch(authActions.set.authState(AuthState.guest));
    // store.dispatch(authActions.set.expiresAt(null));
    resolve(AuthState.guest);
  }, 10000);

  if (authState === AuthState.expired || (isExpired && expiresAtState)) {
    userManager.signinSilent();
  }

  const unsubscribe = store.subscribe(() => {
    const subscribeState = getAuthState(store.getState());
    const subscribeExpiresAtState = getExpiresAt(store.getState());
    const isActive = subscribeExpiresAtState ? subscribeExpiresAtState > Math.floor(Date.now() / 1000) : false;

    if (((subscribeState === AuthState.administrator || subscribeState === AuthState.moderator || subscribeState === AuthState.member ) &&
        isActive) || subscribeState === AuthState.guest) {
      clearTimeout(timeoutTimer);
      resolve(subscribeState);
    }
  });
});

export default getAuthStatus;
