import * as React from 'react';
import styled from 'styled-components';
import color from '../../../../utils/color';

const ItemContainer = styled.div`
  margin: 15px 0;
`;

const ItemHeader = styled.div`
  color: ${color.PRIMARY};
  font-size: 18px;
  padding-bottom: 10px;
  border-bottom: ${color.NEUTRAL} solid 1px;
  margin-bottom: 10px;
`;

const ItemData = styled.div`
  padding: 2px 0;
`;
const ItemContentWrapper = styled.div`
  padding-top: 5px;
  font-size: 12px;
`;

interface Item {
  reportTitle: string,
  reportName: string,
  publisherName: string,
  country: string,
  url?: string,
  releaseDate: string,
  datePeriod: string,
}

interface Props {
  item: Item,
}

const CatalogMenuHomeItem: React.FC<Props> = ({item}) => (
  <ItemContainer>
    <ItemHeader>{item.reportTitle}</ItemHeader>
    <ItemContentWrapper>
      <ItemData><b>Report Name: </b>{item.reportName}</ItemData>
      <ItemData><b>Publisher Name: </b>{item.publisherName}</ItemData>
      <ItemData><b>Country: </b>{item.country}</ItemData>
      {item.url ? <ItemData><b>URL: </b>{item.url}</ItemData> : null}
      <ItemData><b>Next Release Date: </b>{item.releaseDate}</ItemData>
      <ItemData><b>Date Period: </b>{item.datePeriod}</ItemData>
    </ItemContentWrapper>
  </ItemContainer>
);

export default CatalogMenuHomeItem;
