import { AxiosResponse } from 'axios';
import { UnitDto } from '../../../types/dto/UnitDto';
import { Units } from '../../../types/Units';

const mapUnitDtoToUnit = ({data}: AxiosResponse<UnitDto[]>): Units => {
  const result = {};
  data.forEach((unit: UnitDto) => {
    if (!result[unit.fromUnit.id]) {
      result[unit.fromUnit.id] = {};
    }
    result[unit.fromUnit.id][unit.toUnit.id] = {
      id: unit.toUnit.id,
      name: unit.toUnit.unit,
      conversionFactor: Number(unit.factor),
      conversionFunction: unit.function.id,
      conversionSeriesID: unit.series ? unit.series.id : null,
      scale: unit.toUnit.scale,
    };
  });
  return result;
};

export default mapUnitDtoToUnit;
