import * as React from 'react';
import styled from 'styled-components';
import color from 'src/utils/color';

const Input = styled.input`
  -webkit-appearance: none;
  height: 5px;
  background: ${color.BACKGROUND_SECONDARY};
  outline: none;
  border-radius: 6px;
  width: 100%;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 14px;
    height: 14px;
    background: ${color.DRP_INPUT};
    cursor: pointer;
    border-radius: 50%;
  }

  &::-moz-range-thumb {
    width: 14px;
    height: 14px;
    background: ${color.DRP_INPUT};
    cursor: pointer;
    border-radius: 50%;
  }
`;

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: number,
  min?: number,
  max?: number,
  step?: number,
}

// TODO: add background color behind slider (like in progress bar)
const Slider: React.FC<Props> = (props) => <Input type="range" {...props} />;

export default Slider;
