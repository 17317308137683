import produce from 'immer';
import { FuturesRecords } from '../../../types/FuturesRecord';
import { recordsActions, RecordsActions } from '../actions';
import { getType } from 'typesafe-actions';
import createUniqueArray from '../../../utils/createUniqueArray';

interface FuturesRecordsState {
  allProducts: number[],
  productsById: FuturesRecordProduct,
}

interface FuturesRecordProduct {
  [productID: string] : FuturesRecords,
}

const initialState: FuturesRecordsState = {
  allProducts: [],
  productsById: {},
};

export const futuresRecordReducer = produce<FuturesRecordsState, RecordsActions>((state, action) => {
  switch (action.type) {
    case getType(recordsActions.futures.setFutures): {
      const { payload: productID } = action;
      state.allProducts =
        createUniqueArray<number>([...state.allProducts, Number(productID)]);
      state.productsById = {[productID]: action.meta};
      return;
    }
  }
}, initialState);

export default futuresRecordReducer;
