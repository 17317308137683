const practiceCodeList = [
  // { name: 'Oct - Dec, 2018', id: 801 },
  // { name: 'Jan - Mar, 2019', id: 802 },
  // { name: 'Apr - Jun, 2019', id: 803 },
  { name: 'Jul - Sep, 2019', id: 804 },
  { name: 'Oct - Dec, 2019', id: 805 },
  { name: 'Jan - Mar, 2020', id: 806 },
  { name: 'Apr - Jun, 2020', id: 807 },
  // { name: 'Jul - Sep, 2020', id: 808 },
];

export default practiceCodeList;
