import * as React from 'react';
import styled from 'styled-components';
import color from '../../../../utils/color';
import { connect } from 'react-redux';
import { menuActions } from '../../../../ducks/menu/actions';
import { NavMenuDto } from '../../../../types/dto/NavMenuDto';
import { Menu } from '../../../../types/Menu';
import ReportItem from '../ReportItem/ReportItem';
import MinExpandIcon from 'src/icons/MinExpandIcon';
import MidExpandIcon from 'src/icons/MidExpandIcon';

const Text = styled.div`
  font-size: 20px;
  margin: 0 0 10px 5px;
  font-weight: 400;
  color: ${color.TEXT_PRIMARY};
`;

const Content = styled.div`
  width: 100%;
`;

const HeaderTextWrapper = styled.div`
  border-bottom: ${color.NEUTRAL} solid 2px;
  width: 100%;
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  padding: 0 10px;
  border-bottom: ${color.NEUTRAL} solid 2px;
`;

const SubmenuWrapper = styled.div`
  margin-top: 20px;
  cursor: pointer;
`;

const SubmenuBorderWrapper = styled.div`
  border-bottom: ${color.NEUTRAL} solid 1px;
`;

const SubmenuText = styled.div`
  font-size: 16px;
  margin: 15px 0 5px 0;
  font-weight: 600;
  color: ${color.PRIMARY};
`;

const ReportsWrapper = styled.div`
  margin-top: 30px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const CollapseIcon = styled(MinExpandIcon)<{ isExpanded: boolean }>`
  color: ${({ isExpanded }) => isExpanded ? color.NEUTRAL : color.PRIMARY};
  font-size: 16px;
  cursor: pointer;
  transform: translateY(5px);
  padding-right: 3px;
`;

const ExpandIcon = styled(MidExpandIcon)<{ isExpanded: boolean }>`
  color: ${({ isExpanded }) => isExpanded ? color.PRIMARY : color.NEUTRAL};
  cursor: pointer;
  font-size: 20px;
`;

interface OwnProps {
  data: NavMenuDto,
  reports: Menu[] | null,
}

type Props = OwnProps & typeof mapDispatchToProps;

interface State {
  isExpanded: boolean,
}

class MenuContent extends React.Component <Props, State> {
  public state = {
    isExpanded: false,
  };

  public render() {
    const { data, reports } = this.props;
    const { isExpanded } = this.state;
    return (
      <Content>
        <Header>
          <HeaderTextWrapper><Text>{data && data.text}</Text></HeaderTextWrapper>
          {reports &&
            <IconsWrapper>
              <CollapseIcon onClick={this.onMenuCollapseHandler} isExpanded={isExpanded}/>
              <ExpandIcon onClick={this.onMenuExpansionHandler} isExpanded={isExpanded}/>
            </IconsWrapper>
          }
        </Header>
        {reports && <ReportsWrapper>{reports.map((report) => <ReportItem key={report.id} item={report} isExpanded={isExpanded}/>)}</ReportsWrapper>}
        <SubmenuWrapper>
          {!reports && data && data.children.map((submenuItem) =>
            <SubmenuBorderWrapper key={submenuItem.id} onClick={this.onMenuSelectHandler(submenuItem)}>
              <SubmenuText>{submenuItem.text}</SubmenuText>
            </SubmenuBorderWrapper>)}
        </SubmenuWrapper>
      </Content>
    );
  }

  private onMenuSelectHandler = (submenuItem: NavMenuDto) => () => {
    this.props.setSelectedMenu(submenuItem);
    if (!submenuItem.children.length) {
      this.props.getMenu(submenuItem.id);
    }

    window.dispatchEvent(new CustomEvent(`expandSubmenu-${submenuItem.id}`));
  };

  private onMenuExpansionHandler = () => {this.setState({ isExpanded: true })};

  private onMenuCollapseHandler = () => {this.setState({ isExpanded: false })};
}

const mapDispatchToProps = {
  setSelectedMenu: menuActions.navMenu.setSelectedMenu,
  getMenu: menuActions.menu.get,
};

export default connect(null, mapDispatchToProps)(MenuContent);
