import * as React from 'react';
import { connect } from 'react-redux';
import { catalogActions } from 'src/ducks/catalog/actions';
import CatalogSelectModal from '../../../components/shared/Modal/CatalogSelectModal';
import { RootState } from '../../../ducks/store';
import {
  getCatalogFilterSortOrder, getPublisherIds, getCatalogFilterSearch, getFilteredCatalogPublishersList,
} from '../../../ducks/catalog/selectors';
import { SortOrder } from '../../../utils/enums';

interface OwnProps {
  onClose: () => void,
  isTranslated: boolean,
}

type Props = OwnProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

const PublishersCatalogContainer: React.FC<Props> = ({
  getPublishers, onClose, selectedList, addPublisher, removePublisher, removeAllPublisher, setSortOrder, sortOrder, setSearch, search, filteredList,
}) => {
  React.useEffect(() => {
    getPublishers();
  }, []);

  const onListItemClick = (id: number) => () => {
    if (selectedList.includes(id)) {
      removePublisher(id);
    } else {
      addPublisher(id);
    }
  };

  const onClearClick = () => {
    removeAllPublisher();
  };

  const onSortClick = () => {
    if (sortOrder === SortOrder.DESCENDING) {
      setSortOrder(SortOrder.ASCENDING)
    } else {
      setSortOrder(SortOrder.DESCENDING)
    }
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <CatalogSelectModal
      name={'Publishers'}
      onClose={onClose}
      onListItemClick={onListItemClick}
      list={filteredList}
      onClearSelectClick={onClearClick}
      sortOrder={sortOrder}
      onSortClick={onSortClick}
      search={search}
      onSearchChange={onSearchChange}
    />
  );
};

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  filteredList: getFilteredCatalogPublishersList(state, props),
  selectedList: getPublisherIds(state),
  sortOrder: getCatalogFilterSortOrder(state),
  search: getCatalogFilterSearch(state),
});

const mapDispatchToProps = {
  getPublishers: catalogActions.getPublishers,
  addPublisher: catalogActions.filter.addPublisher,
  removePublisher: catalogActions.filter.removePublisher,
  removeAllPublisher: catalogActions.filter.removeAllPublisher,
  setSortOrder: catalogActions.filterUtils.setSortOrder,
  setSearch: catalogActions.filterUtils.setSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(PublishersCatalogContainer);
