import { RootState } from '../store';
import { createSelector } from 'reselect';
import { Product } from '../../types/Product';
import { getProductIDFromProps, getFrequencyFromProps, getAreaIDFromProps } from '../fromPropsSelector';

export const getAllProducts = (state: RootState) => state.product.productById;
export const getAllProductIDs = (state: RootState) => state.product.allProducts;

export const getProduct = createSelector(
    getProductIDFromProps,
    getAllProducts,
    (productID, allProducts): Product | null => {
      if (!productID) {
        return null;
      }
      if (!allProducts[productID]) {
        return null;
      }
      return allProducts[productID];
    },
);

export const getProductFromProduct = createSelector(
    getProduct,
    (product) => product !== null ? product.product : null,
);

export const getReportFromProduct = createSelector(
    getProduct,
    (product) => product !== null ? product.report : null,
);

export const getPublisherFromProduct = createSelector(
    getProduct,
    (product) => product !== null ? product.publisher : null,
);

export const getSummaryFromProduct = createSelector(
    getProduct,
    (product) => product !== null ? product.summary : null,
);

export const getUnitFromProduct = createSelector(
    getProduct,
    (product) => product !== null ? product.unit : null,
);

export const getAreasFromProduct = createSelector(
    getProduct,
    (product) => product !== null ? product.areas : null,
);

export const getUpdatesFromProduct = createSelector(
    getProduct,
    (product) => product !== null ? product.updates : null,
);

export const getMetaFieldsFromProduct = createSelector(
  getProduct,
  (product) => product !== null ? product.metaFields : null,
);

export const getDefaultScaleFromProduct = createSelector(
  getProduct,
  (product) => product !== null ? product.defaultScale : null,
);

export const getDefaultAreaFromProduct = createSelector(
    getAreasFromProduct,
    (areas) => areas !== null ? areas.defaultArea : null,
);

export const getProductFirstReportPeriod = createSelector(
  getProduct,
  (product) => product !== null ? product.summary.firstReportPeriod : null,
);

export const getProductLastReportPeriod = createSelector(
  getProduct,
  (product) => product !== null ? product.summary.lastReportPeriod : null,
);

export const getFrequencyAreas = createSelector(
  getAreasFromProduct,
  getFrequencyFromProps,
    (areas, frequency) => {
      if (areas === null) {
        return [];
      }
      return areas[frequency];
    },
);

export const getAvailableProductFrequencies = createSelector(
  getAreasFromProduct,
  (areas) => {
    if (!areas) {
      return [];
    }
    return areas.availableFrequencies;
  },
);

export const getAreaInfo = createSelector(
  getAreasFromProduct,
  getFrequencyFromProps,
  getAreaIDFromProps,
  (areas, frequency, areaID) => {
    if (areas && frequency && areaID && areas.defaultArea.frequency as any !== 'Futures') {
      return areas[frequency].find((area) => area.id === areaID) || null;
    }
    return {name: 'Futures'};
  },
);
