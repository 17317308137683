import { all, call, put, takeLatest } from 'redux-saga/effects';
import { adminActions } from '../actions';
import { ActionType, getType } from 'typesafe-actions';
import { toast } from 'react-toastify';
import { authSaga } from '../../authentication/getAuthStatus';
import organizationAPI from '../../../services/organizationServices';
import { AxiosResponse } from 'axios';
import { OrganizationView } from '../../../types/OrganizationView';

export function* getOrganizationViews(action: ActionType<typeof adminActions.organizationView.get>) {
  try {
    const organizationViews: AxiosResponse<OrganizationView[]> = yield call(organizationAPI.fetchOrganizationViews, action.payload);
    yield put(adminActions.organizationView.addAll({ organizationName: action.payload, view: organizationViews.data }));
  } catch (error) {
    toast.error(error.message);
  }
}

export function* addOrganizationView(action: ActionType<typeof adminActions.organizationView.new>) {
  try {
    yield put(adminActions.organizationView.add({ organizationName: action.payload.organizationName, view: action.payload.view }));
    yield call(organizationAPI.addOrganizationView, action.payload.organizationName, action.payload.view.name);
  } catch (error) {
    yield put(adminActions.organizationView.remove({ organizationName: action.payload.organizationName, view: action.payload.view }));
    toast.error(error.message);
  }
}

export function* removeOrganizationView(action: ActionType<typeof adminActions.organizationView.delete>) {
  try {
    yield put(adminActions.organizationView.remove({ organizationName: action.payload.organizationName, view: action.payload.view }));
    yield call(organizationAPI.removeOrganizationView, action.payload.organizationName, action.payload.view.name);
  } catch (error) {
    yield put(adminActions.organizationView.add({ organizationName: action.payload.organizationName, view: action.payload.view }));
    toast.error(error.message);
  }
}

export function* addAllViewsToOrganization(action: ActionType<typeof adminActions.organizationView.addAllViews>) {
  yield call(organizationAPI.addAllViews, action.payload);
  yield put(adminActions.organizationView.saveAllViews(action.payload));
}

export function* requestViewAccess(action: ActionType<typeof adminActions.organizationView.requestViewAccess>) {
  try {
    yield call(organizationAPI.requestViewAccess, action.payload.organizationName, action.payload.view.name);
    yield toast.success('Access has been requested!');
  } catch (error) {
    toast.error(error.message);
  }
}

export function* organizationViewSagaWatcher() {
  yield all([
    takeLatest(getType(adminActions.organizationView.get), authSaga(getOrganizationViews)),
    takeLatest(getType(adminActions.organizationView.new), authSaga(addOrganizationView)),
    takeLatest(getType(adminActions.organizationView.delete), authSaga(removeOrganizationView)),
    takeLatest(getType(adminActions.organizationView.addAllViews), authSaga(addAllViewsToOrganization)),
    takeLatest(getType(adminActions.organizationView.requestViewAccess), authSaga(requestViewAccess)),
  ]);
}
