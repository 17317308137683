import { ChartDto } from '../../../types/dto/ChartDto';
import { Chart } from '../../../types/Chart';

const mapChartDtoToChart = (chartDto: ChartDto): Chart => ({
  ...chartDto,
  transformationsList: chartDto.transformations.map(value => value.id),
  normalizationsList: chartDto.normalizations.map(value => value.id),
  selectedTransformation: chartDto.transformations[0].id,
  selectedNormalization: chartDto.normalizations[0].id,
});

export default mapChartDtoToChart;
