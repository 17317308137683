import { all } from 'redux-saga/effects';
import menuSagaWatcher from './menu/sagas';
import recordsRootSaga from './records/sagas';
import productSagaWatcher from './products/sagas';
import unitSagaWatcher from './units/sagas';
import productPageSagaWatcher from './productPage/sagas';
import authSagaWatcher from './authentication/sagas';
import adminRootSaga from './admin/sagas';
import landingPageSagaWatcher from './landingPage/sagas';
import calendarsSagaWatcher from './admin/calendars/sagas';
import organizationUsersSagaWatcher from './admin/organizationUsers/sagas';
import contextTableSagaWatcher from './contextTable/sagas';
import catalogSagaWatcher from './catalog/sagas';

export default function* () {
  yield all([
    menuSagaWatcher(),
    productSagaWatcher(),
    recordsRootSaga(),
    unitSagaWatcher(),
    productPageSagaWatcher(),
    authSagaWatcher(),
    adminRootSaga(),
    landingPageSagaWatcher(),
    calendarsSagaWatcher(),
    organizationUsersSagaWatcher(),
    contextTableSagaWatcher(),
    catalogSagaWatcher(),
  ]);
}
