import produce from 'immer';
import { adminActions, AdminActions } from '../actions';
import { OrganizationReport } from 'src/types/OrganizationReport';
import { getType } from 'typesafe-actions';

interface OrganizationReportState {
  [org: string] : OrganizationReport[];
}

const initialState: OrganizationReportState = {};

export const organizationReportsReducer = produce<OrganizationReportState, AdminActions>(
  (state, action) => {
    switch (action.type) {
      case getType(adminActions.organizationReports.add):
        state[action.meta] = action.payload;
        return;
      case getType(adminActions.organizationReports.update):
        state[action.meta].push(action.payload);
        return;
      case getType(adminActions.organizationReports.removeOrganization):
        delete state[action.payload];
        return;
      case getType(adminActions.organizationReports.remove):
        const index = state[action.payload].findIndex(e => e.reportNameCode === action.meta);
        if (index !== -1) {
          state[action.payload].splice(index, 1);
        }
        return;
    }
  }, initialState,
);
