import { ActionType, createStandardAction } from 'typesafe-actions';
import { PeriodFrequency } from '../../types/PeriodFrequency';
import { Chart } from '../../types/Chart';
import { ChartConfigIDs } from './utils/charts.config';

export interface AddChartMeta {
  frequency: PeriodFrequency,
  productID: number,
}

export interface ChangeChartTransformationData extends ChangeChartData {
  selectedTransformationID: number,
}

export interface ChangeChartNormalizationData extends ChangeChartData {
  selectedNormalizationID: number,
}

interface ChangeChartData {
  productID: number,
  frequency: PeriodFrequency,
  chartID: ChartConfigIDs,
}

export const chartActions = {
  add: createStandardAction('@chart/ADD')<Chart[], AddChartMeta>(),
  changeTransformation: createStandardAction('@charts/CHANGE_TRANSFORMATION')<ChangeChartTransformationData>(),
  changeNormalization: createStandardAction('@charts/CHANGE_NORMALIZATION')<ChangeChartNormalizationData>(),
};

export type ChartActions = ActionType<typeof chartActions>;
