import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { adminActions } from '../actions';
import { OrganizationDto, OrganizationsDto } from '../../../types/dto/OrganizationsDto';
import { toast } from 'react-toastify';
import { adminPageActions } from '../../adminPage/actions';
import { authSaga } from '../../authentication/getAuthStatus';
import organizationAPI from '../../../services/organizationServices';
import { AxiosResponse } from 'axios';

export function* getOrganizations() {
  try {
    const organizations: AxiosResponse<OrganizationsDto> = yield call(organizationAPI.fetchOrganizations);
    yield put(adminActions.organization.addAll(organizations.data));
    if (organizations.data.length > 0) {
      yield put(adminPageActions.setOrganization(organizations.data[0].name))
    }
  } catch (error) {
    toast.error(error.message, {
      position: 'top-center',
    });
  }
}

export function* deleteOrganization(action: ActionType<typeof adminActions.organization.delete>) {
  try {
    yield call(organizationAPI.deleteOrganization, action.payload);
    yield put(adminActions.organization.remove(action.payload));
    yield put(adminActions.organizationReports.removeOrganization(action.payload));
    yield put(adminActions.organizationView.removeOrganization(action.payload));
    yield put(adminActions.calendars.removeOrganization(action.payload));
    yield put(adminActions.organizationUsers.removeOrganization(action.payload));
    yield put(adminActions.calendarReports.removeOrganization(action.payload));
    yield toast.success('Delete successful');
  } catch (error) {
    toast.error(error.message);
  }
}

export function* createOrganization(action: ActionType<typeof adminActions.organization.new>) {
  try {
    const createdOrganization: AxiosResponse<OrganizationDto> = yield call(organizationAPI.createOrganization, action.payload);
    yield put(adminActions.organization.add(createdOrganization.data));
    yield action.meta();
    yield toast.success('Organization successfully created');
  } catch (error) {
    if (error.response && error.response.status === 409 ||
        error.response && error.response.status === 400) {
      action.meta(error.response.data.Name[0]);
    } else {
      toast.error(error.message);
    }
  }
}

export function* updateOrganizationName(action: ActionType<typeof adminActions.organization.edit>) {
  try {
    yield call(organizationAPI.updateOrganization, action.payload, action.meta.name);
    yield put(adminActions.organization.update(action.payload, action.meta.name));
    yield action.meta.cb();
  } catch (e) {
    if (e.response && e.response.status === 409 ||
        e.response && e.response.status === 400) {
      action.meta.cb(e.response.data.Name[0]);
    } else {
      console.error(e.response);
    }
  }
}

export function* organizationSagaWatcher() {
  yield all([
    takeLatest(getType(adminActions.organization.get), authSaga(getOrganizations)),
    takeLatest(getType(adminActions.organization.new), authSaga(createOrganization)),
    takeLatest(getType(adminActions.organization.edit), authSaga(updateOrganizationName)),
    takeLatest(getType(adminActions.organization.delete), authSaga(deleteOrganization)),
  ]);
}

export default organizationSagaWatcher;
