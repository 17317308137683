import * as React from 'react';
import styled from 'styled-components';
import color from '../../../../utils/color';
import SortIcon from 'src/icons/UnfoldMore';
import Checkbox from '../../../shared/Checkbox/Checkbox';

const SortByHeader = styled.div`
  font-size: 20px;
  padding-bottom: 9px;
  border-bottom: ${color.NEUTRAL} solid 2px;
  margin-bottom: 15px;
  margin-top: 25px;
`;

const SortIconWrapper = styled(SortIcon)`
  color: ${color.NEUTRAL};
  transform: translateY(2px);
  font-size: 28px;
  margin-right: 10px;
`;

const CheckboxWrapper = styled.div`
  padding: 3px 0;
`;

const SortByFilter: React.FC = () => (
  <>
    <SortByHeader><SortIconWrapper/>Sort By</SortByHeader>
    <CheckboxWrapper><Checkbox selected={false}/>Original Names</CheckboxWrapper>
    <CheckboxWrapper><Checkbox selected={true}/>Translated Names</CheckboxWrapper>
  </>
);

export default SortByFilter;
