import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { authActions } from './actions';
import userManager from './userManager';
import { setAccessToken } from '../../utils/api';
import { AuthState } from './reducers';

export const getUser = async () => await userManager.getUser();

export function signInRequest() {
  userManager.signinRedirect();
}

export function signOutRequest() {
  userManager.signoutRedirect();
}

export function tokenExpired() {
  userManager.signinSilent();
}

export function* updateToken() {
  const user = yield call(getUser);
  if (user) {
    yield setAccessToken(user.token_type, user.access_token);
    yield put(authActions.get.authState());
    yield put(authActions.set.expiresAt(user.expires_at));
  }
}

export function* getAuthState() {
  const user = yield call(getUser);
  if (user) {
    if (user.profile.role && (typeof user.profile.role === 'string' && user.profile.role === 'Administrator') ||
      (Array.isArray(user.profile.role) && user.profile.role.findIndex((role: string) => role === 'Administrator') !== -1)) {
      yield put(authActions.set.authState(AuthState.administrator));
    } else if (user.profile.role &&  (typeof user.profile.role === 'string' && user.profile.role === 'Moderator') ||
      (Array.isArray(user.profile.role) && user.profile.role.findIndex((role: string) => role === 'Moderator') !== -1))  {
      yield put(authActions.set.authState(AuthState.moderator));
    } else {
      yield put(authActions.set.authState(AuthState.member));
    }
  } else {
    authActions.set.authState(AuthState.guest)
  }
}

function* authSagaWatcher() {
  yield all([
    takeLatest(getType(authActions.signIn), signInRequest),
    takeLatest(getType(authActions.signOut), signOutRequest),
    takeLatest(getType(authActions.token.expired), tokenExpired),
    takeLatest(getType(authActions.token.update), updateToken),
    takeLatest(getType(authActions.get.authState), getAuthState),
  ]);
}

export default authSagaWatcher;
