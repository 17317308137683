import { PeriodFrequency } from '../../../../types/PeriodFrequency';
import { format } from 'date-fns';

export const getTimeIndexFromTimestamp = (timestamp: number): number =>
  Number(String(timestamp).slice(4));

export const getYearFromTimestamp = (timestamp: number): number =>
  Number(String(timestamp).slice(0, 4));

export const getFrequencyTimestamp = (date: Date, frequency: PeriodFrequency): number => {
  switch (frequency) {
    case 'Daily':
      // TODO change daily timestamp to start from 0
      return Number(format(date, 'YYYYDDDD'));
    case 'Weekly': {
      // TODO change weekly timestamp to start from 00
      const dayNumber = Number(format(date, 'DDD'));
      let week = 0;
      if (dayNumber === 366) {
        week = Math.floor(1 / 7 + 1);
      } else {
        week = Math.floor(dayNumber / 7 + 1);
      }
      return Number(format(date, 'YYYY') + String(week).padStart(2, '0'));
    }
    case 'Monthly':
      return Number(format(date, `YYYYMM`)) - 1;
    case 'Quarterly':
      return Number(format(date, 'YYYYQ')) - 1;
    case 'Annual':
      return Number(format(date, 'YYYY'));
    default: return 0;
  }
};
