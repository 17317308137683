import produce from 'immer';
import { adminActions, AdminActions } from '../actions';
import { getType } from 'typesafe-actions';
import { CalendarReport } from '../../../types/CalendarReport';

interface CalendarReportState {
  [organization: string] : {
    [calendar: string] : CalendarReport[];
  };
}

const initialState: CalendarReportState = {};

export const calendarReportsReducer = produce<CalendarReportState, AdminActions>(
  (state, action) => {
    switch (action.type) {
      case getType(adminActions.calendarReports.addAll):
        if (!state[action.payload.organization]) {
          state[action.payload.organization] = {};
        }
        state[action.payload.organization][action.payload.calendarName] = action.meta;
        return;
      case getType(adminActions.calendarReports.remove):
        const deleteIndex = state[action.payload.organization][action.payload.calendarName].findIndex(({name}) => name === action.meta);
        if (deleteIndex === -1) {
          return;
        }
        state[action.payload.organization][action.payload.calendarName].splice(deleteIndex, 1);
        return;
      case getType(adminActions.calendarReports.add):
        state[action.payload.organization][action.payload.calendarName].push({ name: action.meta });
        return;
      case getType(adminActions.calendarReports.removeCalendar):
        delete state[action.payload][action.meta];
        return;
      case getType(adminActions.calendarReports.removeOrganization):
        delete state[action.payload];
        return;
    }
  }, initialState,
);
