import * as React from 'react';
import styled from 'styled-components';
import artboard from '../../media/images/artboard.png';
import macbook from '../../media/images/macbook.png';
import Button from 'src/components/shared/Button/Button';
import color from '../../utils/color';
import MailIcon from 'src/icons/MailIcon';

const Hero = styled.div`
  background-image: url(${artboard}); // linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),

  /* Set a specific height */
  height: calc(100vh - 100px);

  width: 100%;
  margin: 0 auto;
  padding-top: 100px;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
`;

const Section = styled.div`
  position: relative;
  width: 50%;
`;

const Content = styled.div`
  position: absolute;
  top: 40%;
  left: 30%;
  transform: translateY(-50%);
  color: ${color.BACKGROUND};

  h1 {
    font-size: 50px;
    font-weight: bolder;
    font-family: 'Open Sans', sans-serif;
  }
`;

const ImageSection = styled.div`
  position: relative;
  width: 50%;

  div {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
  }
`;

const Image = styled.img`
  width: 70%;
`;

const ButtonLink = styled.a`
  font-weight: lighter;
  text-decoration: none;
  color: ${color.BACKGROUND};
`;

const Mail = styled(MailIcon)`
  transform: translateY(3px);
`;

const LandingPageScene: React.FC = () => (
  <Hero>
    <Section>
      <Content>
        <h1>
          Making Data Fun. <br />
          In a Serious Way.
        </h1>
        <br />
        <Button color={color.SECONDARY} width={160} fontSize={18} height={34} filled>
          <ButtonLink href="mailto:mbozic@umn.edu">
            Contact us <Mail />
          </ButtonLink>
        </Button>
      </Content>
    </Section>
    <ImageSection>
      <div>
        <Image src={macbook} alt="demo showcase"/>
      </div>
    </ImageSection>
  </Hero>
);

export default LandingPageScene;
