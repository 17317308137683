import { CatalogPublishersDto } from '../../../types/dto/CatalogPublishersDto';
import { AxiosResponse } from 'axios';
import { Publisher } from '../../../types/Publisher';

const mapPublishersDtoToPublishers = ({ data }: AxiosResponse<CatalogPublishersDto>): Publisher[] => data.value
  .filter(({ Active }) => Active)
  .map(({ PublisherID, PublisherName, PublisherNameShort, PublisherNameTranslated, PublisherUrl }) =>
    ({ id: PublisherID, name: PublisherName, nameShort: PublisherNameShort, nameTranslated: PublisherNameTranslated, url: PublisherUrl }));

export default mapPublishersDtoToPublishers;
