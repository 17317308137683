import * as React from 'react';
import styled from 'styled-components';
import UserIcon from 'src/icons/UserIcon';
import color from '../../../utils/color';
import { authActions } from '../../../ducks/authentication/actions';
import { RouteComponentProps, withRouter } from 'react-router';
import UsersEditIcon from 'src/icons/UsersEditIcon';
import LogoutIcon from 'src/icons/LogoutIcon';
import { AuthState } from '../../../ducks/authentication/reducers';

const HeaderWrapper = styled.div`
  position: relative;
`;

const DropDownWrapper = styled.div`
  width: 160px;
  position: absolute;
  left: -70px;
  top: 15px;
  margin-top: 20px;
  background-color: ${color.BACKGROUND};
  box-shadow: 0 2px 3px 1px ${color.NEUTRAL};
`;

const DropDownItem = styled.div<{textColor?: string}>`
  height: 40px;
  width: calc(100% - 10px);
  font-size: 14px;
  color: ${({ textColor }) => textColor ? textColor : color.TEXT_PRIMARY};
  line-height: 40px;
  padding-left: 10px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: ${color.BACKGROUND_SECONDARY};
  }
`;

const UserIconWrapper = styled(UserIcon)`
  color: ${color.ICONS_HEADER}
  cursor: pointer;
  transform: translateY(7px);
`;

const LogoutIconWrapper = styled(LogoutIcon)`
  transform: translateY(3px);
  padding-right: 10px;
  font-size: 14px;
`;

const AdminPanelIcon = styled(UsersEditIcon)`
  transform: translateY(2px);
  padding-right: 10px;
  font-size: 16px;
`;

interface Props {
  signOut: typeof authActions.signOut,
  auth: AuthState,
}

interface State {
  isOpened: boolean,
}

class NavMenuHeader extends React.Component<Props & RouteComponentProps, State> {
  public state = {
    isOpened: false,
  };

  public render () {
    const { isOpened } = this.state;

    return (
      <HeaderWrapper onMouseLeave={this.onMouseLeaveHandler}>
        <UserIconWrapper onClick={this.iconClickHandler}/>
        {isOpened &&
          <DropDownWrapper>
            { this.props.auth === AuthState.administrator || this.props.auth === AuthState.moderator ?
              <DropDownItem onClick={this.adminPanelClickHandler}><AdminPanelIcon />Admin Panel</DropDownItem> : null }
            <DropDownItem onClick={this.logoutClickHandler} textColor={color.NEGATIVE}><LogoutIconWrapper />Logout</DropDownItem>
          </DropDownWrapper>
        }
      </HeaderWrapper>
    );
  }
  private onMouseLeaveHandler = () => {this.setState({ isOpened: false })};

  private adminPanelClickHandler = () => {
    this.props.history.push('/admin');
    this.setState({ isOpened: false });
  };

  private logoutClickHandler = () => {
    this.props.signOut();
    this.setState({ isOpened: false });
  };

  private iconClickHandler = () => {this.setState({ isOpened: !this.state.isOpened })};
}

export default withRouter(NavMenuHeader);
