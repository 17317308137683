import * as React from 'react';
import styled from 'styled-components';
import ContentLoaderRectangle from '../shared/ContentLoaders/ContentLoaderRectangle';
import color from '../../utils/color';
import numberRange from '../../utils/numberRange';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 50px 50px 50px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 73%;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  width: 22%;
`;

const MenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  border-bottom: ${color.NEUTRAL} solid 2px;
`;

const Header = styled.div`
  margin-bottom: 30px;
  border-bottom: ${color.NEUTRAL} solid 2px;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const OrganizationSelectContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
  border-bottom: ${color.NEUTRAL} solid 2px;
`;

const TableName = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-bottom: ${color.NEUTRAL} solid 2px;
`;

const Row = styled.div`
  margin: 5px 0;
`;

const HeaderText = styled.div`
  margin: 0 0 0 5px;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px 5px 5px;
  border-bottom: ${color.NEUTRAL} solid 2px;
`;

const AdminPanelSkeleton: React.FC = () => (
  <Root>
    <OrganizationSelectContainer>
      <ContentLoaderRectangle height={30} width={'50%'}/>
    </OrganizationSelectContainer>
    <Content>
      <Menu>
        <MenuHeader>
          <ContentLoaderRectangle height={39} width={'35%'}/>
          <HeaderText><ContentLoaderRectangle height={39} width={'50%'}/></HeaderText>
        </MenuHeader>
        {numberRange(0, 4).map((key: number) =>
          <MenuItem key={key}>
            <ContentLoaderRectangle height={40} width={'25%'}/>
            <ContentLoaderRectangle height={40} width={'65%'}/>
          </MenuItem>)
        }
      </Menu>
      <List>
        <Header>
          <HeaderWrapper>
            <ContentLoaderRectangle height={40} width={'50%'}/>
            <ContentLoaderRectangle height={40} width={'30%'}/>
          </HeaderWrapper>
        </Header>
        <div>
          <TableName>
            <ContentLoaderRectangle height={30} width={'50%'}/>
          </TableName>
        </div>
        <div>
          {numberRange(1, 11).map((key: number) => <Row key={key}><ContentLoaderRectangle width={'100%'} height={40}/></Row>)}
        </div>
        <Footer>
          <ContentLoaderRectangle width={'25%'} height={40}/>
          <ContentLoaderRectangle width={'40%'} height={40}/>
        </Footer>
      </List>
    </Content>
  </Root>
);

export default AdminPanelSkeleton;
