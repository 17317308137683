import * as React from 'react';
import styled from 'styled-components';
import { Menu } from '../../../../types/Menu';
import color from '../../../../utils/color';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import formatValue from '../../../../utils/formatValue';
import LongArrowDown from 'src/icons/LongArrowDown';
import LongArrowUp from 'src/icons/LongArrowUp';

const Root = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  margin-bottom: ${({ isExpanded }) => isExpanded ? '40px' : '25px'};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Header = styled.div`
  border-bottom: 1px solid ${color.NEUTRAL};
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${color.PRIMARY};
  margin-bottom: 3px;
`;

const Content = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  width: 100%;
`;

const Label = styled.div`
  font-weight: 600;
  display: inline;
`;

const Item = styled.div`
  padding: 5px;
  font-size: 13px;
`;

const YearOnYearValue = styled.span<{ isNegative: boolean }>`
  color: ${({ isNegative }) => isNegative ? color.NEGATIVE : color.POSITIVE};
`;

const ArrowDown = styled(LongArrowDown)`
  transform: translateY(2px);
`;

const ArrowUp = styled(LongArrowUp)`
  transform: translateY(2px);
`;

interface Props {
  item: Menu,
  isExpanded: boolean,
}

const ReportItem: React.FC<Props> = ({ item, isExpanded }) => {
  const { name, nameTranslated, report, unit, id, scale } = item;
  const { description } = unit;
  return (
    <Root isExpanded={isExpanded}>
      <StyledLink to={`/product/${id}`}><Header><HeaderText>{nameTranslated}</HeaderText></Header></StyledLink>
      { isExpanded &&
        <Content>
          <div>
            {name && <Item><Label>Series Name: </Label>{name}</Item>}
            {description && <Item><Label>Units: </Label>{description}</Item>}
            {report && report.areaFrequencys && <Item><Label>Area: </Label>{report.areaFrequencys[0].area.name}</Item>}
          </div>
          <div>
            {report && report.lastReportPeriod
            && <Item><Label>Last Report Period: </Label>{format(new Date(report.lastReportPeriod), 'MMMM YYYY')}</Item>}
            {report && report.lastReportValue && <Item><Label>Latest Report Value: </Label>{formatValue(scale)(report.lastReportValue)}</Item>}
            {report && report.yoYChangePercentage &&
            <Item>
              <Label>Year-On-Year: </Label>
              <YearOnYearValue isNegative={report.yoYChangePercentage < 0}>
                {report.yoYChangePercentage.toFixed(2)}%
                {report.yoYChangePercentage < 0 ? <ArrowDown /> : <ArrowUp />}
              </YearOnYearValue>
            </Item>}
          </div>
        </Content>
      }
    </Root>
  );
};

export default ReportItem;
