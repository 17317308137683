import { AxiosResponse } from 'axios';
import { CatalogArea } from '../../../types/CatalogArea';
import { CatalogAreasDto } from 'src/types/dto/CatalogAreasDto';

const mapCatalogAreasDtoToCatalogAreas = ({ data }: AxiosResponse<CatalogAreasDto>): CatalogArea[] => data.value
  .filter(({ Active }) => Active)
  .map(({ AreaID, AreaName, AreaNameCode, AreaNameTranslated }) =>
    ({ id: AreaID, name: AreaName, nameCode: AreaNameCode, nameTranslated: AreaNameTranslated }));

export default mapCatalogAreasDtoToCatalogAreas;
