import { all, call, put, takeLatest, delay } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { adminActions } from '../actions';
import { toast } from 'react-toastify';
import { authSaga } from '../../authentication/getAuthStatus';
import UdmDB from 'src/utils/db';
import directAPI from '../../../services/directServices';
import { AxiosResponse } from 'axios';
import { ViewDto } from '../../../types/dto/ViewDto';
import { mapViewDtoToView } from './utils/mapViewDtoToView';

let getViewsFirstError = true;
let cachedData = false;

export function* getAllViews() {
  try {
    const cache = yield call(UdmDB.getAllViews);
    if (cache.length !== 0) {
      cachedData = true;
    }
    yield put(adminActions.view.add(cache));

    const viewsDto: AxiosResponse<ViewDto[]> = yield call(directAPI.fetchAllViews);
    const views = yield call(mapViewDtoToView, viewsDto.data);
    yield call(UdmDB.clearAllViews);
    yield call(UdmDB.addAllViews, views);
    getViewsFirstError = true;
    yield put(adminActions.view.add(views));
  } catch (error) {
    const timeoutError = error.message.split(' ')[0] === 'timeout';
    if  (timeoutError && getViewsFirstError) {
      getViewsFirstError = false;
      yield delay(40000);
      yield put(adminActions.view.get())
    } else if (!cachedData || !timeoutError) {
      toast.error(error.message, {
        position: 'top-center',
      });
    }
  }
}

export function* viewSagaWatcher() {
  yield all([
    takeLatest(getType(adminActions.view.get), authSaga(getAllViews)),
  ]);
}

export default viewSagaWatcher;
