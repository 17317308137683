import { AxiosResponse } from 'axios';
import { FrequencyRecordDto } from '../../../../types/dto/FrequencyRecordDto';
import { FrequencyRecordRaw } from '../../../../types/FrequencyRecord';

const mapFrequencyRecordsDtoToFrequencyRecords = ({data}: AxiosResponse<FrequencyRecordDto>): FrequencyRecordRaw[] =>
  data.value.map(({AreaID, Value, ReportPeriod, CollectionDate, WeekEnding}) =>
    ({ areaID: AreaID, value: Number(Value), reportPeriod: new Date(ReportPeriod), collectionDate: new Date(CollectionDate),
      weekEnding: WeekEnding ? new Date(WeekEnding) : undefined }));

export default mapFrequencyRecordsDtoToFrequencyRecords;
