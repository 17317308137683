import produce from 'immer';
import { menuActions, MenuActions } from './actions';
import { getType } from 'typesafe-actions';
import { NavMenuDto } from '../../types/dto/NavMenuDto';
import { Menu } from '../../types/Menu';
import createUniqueArray from '../../utils/createUniqueArray';

export interface MenuState {
  navMenu: NavMenuDto[],
  selectedMenu?: NavMenuDto,
  menuById: {
    [id: number] : Menu[],
  },
  menuIds: number[],
}

export const initialState: MenuState = {
  navMenu: [],
  menuById: {},
  menuIds: [],
};

export const menuReducer = produce<MenuState, MenuActions>(
  (state, action) => {
    switch (action.type) {
      case getType(menuActions.navMenu.add):
        state.navMenu = action.payload;
        return;
      case getType(menuActions.menu.add):
        state.menuIds = createUniqueArray<number>([...state.menuIds, action.meta]);
        state.menuById[action.meta] = action.payload;
        return;
      case getType(menuActions.navMenu.setSelectedMenu):
        state.selectedMenu = action.payload;
        return;
    }
  }, initialState,
);
