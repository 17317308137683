import * as React from 'react';
import BrowseDataItem from './BrowseDataItem';
import styled from 'styled-components';
import color from '../../../../utils/color';
import SearchIcon from '../../../../icons/SearchIcon';
import { connect } from 'react-redux';
import { NavMenuDto } from '../../../../types/dto/NavMenuDto';
import { menuActions } from '../../../../ducks/menu/actions';

const BrowseDataHeader = styled.div`
  font-size: 20px;
  padding-bottom: 9px;
  border-bottom: ${color.NEUTRAL} solid 2px;
  margin-bottom: 15px;
`;

const SearchIconWrapper = styled(SearchIcon)`
  color: ${color.NEUTRAL};
  transform: translateY(2px);
  margin-right: 10px;
`;

interface OwnProps {
  data: NavMenuDto[],
  selectedMenuID: number,
}

type Props = OwnProps & typeof mapDispatchToProps;

class BrowseData extends React.Component<Props> {
  public render() {
    const { data , setSelectedMenu, selectedMenuID, getMenu } = this.props;
    return (
      <>
        <BrowseDataHeader><SearchIconWrapper/> Browse Data</BrowseDataHeader>
        {data.map((item: NavMenuDto) =>
          <BrowseDataItem item={item} key={item.id} selectedMenuID={selectedMenuID} setSelectedMenu={setSelectedMenu} getMenu={getMenu}/>)}
      </>
    )
  }
}

const mapDispatchToProps = {
  setSelectedMenu: menuActions.navMenu.setSelectedMenu,
  getMenu: menuActions.menu.get,
};

export default connect(null, mapDispatchToProps)(BrowseData);
