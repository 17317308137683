const stateCodeList = [
  { name: 'Alabama', id: 1 },
  { name: 'Alaska', id: 2 },
  { name: 'Arizona', id: 4 },
  { name: 'Arkansas', id: 5 },
  { name: 'California', id: 6 },
  { name: 'Colorado', id: 8 },
  { name: 'Connecticut', id: 9 },
  { name: 'Delaware', id: 10 },
  { name: 'Florida', id: 12 },
  { name: 'Georgia', id: 13 },
  { name: 'Hawaii', id: 15 },
  { name: 'Idaho', id: 16 },
  { name: 'Illinois', id: 17 },
  { name: 'Indiana', id: 18 },
  { name: 'Iowa', id: 19 },
  { name: 'Kansas', id: 20 },
  { name: 'Kentucky', id: 21 },
  { name: 'Louisiana', id: 22 },
  { name: 'Maine', id: 23 },
  { name: 'Maryland', id: 24 },
  { name: 'Massachusetts', id: 25 },
  { name: 'Michigan', id: 26 },
  { name: 'Minnesota', id: 27 },
  { name: 'Mississippi', id: 28 },
  { name: 'Missouri', id: 29 },
  { name: 'Montana', id: 30 },
  { name: 'Nebraska', id: 31 },
  { name: 'Nevada', id: 32 },
  { name: 'New Hampshire', id: 33 },
  { name: 'New Jersey', id: 34 },
  { name: 'New Mexico', id: 35 },
  { name: 'New York', id: 36 },
  { name: 'North Carolina', id: 37 },
  { name: 'North Dakota', id: 38 },
  { name: 'Ohio', id: 39 },
  { name: 'Oklahoma', id: 40 },
  { name: 'Oregon', id: 41 },
  { name: 'Pennsylvania', id: 42 },
  { name: 'Rhode Island', id: 44 },
  { name: 'South Carolina', id: 45 },
  { name: 'South Dakota', id: 46 },
  { name: 'Tennessee', id: 47 },
  { name: 'Texas', id: 48 },
  { name: 'Utah', id: 49 },
  { name: 'Vermont', id: 50 },
  { name: 'Virginia', id: 51 },
  { name: 'Washington', id: 53 },
  { name: 'West Virginia', id: 54 },
  { name: 'Wisconsin', id: 55 },
  { name: 'Wyoming', id: 56 },
];

export default stateCodeList;
