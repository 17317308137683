import * as React from 'react';
import pallet from '../../../utils/color';
import styled from 'styled-components';
import { FaAngleDown as AngleDownIcon, FaAngleUp as AngleUpIcon } from 'react-icons/fa';

const HeaderContainer = styled.div<{ color: string }>`
  display: flex;
  justify-content: space-between;
  background-color: ${({ color }) => color};
  color: ${pallet.BACKGROUND};
  padding: 5px;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px;
`;

interface Props {
  visible?: boolean
  color?: string,
  onClick?: () => void,
  title: string,
}

const Accordion: React.FC<Props> = ({ color = pallet.PRIMARY, visible = false, children, title, onClick }) =>
  (<>
    <HeaderContainer color={color} onClick={onClick}>
      {title}
      {visible ? <AngleUpIcon /> : <AngleDownIcon />}
    </HeaderContainer>
    {visible && children}
  </>);

export default Accordion;
