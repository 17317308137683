import { RootState } from '../store';
import { createSelector } from 'reselect';
import { getIsTranslatedFromProps } from '../fromPropsSelector';
import containsString from '../../utils/containsString';
import { SortOrder } from '../../utils/enums';
import sortObjectStringsByKey from '../../utils/sortObjectStringsByKey';

export const getCatalogSort = (state: RootState) => state.catalog.sort;
export const getCatalogSortOrder = (state: RootState) => state.catalog.sortOrder;
export const getCatalogFilterSortOrder = (state: RootState) => state.catalog.filterUtils.sortOrder;
export const getCatalogFilterSearch = (state: RootState) => state.catalog.filterUtils.search;

// Publisher
export const getPublisherIds = (state: RootState) => state.catalog.filterItems.publisherId;
export const getAllCatalogPublishers = (state: RootState) => state.catalog.publishers;
export const getCatalogPublishersList = createSelector(
  getAllCatalogPublishers,
  getPublisherIds,
  getIsTranslatedFromProps,
  (publishers, ids, isTranslated) =>
    publishers.map(({name, nameTranslated, id}) => ({ name: isTranslated ? nameTranslated : name, id, selected: ids.includes(id) })),
);
export const getFilteredCatalogPublishersList = createSelector(
  getCatalogPublishersList,
  getCatalogFilterSearch,
  getCatalogFilterSortOrder,
  (list, search, sort) => {
    const newList = list.filter(({ name }) => containsString(name, search)).sort(sortObjectStringsByKey('name'));
    if (sort === SortOrder.ASCENDING) {
      newList.reverse();
    }
    return newList;
  },
);

// Report
export const getReportCode = (state: RootState) => state.catalog.filterItems.reportCode;
export const getAllCatalogReports = (state: RootState) => state.catalog.reports;
export const getCatalogReportsList = createSelector(
  getAllCatalogReports,
  getReportCode,
  getIsTranslatedFromProps,
  (reports, reportCodes, isTranslated) =>
    reports.map(({name, nameTranslated, id, publisher}) =>
      ({ name: isTranslated ? nameTranslated : name, id, selected: reportCodes.includes(id), publisher })),
);

export const getFilteredCatalogReportsList = createSelector(
  getCatalogReportsList,
  getCatalogFilterSearch,
  getCatalogFilterSortOrder,
  getPublisherIds,
  (list, search, sort, publisherIds) => {
    const newList = list
      // .filter(({ publisher }) => publisherIds.length > 0 ? publisherIds.includes(publisher) : true )
      .filter(({ name }) => containsString(name, search)).sort(sortObjectStringsByKey('name'));
    if (sort === SortOrder.ASCENDING) {
      newList.reverse();
    }
    return newList;
  },
);

// Area
export const getAreaId = (state: RootState) => state.catalog.filterItems.areaId;
export const getAllCatalogAreas = (state: RootState) => state.catalog.areas;
export const getCatalogAreasList = createSelector(
  getAllCatalogAreas,
  getAreaId,
  getIsTranslatedFromProps,
  (areas, areaId, isTranslated) =>
    areas.map(({name, nameTranslated, id}) => ({ name: isTranslated ? nameTranslated : name, id, selected: areaId.includes(id) })),
);

export const getFilteredCatalogAreasList = createSelector(
  getCatalogAreasList,
  getCatalogFilterSearch,
  getCatalogFilterSortOrder,
  (list, search, sort) => {
    const newList = list.filter(({ name }) => containsString(name, search)).sort(sortObjectStringsByKey('name'));
    if (sort === SortOrder.ASCENDING) {
      newList.reverse();
    }
    return newList;
  },
);

// Category
export const getCategoryId = (state: RootState) => state.catalog.filterItems.categoryId;
export const getAllCatalogCategories = (state: RootState) => state.catalog.categories;
export const getCatalogCategoryList = createSelector(
  getAllCatalogCategories,
  getCategoryId,
  getIsTranslatedFromProps,
  (categories, categoryId, isTranslated) =>
    categories.map(({name, id}) => ({ name, id, selected: categoryId.includes(id) })),
);

export const getFilteredCatalogCategoryList = createSelector(
  getCatalogCategoryList,
  getCatalogFilterSearch,
  getCatalogFilterSortOrder,
  (list, search, sort) => {
    const newList = list.filter(({ name }) => containsString(name, search)).sort(sortObjectStringsByKey('name'));
    if (sort === SortOrder.ASCENDING) {
      newList.reverse();
    }
    return newList;
  },
);

// Category
export const getMeasureId = (state: RootState) => state.catalog.filterItems.measureId;
export const getAllCatalogMeasures = (state: RootState) => state.catalog.measure;
export const getCatalogMeasuresList = createSelector(
  getAllCatalogMeasures,
  getMeasureId,
  getIsTranslatedFromProps,
  (measure, measureId, isTranslated) =>
    measure.map(({name, id}) => ({ name, id, selected: measureId.includes(id) })),
);

export const getFilteredCatalogMeasuresList = createSelector(
  getCatalogMeasuresList,
  getCatalogFilterSearch,
  getCatalogFilterSortOrder,
  (list, search, sort) => {
    const newList = list.filter(({ name }) => containsString(name, search)).sort(sortObjectStringsByKey('name'));
    if (sort === SortOrder.ASCENDING) {
      newList.reverse();
    }
    return newList;
  },
);

// Country
export const getCountryId = (state: RootState) => state.catalog.filterItems.countryId;
export const getAllCatalogCountries = (state: RootState) => state.catalog.countries;
export const getCatalogCountriesList = createSelector(
  getAllCatalogCountries,
  getCountryId,
  getIsTranslatedFromProps,
  (countries, countryId, isTranslated) =>
    countries.map(({name, id}) => ({ name, id, selected: countryId.includes(id) })),
);

export const getFilteredCatalogCountriesList = createSelector(
  getCatalogCountriesList,
  getCatalogFilterSearch,
  getCatalogFilterSortOrder,
  (list, search, sort) => {
    const newList = list.filter(({ name }) => containsString(name, search)).sort(sortObjectStringsByKey('name'));
    if (sort === SortOrder.ASCENDING) {
      newList.reverse();
    }
    return newList;
  },
);
