import Dexie from 'dexie';
import { View } from '../types/View';
import { Report } from '../types/Report';

class UdmDB extends Dexie {
  private views: Dexie.Table<View, string>;
  private reports: Dexie.Table<Report, string>;

  constructor() {
    super('UdmDB');
    this.version(0.1).stores({
      views: 'name',
      reports: 'name',
    });

    this.views = this.table('views');
    this.reports = this.table('reports');
  }

  // Views CRUD
  public getAllViews = async () => await this.views.toArray();

  public clearAllViews = async () => await this.views.clear();

  public addAllViews = async (views: View[]) => await this.views.bulkPut(views);

  // Reports CRUD
  public getAllReports = async () => await this.reports.toArray();

  public clearAllReports = async () => await this.reports.clear();

  public addAllReports = async (reports: Report[]) => await this.reports.bulkPut(reports);
}

export default new UdmDB();
