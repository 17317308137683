import * as React from 'react';
import styled from 'styled-components';
import color from '../../../utils/color';
import * as ReactDOM from 'react-dom';
import CloseIcon from 'src/icons/CloseIcon';

interface RootProps {
  isOpen: boolean,
  background: string,
}

const Root = styled.div<RootProps>`
  display: ${({isOpen}) => isOpen ? 'block' : 'none'};
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  padding-top: 100px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.22);
`;

const ModalContainer = styled.div`
  position: relative;
  z-index: 100;
  display: block;
  width: 100%;
  max-width: 1000px;
  height: calc(100% - 200px);
  margin: auto;
`;

interface ModalContentProps {
  width: number
}

const ModalContent = styled.div<ModalContentProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: ${({width}) => width}px;
  max-width: 1000px;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(-50% + 50px));
  padding: 0;
  background-color: ${color.BACKGROUND};
  box-shadow: 0 2px 3px 1px ${color.NEUTRAL};
  z-index: 10;
`;

const CloseIconPlaceholder = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${color.NEUTRAL};
`;

interface Props {
  isOpen: boolean,
  onClose: () => void,
  closeButton: boolean;
  width?: number,
  backgroundColor?: string,
}

class Modal extends React.Component<Props> {
  private refNode: React.ReactNode;

  public componentDidMount () {
    document.addEventListener('mousedown', this.outsideClickHandler);
  }

  public componentWillUnmount () {
    document.removeEventListener('mousedown', this.outsideClickHandler);
  }

  public render () {
    const { isOpen, backgroundColor = 'none', width = 300, children, onClose, closeButton = false } = this.props;
    return (
      <Root isOpen={isOpen} background={backgroundColor} >
        <ModalContainer>
          <ModalContent ref={this.setRef} width={width}>
            {closeButton ? <CloseIconPlaceholder><CloseIcon size={20} onClick={onClose}/></CloseIconPlaceholder> : null}
            {children}
          </ModalContent>
        </ModalContainer>
      </Root>
    );
  }

  private setRef = (element: React.ReactNode) => {
    this.refNode = element;
  };

  private outsideClickHandler: any = (event: React.MouseEvent) => {
    if (this.props.isOpen) {
      const controlsNode = ReactDOM.findDOMNode(this.refNode as any);
      if (controlsNode === null) {
        throw new Error('node is missing');
      }
      if (event.target instanceof Node && controlsNode.contains(event.target)) {
        return;
      }
      this.props.onClose();
    }
  };
}

export default Modal;
