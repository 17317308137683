import { AxiosResponse } from 'axios';
import { FuturesRecordDto } from '../../../../types/dto/FuturesRecordDto';
import { FuturesRaw } from '../../../../types/FuturesRecord';

const mapFuturesRecordsDtoToFuturesRecords = ({data}: AxiosResponse<FuturesRecordDto>): FuturesRaw[] =>
  data.value.map(({AreaID, Volume, LowPrice, OpenPrice, HighPrice, ClosePrice, ContractMonth, ReportPeriod, OpenInterest}) =>
    ({
        areaID: AreaID,
        volume: Volume,
        lowPrice: Number(LowPrice),
        openPrice: Number(OpenPrice),
        highPrice: Number(HighPrice),
        closePrice: Number(ClosePrice),
        contractMonth: new Date(ContractMonth),
        reportPeriod: new Date(ReportPeriod),
        openInterest: OpenInterest,
      }
    ));

export default mapFuturesRecordsDtoToFuturesRecords;
