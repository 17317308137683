import { SortOrder } from '../../utils/enums';
import produce from 'immer';
import { catalogActions, CatalogActions } from './actions';
import { getType } from 'typesafe-actions';
import { Publisher } from '../../types/Publisher';
import { CatalogReport } from '../../types/CatalogReport';
import { CatalogArea } from '../../types/CatalogArea';
import { CatalogCategory } from '../../types/CatalogCategory';
import { CatalogCountry } from '../../types/CatalogCountry';

export interface CatalogState {
  sort: string,
  sortOrder: SortOrder,
  filterItems: {
    publisherId: number[],
    reportCode: string[],
    areaId: number[],
    categoryId: number[],
    measureId: number[],
    countryId: number[],
  },
  filterUtils: {
    sortOrder: SortOrder,
    search: string,
  },
  publishers: Publisher[],
  reports: CatalogReport[],
  areas: CatalogArea[];
  categories: CatalogCategory[];
  measure: CatalogCategory[];
  countries: CatalogCountry[];
}

export const initialState: CatalogState = {
  sort: 'All',
  sortOrder: SortOrder.DESCENDING,
  filterItems: {
    publisherId: [],
    reportCode: [],
    areaId: [],
    categoryId: [],
    measureId: [],
    countryId: [],
  },
  filterUtils: {
    sortOrder: SortOrder.DESCENDING,
    search: '',
  },
  publishers: [],
  reports: [],
  areas: [],
  categories: [],
  measure: [],
  countries: [],
};

export const catalogReducer = produce<CatalogState, CatalogActions>((state, action) => {
  switch (action.type) {
    case getType(catalogActions.setSort):
      state.sort = action.payload;
      return;
    case getType(catalogActions.setSortOrder):
      state.sortOrder = action.payload;
      return;
    case getType(catalogActions.filterUtils.setSortOrder):
      state.filterUtils.sortOrder = action.payload;
      return;
    case getType(catalogActions.filterUtils.setSearch):
      state.filterUtils.search = action.payload;
      return;
    case getType(catalogActions.filterUtils.reset):
      state.filterUtils.search = '';
      state.filterUtils.sortOrder = SortOrder.DESCENDING;
      return;
    case getType(catalogActions.filter.clearAll):
      state.filterItems = { publisherId: [], reportCode: [], areaId: [], categoryId: [], measureId: [], countryId: [] };
      return;
    // Publishers
    case getType(catalogActions.setPublishers):
      state.publishers = action.payload;
      return;
    case getType(catalogActions.filter.addPublisher):
      state.filterItems.publisherId.push(action.payload);
      return;
    case getType(catalogActions.filter.addAllPublisher):
      state.filterItems.publisherId = state.publishers.map(({ id }) => id);
      return;
    case getType(catalogActions.filter.removePublisher):
      state.filterItems.publisherId.splice(state.filterItems.publisherId.findIndex((id) => id === action.payload), 1);
      return;
    case getType(catalogActions.filter.removeAllPublisher):
      state.filterItems.publisherId = [];
      return;
    // Reports
    case getType(catalogActions.setReports):
      state.reports = action.payload;
      return;
    case getType(catalogActions.filter.addReport):
      state.filterItems.reportCode.push(action.payload);
      return;
    case getType(catalogActions.filter.addAllReports):
      state.filterItems.reportCode = state.reports.map(({ id }) => id);
      return;
    case getType(catalogActions.filter.removeReport):
      state.filterItems.reportCode.splice(state.filterItems.reportCode.findIndex((id) => id === action.payload), 1);
      return;
    case getType(catalogActions.filter.removeAllReports):
      state.filterItems.reportCode = [];
      return;
    // Areas
    case getType(catalogActions.setAreas):
      state.areas = action.payload;
      return;
    case getType(catalogActions.filter.addArea):
      state.filterItems.areaId.push(action.payload);
      return;
    case getType(catalogActions.filter.addAllAreas):
      state.filterItems.areaId = state.areas.map(({ id }) => id);
      return;
    case getType(catalogActions.filter.removeArea):
      state.filterItems.areaId.splice(state.filterItems.areaId.findIndex((id) => id === action.payload), 1);
      return;
    case getType(catalogActions.filter.removeAllAreas):
      state.filterItems.areaId = [];
      return;
    // Categories
    case getType(catalogActions.setCategories):
      state.categories = action.payload;
      return;
    case getType(catalogActions.filter.addCategory):
      state.filterItems.categoryId.push(action.payload);
      return;
    case getType(catalogActions.filter.addAllCategories):
      state.filterItems.categoryId = state.categories.map(({ id }) => id);
      return;
    case getType(catalogActions.filter.removeCategory):
      state.filterItems.categoryId.splice(state.filterItems.categoryId.findIndex((id) => id === action.payload), 1);
      return;
    case getType(catalogActions.filter.removeAllCategories):
      state.filterItems.categoryId = [];
      return;
    // Measures
    case getType(catalogActions.setMeasures):
      state.measure = action.payload;
      return;
    case getType(catalogActions.filter.addMeasure):
      state.filterItems.measureId.push(action.payload);
      return;
    case getType(catalogActions.filter.addAllMeasures):
      state.filterItems.measureId = state.measure.map(({ id }) => id);
      return;
    case getType(catalogActions.filter.removeMeasure):
      state.filterItems.measureId.splice(state.filterItems.measureId.findIndex((id) => id === action.payload), 1);
      return;
    case getType(catalogActions.filter.removeAllMeasures):
      state.filterItems.measureId = [];
      return;
    // Counties
    case getType(catalogActions.setCounties):
      state.countries = action.payload;
      return;
    case getType(catalogActions.filter.addCountry):
      state.filterItems.countryId.push(action.payload);
      return;
    case getType(catalogActions.filter.addAllCounties):
      state.filterItems.countryId = state.countries.map(({ id }) => id);
      return;
    case getType(catalogActions.filter.removeCountry):
      state.filterItems.countryId.splice(state.filterItems.countryId.findIndex((id) => id === action.payload), 1);
      return;
    case getType(catalogActions.filter.removeAllCounties):
      state.filterItems.countryId = [];
      return;
  }
}, initialState);
