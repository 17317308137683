import { RootState } from '../store';
import { ProductUnit, UnitsState } from '../../types/Units';
import { createSelector } from 'reselect';
import { getSelectedUnitIDFromProps, getUnitIDFromProps } from '../fromPropsSelector';
import { getUnitFromProduct } from '../products/selectors';

export const getAllUnits = (state: RootState): UnitsState => state.units;

export const getUnitByID = createSelector(
  getAllUnits,
  getUnitIDFromProps,
  (units, selectedUnit) => units[selectedUnit] || null,
);

export const getAllUnitIDs = createSelector(
  getUnitByID,
  (units) => {
    if (!units) {
      return [];
    }
    return units.allIDs;
  },
);

export const getSelectedUnit = createSelector(
  getUnitByID,
  getSelectedUnitIDFromProps,
  (units, selected) => units !== null ? units[selected] || null : null,
);

export const getTranslatedFlag = (state: RootState): boolean => state.productPage.translated;

export const getAllProductUnits = createSelector(
  getUnitFromProduct,
  getUnitByID,
  (defaultProductUnit, unitsByID): ProductUnit[] => {
    if (!defaultProductUnit) {
      return [];
    }

    if (!unitsByID) {
      return [{ id: defaultProductUnit.id, name: defaultProductUnit.name }];
    }
    const units: ProductUnit[] = [];
    unitsByID.allIDs.forEach((unitID) => {
      if (unitID !== defaultProductUnit.id) {
        units.push({
          id: unitsByID[unitID].id,
          name: unitsByID[unitID].name,
          scale: unitsByID[unitID].scale,
        });
      }
    });

    const defaultUnit = { id: defaultProductUnit.id, name: defaultProductUnit.name };
    units.push(defaultUnit);
    return units;
  },
);
