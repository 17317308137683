import * as React from 'react';
import styled from 'styled-components';
import color from '../../../utils/color';

const TooltipContent = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => isVisible ? 'block' : 'none'};
  position: absolute;
  left: 30px;
  bottom: 35px;
  z-index: 9000;
  padding: 10px;
  color: ${color.NEUTRAL_DARK};
  font-weight: 400;
  background-color: ${color.BACKGROUND_SECONDARY};
  box-shadow: 2px 2px 2px ${color.NEUTRAL};
  border-radius: 10px;
`;

const SelectedArrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  z-index: 9999;
  bottom: -15px;
  left: calc(50% - 10px);
  transform: translateY(-55%);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid ${color.BACKGROUND_SECONDARY};
  filter: drop-shadow(2px 3px 1px ${color.NEUTRAL});
`;

interface Props {
  isVisible: boolean
}

const Tooltip: React.FC<Props> = ({ isVisible, children }) => (
  <TooltipContent isVisible={isVisible}>
    {children}
    <SelectedArrow />
  </TooltipContent>
);

export default Tooltip;
