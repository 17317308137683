import { all, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { getProductPageAreaID, getProductPageFrequency, getProductPageProductID, getUnitID } from './selectors';
import { getAllUnitIDs, getUnitByID } from '../units/selectors';
import { PeriodFrequency } from '../../types/PeriodFrequency';
import { recordsActions } from '../records/actions';
import { unitsActions } from '../units/actions';
import { getFrequencyRecord, getAllFrequencyRecords } from '../records/frequency/selectors';
import { productPageActions } from './actions';
import { getFrequencyTimestamp } from '../records/frequency/utils/timeFunctions';
import { UnitState } from '../../types/Units';

export function* fetchConversionSeriesProductForUnitConversion() {
  const unitID: number = yield select(getUnitID);
  const frequency: PeriodFrequency = yield select(getProductPageFrequency);
  if (unitID && frequency && frequency !== 'Futures' as any) {
    const units: UnitState = yield select(getUnitByID, { unitID });
    if (units) {
      const allUnits: number[] = yield select(getAllUnitIDs, { unitID });
      const allRecords = yield select(getAllFrequencyRecords);
      yield all(allUnits.map(unit => {
        const conversionSeriesID = units[unit].conversionSeriesID;
        if (conversionSeriesID) {
          if (allRecords[conversionSeriesID]) {
            if (!allRecords[conversionSeriesID][frequency]) {
              return put(recordsActions.frequency.initializeUnitsForFrequency(String(units[unit].conversionSeriesID), frequency));
            }
            return null;
          }
          return put(recordsActions.frequency.initializeUnitsForFrequency(String(units[unit].conversionSeriesID), frequency));
        }
        return null;
      }).filter((value) => value !== null));
    }
  }
}

export function* fetchFrequencyData(action: ActionType<typeof productPageActions.setDisplayDateRange>) {
  const productID: number = yield select(getProductPageProductID);
  const frequency: PeriodFrequency = yield select(getProductPageFrequency);
  const currentDate = action.payload.from;
  const twoMonthsOlderDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2);
  const selectedFromDate: number = getFrequencyTimestamp(twoMonthsOlderDate, frequency);
  const selectedToDate: number = getFrequencyTimestamp(action.payload.to, frequency);
  const areaID: number = yield select(getProductPageAreaID);
  const frequencyData = yield select(getFrequencyRecord, {productID, frequency});
  const oldestFrequencyDataDate: number = frequencyData[areaID].allRecords[0];
  const newestFrequencyDataDate: number = frequencyData[areaID].allRecords[frequencyData[areaID].allRecords.length - 1];
  if (oldestFrequencyDataDate > selectedFromDate || newestFrequencyDataDate < selectedToDate) {
    yield put(recordsActions.frequency.fetchFrequency(String(productID), {frequency, areaID}));
  }
}

function* productPageSagaWatcher() {
  yield all([
    takeLatest(getType(unitsActions.update), fetchConversionSeriesProductForUnitConversion),
    takeLatest(getType(productPageActions.setDisplayDateRange), fetchFrequencyData),
  ]);
}

export default productPageSagaWatcher;
