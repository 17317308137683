import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { adminActions } from '../actions';
import { toast } from 'react-toastify';
import { CalendarReportDto } from '../../../types/dto/CalendarReportDto';
import { authSaga } from '../../authentication/getAuthStatus';
import organizationAPI from '../../../services/organizationServices';
import { AxiosResponse } from 'axios';

function* getCalendarReports(action: ActionType<typeof adminActions.calendarReports.get>) {
  try {
    const calendarReports: AxiosResponse<CalendarReportDto[]> =
      yield call(organizationAPI.getCalendarReports, action.payload.organization, action.payload.calendarName);
    yield put(adminActions.calendarReports.addAll(action.payload, calendarReports.data));
  } catch (error) {
    toast.error(error.message, {
      position: 'top-center',
    });
  }
}

function* createCalendarReport(action: ActionType<typeof adminActions.calendarReports.new>) {
  try {
    yield call(organizationAPI.addCalendarReport, action.payload.organization, action.payload.calendarName, action.meta);
    yield put(adminActions.calendarReports.add(action.payload, action.meta));
  } catch (error) {
    toast.error(error.message);
  }
}

function* deleteCalendarReport(action: ActionType<typeof adminActions.calendarReports.delete>) {
  try {
    yield call(organizationAPI.deleteCalendarReport, action.payload.organization, action.payload.calendarName, action.meta);
    yield put(adminActions.calendarReports.remove(action.payload, action.meta));
  } catch (error) {
    toast.error(error.message);
  }
}

function* calendarReportsSagaWatcher() {
  yield all([
    takeLatest(getType(adminActions.calendarReports.get), authSaga(getCalendarReports)),
    takeLatest(getType(adminActions.calendarReports.new), authSaga(createCalendarReport)),
    takeLatest(getType(adminActions.calendarReports.delete), authSaga(deleteCalendarReport)),
  ]);
}

export default calendarReportsSagaWatcher;
