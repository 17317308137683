import { all, call, put, takeLatest } from 'redux-saga/effects';
import { adminActions } from '../actions';
import { ActionType, getType } from 'typesafe-actions';
import { CalendarDto } from 'src/types/dto/CalendarDto';
import { toast } from 'react-toastify';
import organizationAPI from '../../../services/organizationServices';
import { AxiosResponse } from 'axios';

function* getCalendars(action: ActionType<typeof adminActions.calendars.get>) {
  try {
    const calendars: AxiosResponse<CalendarDto[]> = yield call(organizationAPI.fetchCalendars, action.payload);
    yield put(adminActions.calendars.addAll(action.payload, calendars.data));
  } catch (error) {
    toast.error(error.message, {
      position: 'top-center',
    });
  }
}

function* createCalendar(action: ActionType<typeof adminActions.calendars.new>) {
  try {
    const createdCalendar: AxiosResponse<CalendarDto>
      = yield call(organizationAPI.createCalendar, action.payload.organizationName, action.payload.name);
    yield put(adminActions.calendars.add(action.payload.organizationName, createdCalendar.data));
    yield action.meta();
    yield toast.success('Calendar successful created');
  } catch (error) {
    if (error.response && error.response.status === 409 ||
      error.response && error.response.status === 400) {
      action.meta(error.response.data.Name[0]);
    } else {
      toast.error(error.message);
    }
  }
}

function* deleteCalendar(action: ActionType<typeof adminActions.calendars.delete>) {
  try {
    yield call(organizationAPI.deleteCalendar, action.payload, action.meta);
    yield put(adminActions.calendars.remove(action.payload, action.meta));
    yield put(adminActions.calendarReports.removeCalendar(action.payload, action.meta));
    yield toast.success('Delete successful');
  } catch (error) {
    toast.error(error.message);
  }
}

function* updateCalendar(action: ActionType<typeof adminActions.calendars.edit>) {
  try {
    yield call(organizationAPI.updateCalendar, action.payload.organizationName, action.payload.name, action.meta.name);
    yield put(adminActions.calendars.update(action.payload, action.meta.name));
    yield action.meta.cb();
  } catch (error) {
    if (error.response && error.response.status === 409 ||
      error.response && error.response.status === 400) {
      if (error.response.data.Name) {
        action.meta.cb(error.response.data.Name[0]);
      } else if (error.response.data.calendarName) {
        action.meta.cb(error.response.data.calendarName[0]);
      }
    } else {
      toast.error(error.message);
    }
  }
}

function* calendarsSagaWatcher() {
  yield all([
    takeLatest(getType(adminActions.calendars.get), getCalendars),
    takeLatest(getType(adminActions.calendars.new), createCalendar),
    takeLatest(getType(adminActions.calendars.delete), deleteCalendar),
    takeLatest(getType(adminActions.calendars.edit), updateCalendar),
  ]);
}

export default calendarsSagaWatcher;
