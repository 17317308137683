import produce from 'immer';
import { adminActions, AdminActions } from '../actions';
import { getType } from 'typesafe-actions';
import { Organization } from '../../../types/Organization';

const initialState: Organization[] = [];

export const organizationReducer = produce<Organization[], AdminActions>(
  (state, action) => {
    switch (action.type) {
      case getType(adminActions.organization.addAll):
        return action.payload;
      case getType(adminActions.organization.add):
        state.push(action.payload);
        return;
      case getType(adminActions.organization.remove):
        const deleteIndex = state.findIndex(({name}) => name === action.payload);
        if (deleteIndex === -1) {
          return;
        }
        state.splice(deleteIndex, 1);
        return;
      case getType(adminActions.organization.update):
        const index = state.findIndex(({name}) => name === action.meta);
        if (index === -1) {
          return;
        }
        state[index] = action.payload;
        return;
      default:
        return state;
    }
  }, initialState,
);
