import * as React from 'react';
import styled from 'styled-components';
import color from '../../../utils/color';
import SearchIcon from '../../../icons/SearchIcon';
import AngleRightIcon from 'src/icons/AngleRightIcon';
import { Link as RouterLink } from 'react-router-dom';

const Root = styled.div`
  width: 185px;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
`;

const Header = styled.div`
  padding-bottom: 0;
  border-bottom: ${color.NEUTRAL} solid 2px;
  margin-bottom: 10px;

  h2 {
    font-size: 25px;
    margin: 0 0 10px 5px;
    font-weight: 400;
    color: ${color.TEXT_PRIMARY};
  }

  svg {
    font-size: 20px;
    color: ${color.NEUTRAL};
    transform: translateY(2px);
  }
`;

const Item = styled.div<{isSelected: boolean}>`
  display: flex;
  position: relative;
  font-size: 14px;
  font-weight: ${({ isSelected }) =>  isSelected ? 600 : 400};
  cursor: pointer;
  padding: 10px 0;

  svg {
    color: ${color.PRIMARY};
    margin-right: 10px;
    transform: translateY(3px);
  }
`;

const Link = styled(RouterLink)`
  text-decoration: none;
  color: ${color.TEXT_PRIMARY}
`;

interface Props {
  selected: string,
  onChange: (name: string) => void;
}

const catalogList = [{name: 'Publishers'}, {name: 'Reports'}, {name: 'Series'}];

const CatalogMenu: React.FC<Props> = ({ selected, onChange }) => {
  const onMenuClick = (name: string) => () => { if (selected !== name) { onChange(name) }};
  return (
    <div>
      <Root>
        <Header><h2><SearchIcon /> Browse Data</h2></Header>
        {catalogList.map(({name}) => (
          <Item isSelected={name === selected} key={name} onClick={onMenuClick(name)}><AngleRightIcon />
            <Link to={{ state: undefined }}>{name}</Link>
          </Item>
        ))}
      </Root>
    </div>
  );
};

export default CatalogMenu;
