import styled from 'styled-components';
import color from '../../../../utils/color';

const FilterContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: ${color.NEUTRAL} solid 2px;
`;

export default FilterContentHeader;
