import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { recordsActions } from '../actions';
import { getProductPageProductID } from '../../productPage/selectors';
import createFuturesRecord from './utils/createFuturesRecord';
import dataAPI from '../../../services/dataServices';
import mapFuturesRecordsDtoToFuturesRecords from './utils/mapFuturesRecordsDtoToFuturesRecords';
import { AxiosResponse } from 'axios';
import { FuturesRecordDto } from '../../../types/dto/FuturesRecordDto';

function* fetchFuturesRecord() {
  const productID = yield select(getProductPageProductID);
  const date = new Date(Date.now());
  const from = new Date(date.getFullYear(), date.getMonth()).toISOString().slice(0, 10);
  const to = new Date(date.getFullYear() + 2, date.getMonth()).toISOString().slice(0, 10);
  const futuresRecordsDto: AxiosResponse<FuturesRecordDto> = yield call(dataAPI.fetchFuturesRecord, productID, from, to);
  const futuresRecords = yield mapFuturesRecordsDtoToFuturesRecords(futuresRecordsDto);
  const records = yield call(createFuturesRecord, futuresRecords);
  yield put(recordsActions.futures.setFutures(productID, records));
}

function* futuresRecordWatcher() {
  yield all([
    takeEvery(getType(recordsActions.futures.initializeFutures), fetchFuturesRecord),
  ]);
}

export default futuresRecordWatcher;
