import { FrequencyRecord, FrequencyRecordRaw } from '../../../../types/FrequencyRecord';
import { PeriodFrequency } from '../../../../types/PeriodFrequency';
import { getFrequencyTimestamp, getTimeIndexFromTimestamp, getYearFromTimestamp } from './timeFunctions';

const createFrequencyRecord = (records: FrequencyRecordRaw[], frequency: PeriodFrequency): FrequencyRecord => {
  const result: FrequencyRecord = { allAreas: [] };
  records.forEach((record: FrequencyRecordRaw) => {
    if (!result[record.areaID]) {
      result[record.areaID] = { allRecords: [] };
    }
    const reportPeriodTimestamp = frequency === 'Weekly' && record.weekEnding
      ? getFrequencyTimestamp(record.weekEnding, frequency)
      : getFrequencyTimestamp(record.reportPeriod, frequency);

    const recordValues = {
      value: record.value,
      index: getTimeIndexFromTimestamp(reportPeriodTimestamp),
      year: getYearFromTimestamp(reportPeriodTimestamp),
      date: record.reportPeriod,
      timestamp: reportPeriodTimestamp,
    };

    if (!result[record.areaID][reportPeriodTimestamp]) {
      result[record.areaID][reportPeriodTimestamp] = {
        ...recordValues,
        collectionDate: record.collectionDate,
      }
    }
    else {
      if (result[record.areaID][reportPeriodTimestamp].collectionDate < record.collectionDate) {
        result[record.areaID][reportPeriodTimestamp] = {
          ...recordValues,
          collectionDate: record.collectionDate,
        }
      }
    }
  });
  result.allAreas = Object.keys(result).filter(value => value !== 'allAreas').map(Number);
  result.allAreas.forEach((area: number) => {
    result[area].allRecords = Object.keys(result[area]).filter(value => value !== 'allRecords').map(Number);
  });
  return result;
};

export default createFrequencyRecord;
