import * as React from 'react';
import styled from 'styled-components';
import BrowseData from '../../components/Menu/MenuComponents/BrowseDataItems/BrowseData';
import { RouteComponentProps } from 'react-router';
import MenuContent from '../../components/Menu/MenuComponents/MenuContent/MenuContent';
import { connect } from 'react-redux';
import { RootState } from '../../ducks/store';
import { getMenuReports, getSelectedNavMenuContent, getSelectedSubmenu } from '../../ducks/menu/selectors';

const Root = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const FiltersContainer = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
`;

const MenuContentContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
`;

type OwnProps = RouteComponentProps<{id: string}>;

type Props =  OwnProps & ReturnType<typeof mapStateToProps>;

class MenuScene extends React.Component<Props> {
  public render () {
    const { browseDataItems, menuContentItems, menuReports } = this.props;

    return (
      <Root>
        <FiltersContainer>
          <BrowseData data={browseDataItems} selectedMenuID={menuContentItems ? menuContentItems.id : Number(this.props.match.params.id)}/>
        </FiltersContainer>
        <MenuContentContainer>
          <MenuContent data={menuContentItems} reports={menuReports}/>
        </MenuContentContainer>
      </Root>
    )
  }
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
    browseDataItems: getSelectedNavMenuContent(state, props),
    menuContentItems: getSelectedSubmenu(state, props),
    menuReports: getMenuReports(state, props),
  }
);

export default connect(mapStateToProps)(MenuScene);
