import produce from 'immer';
import { adminActions, AdminActions } from '../actions';
import { getType } from 'typesafe-actions';
import { Calendar } from '../../../types/Calendar';

interface CalendarState {
  [organization: string] : Calendar[],
}

const initialState: CalendarState = {};

export const calendarsReducer = produce<CalendarState, AdminActions>(
  (state, action) => {
    switch (action.type) {
      case getType(adminActions.calendars.addAll):
        state[action.payload] = action.meta;
        return;
      case getType(adminActions.calendars.remove):
        const deleteIndex = state[action.payload].findIndex(({name}) => name === action.meta);
        if (deleteIndex === -1) {
          return;
        }
        state[action.payload].splice(deleteIndex, 1);
        return;
      case getType(adminActions.calendars.add):
        state[action.payload].push(action.meta);
        return;
      case getType(adminActions.calendars.update):
        const updateIndex = state[action.payload.organizationName].findIndex(({name}) => name === action.payload.name);
        if (updateIndex === -1) {
          return;
        }
        state[action.payload.organizationName][updateIndex].name = action.meta;
        return;
      case getType(adminActions.calendars.removeOrganization):
        delete state[action.payload];
        return;
    }
  }, initialState,
);
