import { ActionType, createStandardAction } from 'typesafe-actions';
import { SeriesContext } from '../../types/SeriesContext';
import { TableContext } from '../../types/TableContext';
import { PeriodFrequency } from '../../types/PeriodFrequency';

export const contextTableActions = {
  series: {
    get: createStandardAction('@contextTable_series/GET')<string, PeriodFrequency>(),
    add: createStandardAction('@contextTable_series/ADD')<SeriesContext[], string>(),
  },
  table: {
    get: createStandardAction('@contextTable_table/GET')<string>(),
    add: createStandardAction('@contextTable_table/ADD')<TableContext[], number>(),
  },
};

export type ContextTableActions = ActionType<typeof contextTableActions>;
