import * as React from 'react';
import styled from 'styled-components';
import color from '../../utils/color';
import SortDownIcon from 'src/icons/SortDownIcon';
import { connect } from 'react-redux';
import { catalogActions } from '../../ducks/catalog/actions';
import { RootState } from '../../ducks/store';
import { getCatalogSort } from '../../ducks/catalog/selectors';

const Root = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${color.PRIMARY};
  border-bottom: ${color.NEUTRAL} solid 2px;
  font-size: 14px;
  padding-bottom: 10px;

  svg {
    transform: translateY(2px);
  }
`;

const SortItem = styled.div<{ selected: boolean }>`
  cursor: pointer;
  font-weight: ${({ selected }) => selected ? 'bold' : 'lighter'};
`;

interface OwnProps {
  sortable?: boolean;
}

type Props = OwnProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const CatalogSortContainer: React.FC<Props> = ({ sortable = false, setSort, sort }) => {
  const setCatalogSort = (by: string) => () => {
    if (sort !== by) { setSort(by); }
  };

  return (
    <Root>
      {/*<SortItem onClick={setCatalogSort('Recent')} selected={sort === 'Recent'}>Recent {sortable && <SortDownIcon />}</SortItem>*/}
      <SortItem onClick={setCatalogSort('All')} selected={sort === 'All'}>All {sortable && <SortDownIcon />}</SortItem>
      {letters.map((letter) => (<SortItem key={letter} onClick={setCatalogSort(letter)} selected={sort === letter}>{letter}</SortItem>))}
    </Root>
  );
};

const mapStateToProps = (state: RootState) => ({
  sort: getCatalogSort(state),
});

const mapDispatchToProps = {
  setSort: catalogActions.setSort,
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogSortContainer);
