import { ActionType, createStandardAction } from 'typesafe-actions';
import { Units } from '../../types/Units';

export const unitsActions = {
  initialize: createStandardAction('@unit/INITIALIZE_UNITS')(),
  add: createStandardAction('@unit/ADD')<Units>(),
  update: createStandardAction('@unit/UPDATE')(),
};

export type UnitsActions = ActionType<typeof unitsActions>;
