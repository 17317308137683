import { ActionType, createStandardAction } from 'typesafe-actions';
import { AuthState } from './reducers';

export const authActions = {
  signIn: createStandardAction('@auth/SIGN_IN_REQUEST')(),
  signOut: createStandardAction('@auth/SIGN_OUT_REQUEST')(),
  token: {
    expired: createStandardAction('@auth_token/EXPIRED')(),
    expiring: createStandardAction('@auth_token/EXPIRING')(),
    update: createStandardAction('@auth_token/UPDATED')(),
  },
  get: {
    authState: createStandardAction('@auth_get/AUTHENTICATION_STATE')(),
  },
  set: {
    authState: createStandardAction('@auth_set/AUTHENTICATION_STATE')<AuthState>(),
    expiresAt: createStandardAction('@auth_set/EXPIRES_AT')<number | null>(),
  },
};

export type AuthActions = ActionType<typeof authActions>;
