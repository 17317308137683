import { all, takeLatest, call, put } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { catalogActions } from './actions';
import dataAPI from '../../services/dataServices';
import { AxiosResponse } from 'axios';
import { CatalogPublishersDto } from '../../types/dto/CatalogPublishersDto';
import mapPublishersDtoToPublishers from './utils/mapPublishersDtoToPublishers';
import mapCatalogReportsDtoToCatalogReports from './utils/mapCatalogReportsDtoToCatalogReports';
import { CatalogReportsDto } from '../../types/dto/CatalogReportsDto';
import mapCatalogAreasDtoToCatalogAreas from './utils/mapCatalogAreasDtoToCatalogAreas';
import { CatalogAreasDto } from '../../types/dto/CatalogAreasDto';
import { CatalogCategoriesDto } from '../../types/dto/CatalogCategoriesDto';
import mapCatalogCategoriesDtoToCatalogCategory from './utils/mapCatalogCategoriesDtoToCatalogCategory';
import { CatalogMeasuresDto } from '../../types/dto/CatalogMeasuresDto';
import mapCatalogMeasureDtoToCatalogMeasure from './utils/mapCatalogMeasureDtoToCatalogMeasure';
import { CatalogCountryDto } from '../../types/dto/CatalogCountryDto';
import mapCatalogCountryDtoToCatalogCountry from './utils/mapCatalogCountryDtoToCatalogCountry';

function* fetchPublishers() {
  try {
    const publishersDto: AxiosResponse<CatalogPublishersDto> = yield call(dataAPI.getPublishers);
    const publishers = mapPublishersDtoToPublishers(publishersDto);
    yield put(catalogActions.setPublishers(publishers));
  } catch (e) {
    console.log(e);
  }
}

function* fetchReports() {
  try {
    const reportsDto: AxiosResponse<CatalogReportsDto> = yield call(dataAPI.getReports);
    const reports = mapCatalogReportsDtoToCatalogReports(reportsDto);
    yield put(catalogActions.setReports(reports));
  } catch (e) {
    console.log(e);
  }
}

function* fetchAreas() {
  try {
    const areasDto: AxiosResponse<CatalogAreasDto> = yield call(dataAPI.getAreas);
    const areas = mapCatalogAreasDtoToCatalogAreas(areasDto);
    yield put(catalogActions.setAreas(areas));
  } catch (e) {
    console.log(e);
  }
}

function* fetchCategories() {
  try {
    const categoriesDto: AxiosResponse<CatalogCategoriesDto> = yield call(dataAPI.getCategories);
    const category = mapCatalogCategoriesDtoToCatalogCategory(categoriesDto);
    yield put(catalogActions.setCategories(category));
  } catch (e) {
    console.log(e);
  }
}

function* fetchMeasures() {
  try {
    const measuresDto: AxiosResponse<CatalogMeasuresDto> = yield call(dataAPI.getMeasures);
    const measure = mapCatalogMeasureDtoToCatalogMeasure(measuresDto);
    yield put(catalogActions.setMeasures(measure));
  } catch (e) {
    console.log(e);
  }
}

function* fetchCounties() {
  try {
    const countiesDto: AxiosResponse<CatalogCountryDto[]> = yield call(dataAPI.getCounties);
    const counties = mapCatalogCountryDtoToCatalogCountry(countiesDto);
    yield put(catalogActions.setCounties(counties));
  } catch (e) {
    console.log(e);
  }
}

function* catalogSagaWatcher() {
  yield all([
    takeLatest(getType(catalogActions.getPublishers), fetchPublishers),
    takeLatest(getType(catalogActions.getReports), fetchReports),
    takeLatest(getType(catalogActions.getAreas), fetchAreas),
    takeLatest(getType(catalogActions.getCategories), fetchCategories),
    takeLatest(getType(catalogActions.getMeasures), fetchMeasures),
    takeLatest(getType(catalogActions.getMeasures), fetchMeasures),
    takeLatest(getType(catalogActions.getCounties), fetchCounties),
  ]);
}

export default catalogSagaWatcher;
