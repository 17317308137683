import * as React from 'react';
import * as Loadable from 'react-loadable';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { productActions } from '../../ducks/products/actions';
import { RootState } from '../../ducks/store';
import { getProductPageFrequency } from '../../ducks/productPage/selectors';
import ProductPageSkeleton from '../../components/Skeletons/ProductPageSkeleton';

const Frequency = Loadable({
  loader: () => import(/* webpackChunkName: "frequency-products" */ './FrequencyProductScene'),
  loading: () => <ProductPageSkeleton />,
  delay: 200,
  timeout: 10000,
});

const Futures = Loadable({
  loader: () => import(/* webpackChunkName: "futures-products" */ './FuturesProductScene'),
  loading: () => <ProductPageSkeleton />,
  delay: 200,
  timeout: 10000,
});

type Props = StateProps & DispatchProps;

class ProductScene
  extends React.Component<Props & RouteComponentProps<{ id: string }, {}, { area?: number }>, {}> {
  public componentDidMount() {
    const { state } = this.props.location;
    const { id } = this.props.match.params;
    this.props.initializeProductScene(id, state && state.area ? { area: state.area } : {});
  }

  public componentDidUpdate(prevProps: RouteComponentProps<{ id: string }>) {
    const { state } = this.props.location;
    if (this.props.match.params.id !== prevProps.match.params.id) {
      const { id } = this.props.match.params;
      this.props.initializeProductScene(id, state && state.area ? { area: state.area } : {});
      if (document.documentElement && state) {
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        document.body.scrollTop = 0; // For Safari
      }
    } else if (state && prevProps.location.state && state.area !== prevProps.location.state.area) {
      const { id } = this.props.match.params;
      this.props.changeArea(id, state && state.area ? { area: state.area } : {});
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      document.body.scrollTop = 0; // For Safari
    }
  }

  public render() {
    switch (this.props.productSceneType as any) {
      case 'Daily':
      case 'Weekly':
      case 'Monthly':
      case 'Quarterly':
      case 'Annual':
        return (<Frequency />);
      case 'Futures':
        return (<Futures />);
      default:
        return <ProductPageSkeleton />;
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  productSceneType: getProductPageFrequency(state),
});

type StateProps = ReturnType <typeof mapStateToProps>;

const mapDispatchToProps = {
  initializeProductScene: productActions.initialize,
  changeArea: productActions.changeProductArea,
};

type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProductScene);
