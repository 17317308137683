import * as React from 'react';
import styled from 'styled-components';
import colorPalette from '../../../utils/color';

const Root = styled.div<{size: number}>`
  position: relative;
  display: inline-block;
  width: ${({ size }) => size * 2}px;
  height: ${({ size }) => size}px;
`;

const Switch = styled.div<{ active: boolean; size: number; color: string; passiveColor: string; }>`
  position: absolute;
  cursor: pointer;
  border-radius: 34px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ active, color, passiveColor }) => active ? color : passiveColor };
  box-shadow: 0 0 1px ${({ active, color, passiveColor }) => active ? color : passiveColor };
  transition: .4s;

  &:before {
    position: absolute;
    border-radius: 50%;
    content: "";
    width: ${({ size }) => size - Math.floor(size / 10) || 1}px;
    height: ${({ size }) => size - Math.floor(size / 10) || 1}px;
    left: ${({ size }) => Math.floor((Math.floor(size / 10) || 1) / 2)}px;
    bottom: ${({ size }) => Math.floor((Math.floor(size / 10) || 1) / 2)}px;
    background-color: white;
    transition: .4s;
    transform: translateX(${({ active, size }) => active ? size - (Math.floor(size / 10) || 1) : 0}px);
  }
`;

interface Props {
  onClick?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  active?: boolean;
  size?: number;
  color?: string;
  passiveColor?: string;
}

// TODO: Fix active dot position
const SwitchButton: React.FC<Props> = ({ active = false, size = 22, color = colorPalette.PRIMARY, passiveColor = colorPalette.NEUTRAL, onClick }) =>
  (<Root onClick={onClick} size={size}><Switch active={active} size={size} color={color} passiveColor={passiveColor}/></Root>);

export default SwitchButton;
