import * as React from 'react';
import styled from 'styled-components';
import color from '../../../../utils/color';
import FilterIcon from 'src/icons/FilterIcon';

const FilterByHeader = styled.div`
  margin-top: 30px;
  font-size: 20px;
  padding-bottom: 9px;
  border-bottom: ${color.NEUTRAL} solid 2px;
  margin-bottom: 15px;
`;

const FilterIconWrapper = styled(FilterIcon)`
  color: ${color.NEUTRAL};
  transform: translateY(2px);
  margin-right: 10px;
`;

const FilterBy: React.FC = () => (
  <>
    <FilterByHeader>
      <FilterIconWrapper/>
      Filter By
    </FilterByHeader>
  </>
);

export default FilterBy;
