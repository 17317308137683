import { all, call, takeLatest, put } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { adminActions } from '../actions';
import { UserDto } from '../../../types/dto/UserDto';
import mapUsersDtoToUsers, { mapUserDtoToUser } from './utils/mapUserDtoToUser';
import { toast } from 'react-toastify';
import { authSaga } from '../../authentication/getAuthStatus';
import organizationAPI from '../../../services/organizationServices';
import { AxiosResponse } from 'axios';

export function* getUsers() {
  try {
    const usersDto: AxiosResponse<UserDto[]> = yield call(organizationAPI.fetchUsers);
    const users = yield call(mapUsersDtoToUsers, usersDto.data);
    yield put(adminActions.users.add(users));
  } catch (error) {
    toast.error(error.message, {
      position: 'top-center',
    });
  }
}

export function* deleteUser(action: ActionType<typeof adminActions.users.delete>) {
  try {
    yield call(organizationAPI.deleteUser, action.payload);
    yield put(adminActions.users.remove(action.payload));
    yield toast.success('Delete successful');
  } catch (error) {
    toast.error(error.message);
  }
}

export interface UserData {
  firstName: string,
  lastName: string,
  email: string,
}

export function* createUser(action: ActionType<typeof adminActions.users.new>) {
  try {
    const user: AxiosResponse<UserDto> = yield call(organizationAPI.createUser, action.payload);
    yield put(adminActions.users.addOne(mapUserDtoToUser(user.data)));
    action.meta();
    toast.success('User successful created');
  } catch (error) {
    if (error.response && error.response.status === 409 ||
      error.response && error.response.status === 400) {
      action.meta(error.response.data);
    } else {
      toast.error(error.message);
    }
  }
}

export function* updateUser(action: ActionType<typeof adminActions.users.update>) {
  try {
    // TODO: fix `user` type
    const user: AxiosResponse<UserDto> = yield call(organizationAPI.updateUser, action.meta.userId, action.payload);
    action.meta.cb();
    yield put(adminActions.users.edit(mapUserDtoToUser(user.data), action.meta.userId));
  } catch (error) {
    if (error.response && error.response.status === 409 ||
      error.response && error.response.status === 400) {
      action.meta.cb(error.response.data);
    } else {
      toast.error(error.message);
    }
  }
}

export function* usersSagaWatcher() {
  yield all([
    takeLatest(getType(adminActions.users.get), authSaga(getUsers)),
    takeLatest(getType(adminActions.users.delete), authSaga(deleteUser)),
    takeLatest(getType(adminActions.users.update), authSaga(updateUser)),
    takeLatest(getType(adminActions.users.new), authSaga(createUser)),
  ]);
}

export default usersSagaWatcher;
