import * as React from 'react';
import styled from 'styled-components';
import color from '../../utils/color';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Logo from '../../media/svg/udm_logo.svg';
import { RootState } from '../../ducks/store';
import { getNavMenuItems } from '../../ducks/menu/selectors';
import { connect } from 'react-redux';
import { authActions } from '../../ducks/authentication/actions';
import NavMenuHeader from '../../components/Menu/NavMenuHeader/NavMenuHeader';
import Button from '../../components/shared/Button/Button';
import { getAuthState } from '../../ducks/authentication/selectors';
import NavBarContent from '../../components/Menu/NavBarContent/NavBarContent';

const NavBarHeaderContainer = styled.div`
  box-shadow: 0 3px 6px ${color.NEUTRAL};
  background-color: ${color.BACKGROUND};
  z-index: 100;
`;

const HeaderContent = styled.div`
  padding: 13px 0;
  margin: auto;
  width: 100%;
  max-width: 1100px;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
`;

const NavBar = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 9999;
  background-color: transparent;
  padding-left: 0;
`;

const LogoImg = styled.img`
  height: 36px;
  cursor: pointer;
`;

interface OwnProps {
  isLoggedIn: boolean,
}

type Props = RouteComponentProps & OwnProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const NavBarContainer: React.FC<Props> = ({ navMenuItems, signIn, isLoggedIn, signOut, auth }) => (
  <NavBar>
    <NavBarHeaderContainer>
      <HeaderContent>
        <Link to="/">
          <LogoImg src={Logo} alt="Understanding Dairy Markets" />
        </Link>
        {/*<div>*/}
        {/*<Link to="/Product/26">26</Link> <Link to="/Product/3714">3714</Link> <Link to="/Product/90044">90044</Link> {' '}*/}
        {/*<Link to="/Product/2503">2503</Link> <Link to="/Product/60239">60239</Link>*/}
        {/*</div>*/}
        {isLoggedIn ?
          <NavMenuHeader signOut={signOut} auth={auth} />
          :
          <Button width={135} height={32} filled onClick={signIn}>Sign in</Button>
        }
      </HeaderContent>
    </NavBarHeaderContainer>
    {!(location.pathname === '/') && <NavBarContent />}
  </NavBar>
);

const mapStateToProps = (state: RootState) => ({
  navMenuItems: getNavMenuItems(state),
  auth: getAuthState(state),
});

const mapDispatchToProps = {
  signIn: authActions.signIn,
  signOut: authActions.signOut,
};

const connectedNavBarContainer = connect(mapStateToProps, mapDispatchToProps)(NavBarContainer);
export default withRouter(connectedNavBarContainer);
