import * as React from 'react';
import styled from 'styled-components';
import color from '../../../utils/color';
import calendar from 'src/media/svg/calendar-regular.svg';

const HeaderWrapper = styled.div`
  display: inline-block;
  flex-grow: 1;
  margin: 20px;
  width: 100%;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  .DayPickerInput input {
    border: none;
    width: 195px;
    align-self: center;
    height: 27px;
    background-color: ${color.BACKGROUND_SECONDARY};
    color: ${color.DRP_INPUT};
    font-weight: lighter;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
    padding-left: 10px;
    margin-top: 2px;

    background-color: ${color.BACKGROUND_SECONDARY};
    background-image: url(${calendar});
    background-position: 180px 5px;
    background-repeat: no-repeat;

    &:focus {
      outline: none;
    }
  }

  .DayPicker-Day--today {
    color: ${color.SECONDARY} !important;
  }

  .DayPicker-Day--selected {
    color: ${color.DRP_INPUT} !important;
    background-color: white !important;
    font-weight: bolder;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
`;

const Label = styled.span`
  color: ${color.TEXT_PRIMARY};
  font-weight: normal;
`;

const Value = styled.span`
  color: ${color.DRP_INPUT};
  font-weight: bolder;
`;

interface Props {
  label: string,
  value?: string | number,
}

const InputContainer: React.FC<Props> = ({ label, children, value }) => (
  <HeaderWrapper>
    <LabelWrapper><Label>{label}</Label>{value !== undefined && <Value>{value}</Value>}</LabelWrapper>
    {children}
  </HeaderWrapper>
);

export default InputContainer;
