import * as React from 'react';
import CatalogSelectModal from '../../../components/shared/Modal/CatalogSelectModal';
import { RootState } from '../../../ducks/store';
import { connect } from 'react-redux';
import { catalogActions } from '../../../ducks/catalog/actions';
import { SortOrder } from '../../../utils/enums';
import { getCatalogFilterSearch, getCatalogFilterSortOrder, getReportCode, getFilteredCatalogReportsList } from '../../../ducks/catalog/selectors';

interface OwnProps {
  onClose: () => void,
  isTranslated: boolean,
}

type Props = OwnProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

const ReportsCatalogContainer: React.FC<Props> = ({
  onClose, selectedList, getReports, addReport, removeReport, removeAllReports, setSortOrder, setSearch, sortOrder, search, filteredList,
}) => {
  React.useEffect(() => {
    getReports();
  }, []);

  const onListItemClick = (id: string) => () => {
    if (selectedList.includes(id)) {
      removeReport(id);
    } else {
      addReport(id);
    }
  };

  const onClearClick = () => {
    removeAllReports();
  };

  const onSortClick = () => {
    if (sortOrder === SortOrder.DESCENDING) {
      setSortOrder(SortOrder.ASCENDING)
    } else {
      setSortOrder(SortOrder.DESCENDING)
    }
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <CatalogSelectModal
      name={'Reports'}
      onClose={onClose}
      onListItemClick={onListItemClick}
      list={filteredList}
      onClearSelectClick={onClearClick}
      sortOrder={sortOrder}
      onSortClick={onSortClick}
      search={search}
      onSearchChange={onSearchChange}
    />
  );
};

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  filteredList: getFilteredCatalogReportsList(state, props),
  selectedList: getReportCode(state),
  sortOrder: getCatalogFilterSortOrder(state),
  search: getCatalogFilterSearch(state),
});

const mapDispatchToProps = {
  getReports: catalogActions.getReports,
  addReport: catalogActions.filter.addReport,
  removeReport: catalogActions.filter.removeReport,
  removeAllReports: catalogActions.filter.removeAllReports,
  setSortOrder: catalogActions.filterUtils.setSortOrder,
  setSearch: catalogActions.filterUtils.setSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsCatalogContainer);
