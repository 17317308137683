import * as React from 'react';
import { connect } from 'react-redux';
import { catalogActions } from '../../../ducks/catalog/actions';
import { getCatalogFilterSearch, getCatalogFilterSortOrder, getFilteredCatalogAreasList, getAreaId } from 'src/ducks/catalog/selectors';
import { SortOrder } from '../../../utils/enums';
import { RootState } from '../../../ducks/store';
import CatalogSelectModal from '../../../components/shared/Modal/CatalogSelectModal';

interface OwnProps {
  onClose: () => void,
  isTranslated: boolean,
}

type Props = OwnProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

const AreasCatalogContainer: React.FC<Props> = ({
  getAreas, addArea, removeArea, removeAllAreas, setSortOrder, sortOrder, setSearch, search, filteredList, selectedList, onClose,
}) => {
  React.useEffect(() => {
    getAreas();
  }, []);

  const onListItemClick = (id: number) => () => {
    if (selectedList.includes(id)) {
      removeArea(id);
    } else {
      addArea(id);
    }
  };

  const onClearClick = () => {
    removeAllAreas();
  };

  const onSortClick = () => {
    if (sortOrder === SortOrder.DESCENDING) {
      setSortOrder(SortOrder.ASCENDING)
    } else {
      setSortOrder(SortOrder.DESCENDING)
    }
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <CatalogSelectModal
      name={'Areas'}
      onClose={onClose}
      onListItemClick={onListItemClick}
      list={filteredList}
      onClearSelectClick={onClearClick}
      sortOrder={sortOrder}
      onSortClick={onSortClick}
      search={search}
      onSearchChange={onSearchChange}
    />
  );
};

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  filteredList: getFilteredCatalogAreasList(state, props),
  selectedList: getAreaId(state),
  sortOrder: getCatalogFilterSortOrder(state),
  search: getCatalogFilterSearch(state),
});

const mapDispatchToProps = {
  getAreas: catalogActions.getAreas,
  addArea: catalogActions.filter.addArea,
  removeArea: catalogActions.filter.removeArea,
  removeAllAreas: catalogActions.filter.removeAllAreas,
  setSortOrder: catalogActions.filterUtils.setSortOrder,
  setSearch: catalogActions.filterUtils.setSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(AreasCatalogContainer);
