import axios from 'axios';
import { PeriodFrequency } from '../types/PeriodFrequency';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_API_URL}/direct/`,
  timeout: 20000,
});

const directAPI = {
  fetchAllViews: () => api.get('view'),

  fetchReports: () => api.get('/report'),

  fetchLandingPageTreeMapData: () => api.get('/product/map'),

  fetchSeriesContext: (seriesId: string) => api.get(`/context/series/${seriesId}`),

  fetchTableContext: (tableId: number, frequency: PeriodFrequency) => api.get(`/context/table/${tableId}/${frequency}`),
};

export default directAPI;
