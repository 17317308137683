import styled from 'styled-components';
import color from '../../../../utils/color';

const ClearFilterContainer = styled.div`
  font-size: 14px;
  color: ${color.PRIMARY};
  font-weight: 400;
  margin-top: 10px;
  cursor: pointer;

  svg {transform: translateY(2px);}
`;

export default ClearFilterContainer;
