import { TableContext } from '../../../types/TableContext';
import { TableContextDto } from '../../../types/dto/TableContextDto';
import { getFrequencyTimestamp, getTimeIndexFromTimestamp, getYearFromTimestamp } from '../../records/frequency/utils/timeFunctions';

const mapContextTableDtoToContextTable = (data: TableContextDto[]): TableContext[] => data.map(({
  Name, Order, Series, Area, Indent, Bold, Footnote, Values, ReportPeriod,
}) => {
  const values = {};
  const valueTimestamps: number[] = [];
  Values.forEach(({ Value, ReportPeriod: ValuesReportPeriod }) => {
    const date = new Date(ValuesReportPeriod);
    const timestamp = getFrequencyTimestamp(date, 'Monthly');
    values[timestamp] = {
      value: Value,
      index: getTimeIndexFromTimestamp(timestamp),
      year: getYearFromTimestamp(timestamp),
      date, timestamp,
    };
    valueTimestamps.push(timestamp);
  });
  return {
    name: Name,
    order: Order,
    series: Series ? {
      id: Series.Id,
      name: Series.Name,
      nameTranslated: Series.NameTranslated,
      principalAreaId: Series.PrincipalAreaId,
      scale: Series.Scale,
    } : null,
    area: Area ? { id: Area.Id, name: Area.Name, principal: Area.Principal } : null,
    indent: Indent,
    bold: Bold,
    footnote: Footnote,
    unitID: Series && Series.Unit ? Series.Unit.Id : null,
    reportPeriod: new Date(ReportPeriod),
    reportPeriodTimestamp: getFrequencyTimestamp(new Date(ReportPeriod), 'Monthly'),
    values, valueTimestamps,
  }
});

export default mapContextTableDtoToContextTable;
