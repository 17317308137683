import { all } from 'redux-saga/effects';
import frequencyRecordWatcher from './frequency/sagas';
import futuresRecordWatcher from './futures/sagas';

export default function* recordsRootSaga() {
  yield all([
    frequencyRecordWatcher(),
    futuresRecordWatcher(),
  ]);
}
