import { ActionType, createStandardAction } from 'typesafe-actions';
import { SortOrder } from '../../utils/enums';
import { Publisher } from '../../types/Publisher';
import { CatalogReport } from '../../types/CatalogReport';
import { CatalogArea } from '../../types/CatalogArea';
import { CatalogCategory } from '../../types/CatalogCategory';
import { CatalogMeasure } from '../../types/CatalogMeasure';
import { CatalogCountry } from '../../types/CatalogCountry';

export const catalogActions = {
  setSort: createStandardAction('@catalog/SET_SORT')<string>(),
  setSortOrder: createStandardAction('@catalog/SET_SORT_ORDER')<SortOrder>(),
  setPublishers: createStandardAction('@catalog/SET_PUBLISHERS')<Publisher[]>(),
  getPublishers: createStandardAction('@catalog/GET_PUBLISHERS')(),
  setReports: createStandardAction('@catalog/SET_REPORTS')<CatalogReport[]>(),
  getReports: createStandardAction('@catalog/GET_REPORTS')(),
  setAreas: createStandardAction('@catalog/SET_AREAS')<CatalogArea[]>(),
  getAreas: createStandardAction('@catalog/GET_AREAS')(),
  setCategories: createStandardAction('@catalog/SET_CATEGORIES')<CatalogCategory[]>(),
  getCategories: createStandardAction('@catalog/GET_CATEGORIES')(),
  setMeasures: createStandardAction('@catalog/SET_MEASURES')<CatalogMeasure[]>(),
  getMeasures: createStandardAction('@catalog/GET_MEASURES')(),
  setCounties: createStandardAction('@catalog/SET_COUNTIES')<CatalogCountry[]>(),
  getCounties: createStandardAction('@catalog/GET_COUNTIES')(),
  filter: {
    clearAll: createStandardAction('@catalog_filter/CLEAR_ALL')(),
    // Publishers
    addPublisher: createStandardAction('@catalog_filter/ADD_PUBLISHER')<number>(),
    addAllPublisher: createStandardAction('@catalog_filter/ADD_ALL_PUBLISHER')(),
    removePublisher: createStandardAction('@catalog_filter/REMOVE_PUBLISHER')<number>(),
    removeAllPublisher: createStandardAction('@catalog_filter/REMOVE_ALL_PUBLISHER')(),
    // Reports
    addReport: createStandardAction('@catalog_filter/ADD_REPORTS')<string>(),
    addAllReports: createStandardAction('@catalog_filter/ADD_ALL_REPORTS')(),
    removeReport: createStandardAction('@catalog_filter/REMOVE_REPORTS')<string>(),
    removeAllReports: createStandardAction('@catalog_filter/REMOVE_ALL_REPORTS')(),
    // Areas
    addArea: createStandardAction('@catalog_filter/ADD_AREAS')<number>(),
    addAllAreas: createStandardAction('@catalog_filter/ADD_ALL_AREAS')(),
    removeArea: createStandardAction('@catalog_filter/REMOVE_AREAS')<number>(),
    removeAllAreas: createStandardAction('@catalog_filter/REMOVE_ALL_AREAS')(),
    // Categories
    addCategory: createStandardAction('@catalog_filter/ADD_CATEGORIES')<number>(),
    addAllCategories: createStandardAction('@catalog_filter/ADD_ALL_CATEGORIES')(),
    removeCategory: createStandardAction('@catalog_filter/REMOVE_CATEGORIES')<number>(),
    removeAllCategories: createStandardAction('@catalog_filter/REMOVE_ALL_CATEGORIES')(),
    // Measures
    addMeasure: createStandardAction('@catalog_filter/ADD_MEASURES')<number>(),
    addAllMeasures: createStandardAction('@catalog_filter/ADD_ALL_MEASURES')(),
    removeMeasure: createStandardAction('@catalog_filter/REMOVE_MEASURES')<number>(),
    removeAllMeasures: createStandardAction('@catalog_filter/REMOVE_ALL_MEASURES')(),
    // Counties
    addCountry: createStandardAction('@catalog_filter/ADD_COUNTIES')<number>(),
    addAllCounties: createStandardAction('@catalog_filter/ADD_ALL_COUNTIES')(),
    removeCountry: createStandardAction('@catalog_filter/REMOVE_COUNTIES')<number>(),
    removeAllCounties: createStandardAction('@catalog_filter/REMOVE_ALL_COUNTIES')(),
  },
  filterUtils: {
    setSortOrder: createStandardAction('@catalog_filterUtils/SET_SORT')<SortOrder>(),
    setSearch: createStandardAction('@catalog_filterUtils/SET_SEARCH')<string>(),
    reset: createStandardAction('@catalog_filterUtils/RESET')(),
  },
};

export type CatalogActions = ActionType<typeof catalogActions>;
