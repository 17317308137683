import produce from 'immer';
import { getType } from 'typesafe-actions';
import createUniqueArray from '../../utils/createUniqueArray';
import { ProductActions, productActions } from './actions';
import { Product } from '../../types/Product';

export interface ProductState {
  allProducts: number[],
  productById: { [id: number] : Product }
}

export const initialState: ProductState = {
  allProducts: [],
  productById: {},
};

export const productReducer = produce<ProductState, ProductActions>(
  (state, action) => {
    switch (action.type) {
      case getType(productActions.addProduct):
        state.allProducts =
          createUniqueArray<number>([...state.allProducts, action.payload.product.id]);
      const { areas, metaFields, product, publisher, report, summary, unit, updates, scale } = action.payload;
        state.productById[action.payload.product.id] = { areas, metaFields, product, publisher,
          report, summary, unit, updates, defaultScale: scale};
        return;
    }
  }, initialState,
);
