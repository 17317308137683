export enum ExploreData {
  TIME_ENDING = 'TIME_ENDING',
  TIME_CHANGE = 'TIME_CHANGE',
  YEAR_ON_YEAR = 'YEAR_ON_YEAR',
  TIME_ON_TIME = 'TIME_ON_TIME',
}

export enum SortOrder {
  DESCENDING = 'DESCENDING',
  ASCENDING = 'ASCENDING',
}

export enum monthsByName {
  JANUARY = 0,
  FEBRUARY = 1,
  MARCH = 2,
  APRIL = 3,
  MAY = 4,
  JUNE = 5,
  JULY = 6,
  AUGUST = 7,
  SEPTEMBER = 8,
  OCTOBER = 9,
  NOVEMBER = 10,
  DECEMBER = 11,
}

export enum DateStatus {
  OUTSIDE = 'DayPicker-Day--outside',
  START = 'DayPicker-Day--selected DayPicker-Day--start',
  END = 'DayPicker-Day--selected DayPicker-Day--end',
  INSIDE = 'DayPicker-Day--selected',
  DISABLED = 'DayPicker-Day--disabled',
}
