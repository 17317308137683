import * as React from 'react';
import MinusIcon from 'src/icons/MinusIcon';
import FilterIcon from 'src/icons/FilterIcon';
import FilterLabel from '../../components/Catalog/FilterLabel/FilterLabel';
import CatalogSortContainer from '../../containers/CatalogSortContainer/CatalogSortContainer';
import LangPicker from '../../components/shared/LangPicker/LangPicker';
import catalogAPI from '../../services/catalogServices';
import { Link } from 'react-router-dom';
import PerPage from '../../components/shared/Pagination/perPage';
import { ChangeEvent } from 'react';
import PaginationWrapper from '../../components/AdminPanel/adminPanel/PaginationWrapper';
import { RootState } from '../../ducks/store';
import { getAreaId, getCatalogSort, getCategoryId, getMeasureId, getPublisherIds, getReportCode } from '../../ducks/catalog/selectors';
import { connect } from 'react-redux';
import PublishersCatalogContainer from 'src/containers/catalog/PublishersCatalogContainer/PublishersCatalogContainer';
import ReportsCatalogContainer from '../../containers/catalog/ReportsCatalogContainer/ReportsCatalogContainer';
import AreasCatalogContainer from '../../containers/catalog/AreasCatalogContainer/AreasCatalogContainer';
import CategoriesCatalogContainer from '../../containers/catalog/CategoriesCatalogContainer/CategoriesCatalogContainer';
import MeasuresCatalogContainer from '../../containers/catalog/MeasuresCatalogContainer/MeasuresCatalogContainer';
import { catalogActions } from '../../ducks/catalog/actions';
import FilterContentHeader from '../../components/Catalog/styles/FilterContentHeader/FilterContentHeader';
import ClearFilterContainer from '../../components/Catalog/styles/ClearFilterContainer/ClearFilterContainer';
import FilterContent from '../../components/Catalog/styles/FilterContent/FilterContent';
import ContentTitle from '../../components/Catalog/styles/ContentTitle/ContentTitle';
import PaginationContainer from 'src/components/Catalog/styles/PaginationContainer/PaginationContainer';
import PaginationBox from '../../components/Catalog/styles/PaginationBox/PaginationBox';
import SeriesLink from '../../components/Catalog/styles/SeriesLink/SeriesLink';
import { RouterState } from './CatalogScene';

enum ModalView {
  none,
  publishers,
  reports,
  areas,
  categories,
  measures,
}

interface OwnProps {
  search: string;
  locationState: RouterState,
}

type Props = OwnProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const CatalogSeriesScene: React.FC<Props> =
  ({ search, sort, publisherIdList, reportsList, areaList, categoryList, measureList, clearAll, resetFilterSearch }) => {
  const [ translated, setTranslation ] = React.useState(false);
  const switchClick = () => { setTranslation(!translated); };

  const [ pageSize, setPageSize ] = React.useState(25);
  const onPageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => { setPageSize(Number(event.target.value)) };

  const [ page, setPage ] = React.useState(1);
  const setNextPage = () => { setPage(page - 1) };
  const setPreviousPage = () => { setPage(page + 1) };

  const [ seriesList, setList ] = React.useState<Array<{id: number, name: string}>>([]);
  const [ hasNext, setHasNext ] = React.useState(false);

  const [ modalView, setModalView ] = React.useState(ModalView.none);
  const onFilterClick = (filter: ModalView) => () => { resetFilterSearch(); setModalView(filter) };
  const getModalView = () => {
  const modalProps = { onClose: () => { setModalView(ModalView.none) }, isTranslated: translated };
    switch (modalView) {
      case ModalView.publishers: return <PublishersCatalogContainer {...modalProps} />;
      case ModalView.reports: return <ReportsCatalogContainer {...modalProps} />;
      case ModalView.areas: return <AreasCatalogContainer {...modalProps} />;
      case ModalView.categories: return <CategoriesCatalogContainer {...modalProps} />;
      case ModalView.measures: return <MeasuresCatalogContainer {...modalProps} />;
      default: return null;
    }
  };
  const clearAllFilters = () => {
    clearAll();
  };

    React.useEffect(() => {
      if (modalView === ModalView.none) {
        (async () => {
          const response = await catalogAPI.searchSeries({
            pageSize, translated,
            page: page - 1,
            contains: search.length >= 3 ? search : '',
            startsWith: sort === 'All' || sort === 'Recent' ? '' : sort,
          }, {
              publisherId: publisherIdList,
              reportCode: reportsList,
              areaId: areaList,
              categoryId: categoryList,
              measureId: measureList,
            });
          setList(response.data);
          setHasNext(response.hasNext);
        })();
      }
    }, [translated, search, pageSize, page, sort, modalView, publisherIdList, reportsList, areaList, categoryList, measureList]);
    return (
      <>
        <FilterContentHeader>
          <ContentTitle>
            <FilterIcon size={16} /> Sort & Filter
        </ContentTitle>
          <ClearFilterContainer onClick={clearAllFilters}><MinusIcon /> Clear Filters</ClearFilterContainer>
        </FilterContentHeader>
        <FilterContent>
          <FilterLabel isActive={publisherIdList.length > 0} onClick={onFilterClick(ModalView.publishers)}>Publishers</FilterLabel>
          <FilterLabel isActive={reportsList.length > 0} onClick={onFilterClick(ModalView.reports)}>Reports</FilterLabel>
          <FilterLabel isActive={areaList.length > 0} onClick={onFilterClick(ModalView.areas)}>Areas</FilterLabel>
          <FilterLabel isActive={categoryList.length > 0} onClick={onFilterClick(ModalView.categories)}>Categories</FilterLabel>
          <FilterLabel isActive={measureList.length > 0} onClick={onFilterClick(ModalView.measures)}>Measures</FilterLabel>
        </FilterContent>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <CatalogSortContainer />
          <LangPicker active={translated} onClick={switchClick} />
        </div>
        {seriesList.map(({ id, name }) => (<SeriesLink key={id}><Link to={`/product/${id}`}>{name}</Link></SeriesLink>))}
        <PaginationWrapper>
          {/*TODO: use dataLength from backend*/}
          <PerPage dataLength={100} perPage={pageSize} perPageClickHandler={onPageSizeChange} />
          <PaginationContainer>
            {page > 1 && <PaginationBox onClick={setNextPage}>Previous</PaginationBox>}
            {hasNext && <PaginationBox onClick={setPreviousPage}>Next</PaginationBox>}
          </PaginationContainer>
        </PaginationWrapper>
        {getModalView()}
      </>
    );
  };

const mapStateToProps = (state: RootState) => ({
  sort: getCatalogSort(state),
  publisherIdList: getPublisherIds(state),
  reportsList: getReportCode(state),
  areaList: getAreaId(state),
  categoryList: getCategoryId(state),
  measureList: getMeasureId(state),
});

const mapDispatchToProps = {
  clearAll: catalogActions.filter.clearAll,
  resetFilterSearch: catalogActions.filterUtils.reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogSeriesScene);
