import * as React from 'react';
import CatalogMenuHomeItem from './CatalogMenuHomeItem';
import styled from 'styled-components';
import color from '../../../../utils/color';

const items = [
  {
    reportTitle: 'Production of major industrial products',
    reportName: 'Produkcja wazniejszych wyrobow przemyslowyxh',
    publisherName: 'Glowny Urzad Statystyczny',
    country: 'Poland',
    url: 'http://loremipsum.com',
    releaseDate: 'October 27. 2018',
    datePeriod: '1/1/2004 - 10/18/2018',
  },
  {
    reportTitle: 'Processed Dairy Product Prices',
    reportName: 'Prix des produits laitiers industriels',
    publisherName: 'L’ Etablissement national des produits de l’ agriculture et de la mer (FranceAgriMer)',
    country: 'France',
    releaseDate: 'October 28, 2018',
    datePeriod: '1/1/2016 - 10/18/2018',
  },
  {
    reportTitle: 'UK Wholesale Prices',
    reportName: 'Uk Wholesale Prices',
    publisherName: 'Agriculture & Horticulture Development Board',
    country: 'United Kingdom',
    releaseDate: 'October 29, 2018',
    datePeriod: '1/1/2016 - 10/18/2018',
  },
];

const CatalogMenuHomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CatalogHomeHeader = styled.div`
  font-size: 20px;
  padding-bottom: 9px;
  border-bottom: ${color.NEUTRAL} solid 2px;
  margin-bottom: 15px;
`;

const CatalogMenuHome: React.FC = () => (
  <CatalogMenuHomeWrapper>
    <CatalogHomeHeader>
      Recently Added Reports
    </CatalogHomeHeader>
    {items.map((item) => <CatalogMenuHomeItem item={item} key={item.reportTitle}/>)}
  </CatalogMenuHomeWrapper>
);

export default CatalogMenuHome;
