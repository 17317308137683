import * as React from 'react';
import { RouterState } from '../CatalogScene';
import catalogAPI from 'src/services/catalogServices';
import CatalogSortContainer from '../../../containers/CatalogSortContainer/CatalogSortContainer';
import SeriesLink from '../../../components/Catalog/styles/SeriesLink/SeriesLink';
import { Link } from 'react-router-dom';
import PaginationWrapper from 'src/components/AdminPanel/adminPanel/PaginationWrapper';
import PerPage from '../../../components/shared/Pagination/perPage';
import PaginationContainer from '../../../components/Catalog/styles/PaginationContainer/PaginationContainer';
import PaginationBox from '../../../components/Catalog/styles/PaginationBox/PaginationBox';
import { RootState } from 'src/ducks/store';
import { connect } from 'react-redux';
import { getCatalogSort, getReportCode } from '../../../ducks/catalog/selectors';
import LangPicker from 'src/components/shared/LangPicker/LangPicker';

interface OwnProps {
  search: string;
  locationState: RouterState,
}

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

const CatalogPublishersReportsScene: React.FC<Props> = ({ search, sort, reportsList, locationState }) => {
  const [ translated, setTranslation ] = React.useState(false);
  const switchClick = () => { setTranslation(!translated); };

  const [ pageSize, setPageSize ] = React.useState(25);
  const onPageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => { setPageSize(Number(event.target.value)) };

  const [ page, setPage ] = React.useState(1);
  const setNextPage = () => { setPage(page - 1) };
  const setPreviousPage = () => { setPage(page + 1) };

  const [ seriesList, setList ] = React.useState<Array<{id: string, name: string}>>([]);
  const [ hasNext, setHasNext ] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const publisherId = locationState && locationState.nested ?
        [locationState && Number(locationState.nested[locationState && locationState.nested.length - 1].id)] : [];
      const response = await catalogAPI.searchReports({
        pageSize, translated,
        page: page - 1,
        contains: search.length >= 3 ? search : '',
        startsWith: sort === 'All' || sort === 'Recent' ? '' : sort,
      }, { publisherId });
      setList(response.data);
      setHasNext(response.hasNext);
    })();
  }, [translated, search, pageSize, page, sort]);
  const prevValues = locationState && locationState.nested ? locationState.nested : [];
  return (
    <>
      <div style={{ display: 'flex', marginBottom: '10px' }}>
        <CatalogSortContainer />
        <LangPicker active={translated} onClick={switchClick} />
      </div>
      {seriesList.map(({ id, name }) => (
        <SeriesLink key={id}>
          <Link to={{ state: { nested: [...prevValues, { name: 'Report', forName: 'Series', description: name, id }] } }}>{name}</Link>
        </SeriesLink>))}
      <PaginationWrapper>
        {/*TODO: use dataLength from backend*/}
        <PerPage dataLength={100} perPage={pageSize} perPageClickHandler={onPageSizeChange} />
        <PaginationContainer>
          { page > 1 && <PaginationBox onClick={setNextPage}>Previous</PaginationBox>}
          { hasNext && <PaginationBox onClick={setPreviousPage}>Next</PaginationBox>}
        </PaginationContainer>
      </PaginationWrapper>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  sort: getCatalogSort(state),
  reportsList: getReportCode(state),
});

export default connect(mapStateToProps)(CatalogPublishersReportsScene);
