import * as React from 'react';
import SwitchButton from '../SwitchButton/SwitchButton';
import styled from 'styled-components';
import color from '../../../utils/color';

const Root = styled.div<{ active: boolean }>`
  display: flex;
  margin-left: 50px;
  border-bottom: ${color.NEUTRAL} solid 2px;
  font-size: 14px;
  padding-bottom: 10px;

  span:first-child {
    color: ${({ active }) => active ? color.TEXT_PRIMARY : color.PRIMARY};
    margin-right: 10px;
  }

  span:last-child {
    color: ${({ active }) => !active ? color.TEXT_PRIMARY : color.PRIMARY};
    margin-left: 10px;
  }
`;

interface Props {
  onClick: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  active: boolean;
}

const LangPicker: React.FC<Props> = ({ onClick, active }) =>
  (<Root active={active}><span>Original</span><SwitchButton active={active} onClick={onClick} size={20} /><span>English</span></Root>);

export default LangPicker;
