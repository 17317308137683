import styled from 'styled-components';
import color from '../../../../utils/color';

const FilterContent = styled.div`
  display: flex;
  margin-bottom: 10px;
  border-bottom: ${color.NEUTRAL} solid 2px;
  padding-bottom: 20px;
`;

export default FilterContent;
