import produce from 'immer';
import { OrganizationUserDto } from '../../../types/dto/OrganizationUserDto';
import { adminActions, AdminActions } from '../actions';
import { getType } from 'typesafe-actions';

interface OrganizationUsers {
  [organizationName: string] : OrganizationUserDto[],
}

const initialState = {};

export const organizationUsersReducer = produce<OrganizationUsers, AdminActions>(
  (state, action) => {
    switch (action.type) {
      case getType(adminActions.organizationUsers.saveUsers): {
        const {users, organizationName } = action.payload;
        state[organizationName] = users;
        return;
      }
      case getType(adminActions.organizationUsers.saveUser): {
        const { user, organizationName } = action.payload;
        const organizationUser: OrganizationUserDto = {
          organizationName,
          isAdmin: user.isAdmin,
          userName: user.userName,
          firstName: user.firstName,
          lastName: user.lastName,
          userId: user.userId,
          email: user.email,
        };
        state[organizationName] = [...state[organizationName], organizationUser];
        return;
      }
      case getType(adminActions.organizationUsers.deleteUser): {
        const { userName, organizationName } = action.payload;
        const deleteIndex = state[organizationName].findIndex((user) => user.userName === userName);
        if (deleteIndex === -1) {
          return;
        }
        state[organizationName].splice(deleteIndex, 1);
        return;
      }
      case getType(adminActions.organizationUsers.removeOrganization):
        delete state[action.payload];
        return;
      case getType(adminActions.organizationUsers.addAdminUserRole): {
        const { userName, organizationName, role } = action.payload;
        state[organizationName] = state[organizationName].map((user) => {
          if (user.userName === userName) {
            user.isAdmin = role === 'admin';
          }
          return user;
        });
        return;
      }
      case getType(adminActions.organizationUsers.removeAdminUserRole): {
        const { userName, organizationName } = action.payload;
        state[organizationName] = state[organizationName].map((user) => {
          if (user.userName === userName) {
            user.isAdmin = false;
          }
          return user;
        });
        return;
      }
    }
}, initialState);
