import { ActionType, createStandardAction } from 'typesafe-actions';
import { PeriodFrequency } from '../../types/PeriodFrequency';
import { FrequencyRecord } from '../../types/FrequencyRecord';
import { FuturesRecords } from '../../types/FuturesRecord';

interface RecordsFrequencyMeta {
  frequency: PeriodFrequency,
  productID: string,
}

export interface InitializeFrequencyMeta {
  frequency: PeriodFrequency,
  areaID: number,
  hasUsaMap?: boolean,
}

export const recordsActions = {
  frequency: {
    initializeFrequency: createStandardAction('@records_frequency/INITIALIZE_FREQUENCY')<string, InitializeFrequencyMeta>(),
    initializeUnitsForFrequency: createStandardAction('@records_frequency/INITIALIZE_UNITS_FOR_FREQUENCY')<string, PeriodFrequency>(),
    fetchFrequency: createStandardAction('@records_frequency/FETCH_FREQUENCY')<string, InitializeFrequencyMeta>(),
    addFrequency: createStandardAction('@records_frequency/ADD_FREQUENCY_TO_RECORDS')<FrequencyRecord, RecordsFrequencyMeta>(),
  },
  futures: {
    initializeFutures: createStandardAction('@records_futures/INITIALIZE')(),
    setFutures: createStandardAction('@records_futures/SET_FUTURES')<string, FuturesRecords>(),
  },
};

export type RecordsActions = ActionType<typeof recordsActions>;
