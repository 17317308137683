import * as React from 'react';
import styled from 'styled-components';
import color from 'src/utils/color';
import PlusIcon from 'src/icons/PlusIcon';

const Root = styled.div<{ active: boolean }>`
  background-color: ${color.BACKGROUND_SECONDARY};
  color: ${({active}) => active ? color.PRIMARY : color.TEXT_PRIMARY};
  font-size: 14px;
  border-radius: 6px;
  width: auto;
  margin-right: 10px;
  padding: 12px 25px;
  cursor: pointer;

  &&:last-child {
    margin: 0;
  }

  svg {
    transform: translateY(2px);
    margin-right: 5px;
  }
`;

interface Props {
  isActive: boolean;
  onClick: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const FilterLabel: React.FC<Props> = ({ children, onClick, isActive = false }) =>
  (<Root active={isActive} onClick={onClick}><PlusIcon />{children}</Root>);

export default FilterLabel;
