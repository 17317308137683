import * as React from 'react';
import styled from 'styled-components';
import AngleRightIcon from '../../../../icons/AngleRightIcon';
import AngleDownIcon from 'src/icons/AngleDownIcon';
import color from '../../../../utils/color';
import { NavMenuDto } from '../../../../types/dto/NavMenuDto';
import { menuActions } from '../../../../ducks/menu/actions';
import Tooltip from '../../../shared/Tooltip/Tooltip';

const Submenu = styled.div`
  margin-left: 20px;
`;

const ArrowContainer = styled.div`
  color: ${color.PRIMARY};
  margin-right: 10px;
  transform: translateY(3px);
`;

const ItemWrapper = styled.div<{hasChildren: boolean, isSelected: boolean}>`
  display: flex;
  position: relative;
  font-size: 14px;
  font-weight: ${({ isSelected }) =>  isSelected ? 600 : 400};
  cursor: pointer;
  padding: 10px 0;
  margin-left: ${({ hasChildren }) =>  hasChildren ? '0' : '15px'};
`;

const TextContainer = styled.div`
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  item: NavMenuDto,
  selectedMenuID: number,
  setSelectedMenu: typeof menuActions.navMenu.setSelectedMenu,
  getMenu: typeof menuActions.menu.get,
}

interface State {
  isExpanded: boolean
  isHovered: boolean,
}

class BrowseDataItem extends React.Component<Props, State> {
  public state = {
    isExpanded: false,
    isHovered: false,
  };

  public componentDidMount() {
    window.addEventListener(`expandSubmenu-${this.props.item.id}`, this.handleSubmenuExpansion);
  }

  public componentWillUnmount() {
    window.removeEventListener(`expandSubmenu-${this.props.item.id}`, this.handleSubmenuExpansion);
  }

  public render() {
    const { item, setSelectedMenu, selectedMenuID, getMenu } = this.props;
    const { isExpanded, isHovered } = this.state;
    return (
      <>
        <ItemWrapper hasChildren={!!item.children.length} isSelected={item.id === selectedMenuID}>
          {item.children.length ? isExpanded
            ? <ArrowContainer><AngleDownIcon onClick={this.onDownIconClickHandler}/></ArrowContainer>
            : <ArrowContainer><AngleRightIcon onClick={this.onRightIconClickHandler}/></ArrowContainer>
            : null}
            <TextContainer id={`submenu-${item.id}`}>
              <span onClick={this.onSubmenuClickHandler(item)}
                    onMouseOver={this.onMouseInHandler(item.id)}
                    onMouseOut={this.onMouseOutHandler}>{item.text}
              </span>
            </TextContainer>
          <Tooltip isVisible={isHovered}>{item.text}</Tooltip>
        </ItemWrapper>
        {isExpanded &&
        (item.children.length
          ? <Submenu>{item.children.map((submenuItem) =>
              <BrowseDataItem item={submenuItem}
                              getMenu={getMenu}
                              key={submenuItem.text}
                              selectedMenuID={selectedMenuID}
                              setSelectedMenu={setSelectedMenu}/>)}
            </Submenu>
          : null)
        }
      </>
    )
  }

  private onDownIconClickHandler = () => {this.setState({ isExpanded: false })};

  private onRightIconClickHandler = () => {this.setState({ isExpanded: true })};

  private onMouseInHandler = (menuID: number) => () => {
    const element = document.getElementById(`submenu-${menuID}`);
    if (this.isOverflown(element)) {
      this.setState({isHovered: true})
    }
  };

  private onMouseOutHandler = () => {this.setState({ isHovered: false })};

  private isOverflown = (element: HTMLElement | null) =>
    element && (element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth);

  private onSubmenuClickHandler = (submenu: NavMenuDto) => () => {
    this.setState({isExpanded: true});
    this.props.setSelectedMenu(submenu);
    if (!submenu.children.length) {
      this.props.getMenu(submenu.id);
    }
  };

  private handleSubmenuExpansion = () => {
    this.setState({isExpanded: !this.state.isExpanded});
  };
}

export default BrowseDataItem;
