import axios from 'axios';
import { format } from 'date-fns';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_API_URL}/direct/`,
  timeout: 20000,
});

const addQueryParameter = (currentParams: string, query: string, param: string | number | boolean | number[]) =>
  `${currentParams && `${currentParams}&`}${query}=${param}`;

interface RiskPremiumService {
  reinsuranceYear: number,
  stateCode: number,
  practiceCode: number,
  salesEffectiveDate: Date,
  butterfatTest: number,
  proteinTest: number,
  classPriceWeightingFactor: number,
  coverageLevel: number,
  coveredMilkProduction: number,
  protectionFactor: number,
  share: number,
}

const riskPremiumAPI = {
  get: async ({reinsuranceYear, stateCode, practiceCode, salesEffectiveDate, butterfatTest, proteinTest, classPriceWeightingFactor,
                coverageLevel, coveredMilkProduction, protectionFactor, share }: RiskPremiumService) => {
    let query = '';
    query = addQueryParameter(query, 'reinsuranceYear', reinsuranceYear);
    query = addQueryParameter(query, 'stateCode', String(stateCode).padStart(2, '0'));
    query = addQueryParameter(query, 'practiceCode', practiceCode);
    query = addQueryParameter(query, 'salesEffectiveDate', format(salesEffectiveDate, 'YYYY-MM-DD'));
    query = addQueryParameter(query, 'calculationParameters.butterfatTest', butterfatTest);
    query = addQueryParameter(query, 'calculationParameters.proteinTest', proteinTest);
    query = addQueryParameter(query, 'calculationParameters.classPriceWeightingFactor', classPriceWeightingFactor);
    query = addQueryParameter(query, 'calculationParameters.coverageLevel', coverageLevel);
    query = addQueryParameter(query, 'calculationParameters.coveredMilkProduction', coveredMilkProduction);
    query = addQueryParameter(query, 'calculationParameters.protectionFactor', protectionFactor);
    query = addQueryParameter(query, 'calculationParameters.share', share);
    return await api.get(query ? 'riskpremium?' + query : '');
  },
};

export default riskPremiumAPI;
