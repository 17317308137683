import { combineReducers } from 'redux';
import { organizationReducer } from './organization/reducers';
import { userReducer } from './users/reducers';
import { organizationReportsReducer } from './organizationReports/reducers';
import { reportsReducer } from './report/reducers';
import { viewReducer } from './view/reducers';
import { calendarsReducer } from './calendars/reducers';
import { calendarReportsReducer } from './calendarReports/reducers';
import { organizationUsersReducer } from './organizationUsers/reducers';

export const adminRootReducer = combineReducers({
  organization: organizationReducer,
  user: userReducer,
  organizationReports: organizationReportsReducer,
  report: reportsReducer,
  view: viewReducer,
  calendars: calendarsReducer,
  calendarReports: calendarReportsReducer,
  organizationUsers: organizationUsersReducer,
});
