import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { PeriodFrequency } from '../../types/PeriodFrequency';
import { productActions } from './actions';
import {  ActionType, getType } from 'typesafe-actions';
import { productPageActions } from '../productPage/actions';
import { Product, ProductWithAttributes, Chart } from '../../types/Product';
import { recordsActions } from '../records/actions';
import { unitsActions } from '../units/actions';
import { chartActions } from '../charts/actions';
import mapChartDtoToChart from '../charts/utils/mapChartDtoToChart';
import { ChartDto } from '../../types/dto/ChartDto';
import { toast } from 'react-toastify';
import dataAPI from '../../services/dataServices';
import { AxiosResponse } from 'axios';
import mapProductDtoToProduct from './utils/mapProductDtoToProduct';
import { ProductDto } from '../../types/dto/ProductDto';
import { contextTableActions } from '../contextTable/actions';
import { getProductPageAreaID, getProductPageFrequency } from '../productPage/selectors';

export function* addProduct(productData: ProductWithAttributes) {
  yield put(productActions.addProduct(productData));
}

export function* addChart(rawCharts: ChartDto[], productData: Product) {
  const charts = rawCharts.map(mapChartDtoToChart).filter(chart => chart.id !== 12 && chart.id !== 13);
  yield put(chartActions.add(charts, {
    productID: productData.product.id,
    frequency: productData.areas.defaultArea.frequency,
  }));
}

export interface ProductInfo {
  productID: number,
  areaID: number,
  chartID: number,
}

export interface ProductMeta {
  frequency: PeriodFrequency,
  unitID: number,
  lastReportPeriod: Date,
  scale: number,
}

export function* setProduct(productInfo: ProductInfo, productMeta: ProductMeta) {
  yield put(productPageActions.setProduct(productInfo, productMeta));
}

export interface InitFrequencyData {
  frequency: PeriodFrequency,
  areaID: number,
}

export interface AllProductData {
  productData: ProductWithAttributes,
  productInfo: ProductInfo,
  productMeta: ProductMeta,
  rawCharts: ChartDto[],
  initFrequencyData: InitFrequencyData,
}

export const setProductData = (productData: ProductWithAttributes): AllProductData => {
  const rawChartObject = {};
  productData.attributes.reduce((chartList: Chart[], { charts }) => chartList.concat(charts), []).forEach((chart: Chart) => {
    if (!rawChartObject[chart.id]) {
      rawChartObject[chart.id] = chart;
    } else {
      const baseChart = rawChartObject[chart.id];
      const transformationRaw = {};
      const normalizationsRaw = {};
      [...baseChart.transformations, ...chart.transformations].forEach((t) => transformationRaw[t.id] = t);
      [...baseChart.normalizations, ...chart.normalizations].forEach((n) => normalizationsRaw[n.id] = n);
      rawChartObject[chart.id] = {
        ...baseChart,
        transformations: Object.keys(transformationRaw).map(id => transformationRaw[id]),
        normalizations: Object.keys(normalizationsRaw).map(id => normalizationsRaw[id]),
      }
    }
  });
  const rawCharts = Object.keys(rawChartObject).map(id => rawChartObject[id]);
  const productMeta = {
    frequency: productData.areas.defaultArea.frequency,
    unitID: productData.unit.id,
    scale: productData.scale,
    lastReportPeriod: productData.summary.lastReportPeriod,
  };
  const productInfo = {
    productID: productData.product.id,
    areaID: productData.areas.defaultArea.id,
    chartID: rawCharts[0].id,
  };
  const initFrequencyData = {
    frequency: productData.areas.defaultArea.frequency,
    areaID: productData.areas.defaultArea.id,
  };
  return {productData, productInfo, productMeta, rawCharts, initFrequencyData};
};

export function* fetchProduct(action: ActionType<typeof productActions.initialize>) {
  try {
    yield put(productPageActions.pageStatus.loading());
    const productDto: AxiosResponse<ProductDto> = yield call(dataAPI.fetchProduct, action.payload, action.meta);
    const productData: ProductWithAttributes = yield mapProductDtoToProduct(productDto);
    const allProductData: AllProductData = yield setProductData(productData);
    yield addProduct(allProductData.productData);
    yield put(contextTableActions.series.get(action.payload, allProductData.productMeta.frequency));
    yield setProduct(allProductData.productInfo, {
      frequency: allProductData.productMeta.frequency,
      unitID: allProductData.productMeta.unitID,
      lastReportPeriod: allProductData.productData.summary.lastReportPeriod,
      scale: allProductData.productData.scale,
    });
    yield addChart(allProductData.rawCharts, allProductData.productData);
    if (allProductData.productMeta.frequency as any === 'Futures') {
      yield put(recordsActions.futures.initializeFutures());
    } else if (allProductData.rawCharts.some(({ id }) => id === 6)) {
      yield put(recordsActions.frequency.initializeFrequency(action.payload,  { ...allProductData.initFrequencyData, hasUsaMap: true }));
    } else {
      yield put(recordsActions.frequency.initializeFrequency(action.payload, allProductData.initFrequencyData));
    }
    yield put(unitsActions.update());
    yield put(productPageActions.pageStatus.loaded());
  } catch (error) {
    if (error.message.slice(0, 7) === 'timeout') {
      toast.error('Connection to server timed out. Please try again later.', {
        position: 'top-center',
        autoClose: 10000,
      });
    } else {
      toast.error(error.message, {
        position: 'top-center',
      });
    }
  }
}

export function* changeProductFrequency(action: ActionType<typeof productActions.changeProductFrequency>) {
  const areaID = yield select(getProductPageAreaID);
  try {
    const productDto:  AxiosResponse<ProductDto> = yield call(dataAPI.fetchProduct, action.payload, { ...action.meta, area: areaID });
    const productData: ProductWithAttributes = yield mapProductDtoToProduct(productDto);
    const allProductData: AllProductData = yield setProductData(productData);
    yield addProduct(allProductData.productData);
    yield setProduct(allProductData.productInfo, allProductData.productMeta);
    yield addChart(allProductData.rawCharts, allProductData.productData);
    yield put(recordsActions.frequency.fetchFrequency(action.payload, allProductData.initFrequencyData));
    yield put(unitsActions.update());
  } catch (e) {
    console.error(e);
  }
}

export function* changeProductArea(action: ActionType<typeof productActions.changeProductFrequency>) {
  const frequency = yield select(getProductPageFrequency);
  try {
    const productDto:  AxiosResponse<ProductDto> = yield call(dataAPI.fetchProduct, action.payload, { ...action.meta, frequency });
    const productData: ProductWithAttributes = yield mapProductDtoToProduct(productDto);
    const allProductData: AllProductData = yield setProductData(productData);
    yield addProduct(allProductData.productData);
    yield setProduct(allProductData.productInfo, allProductData.productMeta);
    yield addChart(allProductData.rawCharts, allProductData.productData);
    yield put(recordsActions.frequency.fetchFrequency(action.payload, allProductData.initFrequencyData));
    yield put(unitsActions.update());
  } catch (e) {
    console.error(e);
  }
}

function* productSagaWatcher() {
  yield all([
    takeLatest(getType(productActions.initialize), fetchProduct),
    takeLatest(getType(productActions.changeProductFrequency), changeProductFrequency),
    takeLatest(getType(productActions.changeProductArea), changeProductArea),
  ]);
}

export default productSagaWatcher;
