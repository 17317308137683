import produce from 'immer';
import { adminActions, AdminActions } from '../actions';
import { getType } from 'typesafe-actions';
import { Report } from '../../../types/Report';

const initialState: Report[] = [];

export const reportsReducer = produce<Report[], AdminActions>(
  (state, action) => {
    switch (action.type) {
      case getType(adminActions.report.add):
        return action.payload;
      default:
        return;
    }
  }, initialState,
);
