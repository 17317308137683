import { FuturesRaw, FuturesRecords } from '../../../../types/FuturesRecord';

const createFuturesRecord = (futuresRecordsRaw: FuturesRaw[]): FuturesRecords => {
  const futuresRecords: FuturesRecords = { allAreas: [] };
  const formatDate = (date: Date) =>
    Number(`${date.getFullYear()}${String(date.getMonth()).padStart(2, '0')}`);

  futuresRecordsRaw.forEach((record) => {
    const {
      areaID, contractMonth, volume, lowPrice, openPrice, highPrice, closePrice, reportPeriod, openInterest,
    } = record;
    const formattedDate = formatDate(new Date(contractMonth));
    if (!futuresRecords[areaID]) {
      futuresRecords[areaID] = {};
    }
    if (!futuresRecords[areaID][formattedDate]) {
      futuresRecords[areaID][formattedDate] = [];
    }
    futuresRecords[areaID][formattedDate].push({
      volume,
      lowPrice: Number(lowPrice),
      openPrice: Number(openPrice),
      highPrice: Number(highPrice),
      closePrice: Number(closePrice),
      contractMonth: new Date(contractMonth),
      reportPeriod: new Date(reportPeriod),
      openInterest,
    });
  });
  futuresRecords.allAreas = Object.keys(futuresRecords).filter(value => value !== 'allAreas').map(Number);
  return futuresRecords;
};

export default createFuturesRecord;
