import * as React from 'react';
import styled from 'styled-components';
import color from 'src/utils/color';
import { connect } from 'react-redux';
import { menuActions } from '../../../ducks/menu/actions';
import { RootState } from '../../../ducks/store';
import { getNavMenuItems } from '../../../ducks/menu/selectors';
import { RouteComponentProps, withRouter } from 'react-router';
import { NavMenuDto } from '../../../types/dto/NavMenuDto';

const NavBarContentWrapper = styled.div`
  margin: 0;
  padding-top: 25px;
  width: 100%;
  background-color: ${color.BACKGROUND_SECONDARY};
  box-shadow:
    0 3px 6px ${color.NEUTRAL},
    inset 0 3px 6px ${color.NEUTRAL};
  z-index: 50;
`;

const NavBarMenuWrapper = styled.div`
  max-width: 1100px;
  margin: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0 0 15px 25px;
`;

const ModuleButton = styled.div<{isSelected: boolean, index: number}>`
  height: 20px;
  font-size: 16px;
  padding: 5px 15px 15px 15px;
  margin-left: ${({ index }) => index === 0 ? null : '50px'};
  text-align: center;
  cursor: pointer;
  border-bottom: ${({ isSelected }) => isSelected && `${color.PRIMARY} solid 2px`};
  box-shadow: ${({ isSelected }) => isSelected && `0 5px 5px -4px ${color.PRIMARY}`};
  margin-bottom: ${({ isSelected }) => isSelected && '-2px'};

  &:hover {
    border-bottom: ${color.PRIMARY} solid 2px;
    box-shadow: 0 5px 5px -4px ${color.PRIMARY};
    margin-bottom: -2px;
  }
`;

interface State {
  selectedMenuItem: number,
}

type Props = RouteComponentProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

class NavBarContent extends React.Component<Props, State> {
  public state = {
    selectedMenuItem: -1,
  };

  public componentDidMount() {
    this.props.getNavMenuItems();
    const separatedURL = this.props.location.pathname.split('/');

    if (this.props.location.pathname === '/menu' || this.props.location.pathname === '/menu/') {
      this.setState({ selectedMenuItem: 1 });
      this.props.history.push('/menu/1');
    } else if (this.props.location.pathname === '/catalog') {
      this.setState({ selectedMenuItem: this.props.navMenuItems.length });
    } else if (separatedURL[1] === 'menu') {
      this.setState({selectedMenuItem: Number(separatedURL[2])});
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps !== this.props && !(this.props.location.pathname.includes('/menu') || this.props.location.pathname.includes('/catalog'))) {
      this.setState({ selectedMenuItem: -1 });
    }

    if (prevProps.navMenuItems.length !== this.props.navMenuItems.length && this.props.location.pathname.includes('/catalog')) {
      this.setState({ selectedMenuItem: this.props.navMenuItems.length });
    }
  }

  public render() {
    const { selectedMenuItem } = this.state;
    const { navMenuItems } = this.props;

    return (
      <NavBarContentWrapper>
        <NavBarMenuWrapper>
          <ButtonContainer>
            {navMenuItems.length ? navMenuItems.map((item, index) =>
              <ModuleButton
                key={item.id}
                index={index}
                onClick={this.selectedMenuItemHandler(item)}
                isSelected={item.id === selectedMenuItem}
              >{item.text}
              </ModuleButton>)
              : <div style={{height: 45}}/>}
            <ModuleButton
              index={navMenuItems.length}
              onClick={this.onCatalogClick}
              isSelected={navMenuItems.length === selectedMenuItem}
            >Catalog Beta</ModuleButton>
          </ButtonContainer>
        </NavBarMenuWrapper>
      </NavBarContentWrapper>
    )
  }

  private onCatalogClick = () => {
    this.setState({ selectedMenuItem: this.props.navMenuItems.length });
    this.props.history.push(`/catalog`);
  };

  private selectedMenuItemHandler = (item: NavMenuDto) => () => {
    this.setState({ selectedMenuItem: item.id });
    this.props.setSelectedMenu(item);
    this.props.history.push(`/menu/${item.id}`);
  };
}

const mapStateToProps = (state: RootState) => ({
  navMenuItems: getNavMenuItems(state),
});

const mapDispatchToProps = {
  getNavMenuItems: menuActions.navMenu.initialize,
  setSelectedMenu: menuActions.navMenu.setSelectedMenu,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBarContent));
