import { ActionType, createStandardAction } from 'typesafe-actions';
import { NavMenuDto } from '../../types/dto/NavMenuDto';
import { Menu } from '../../types/Menu';

export const menuActions = {
  navMenu: {
    initialize: createStandardAction('@navMenu/INITIALIZE_NAV_MENU_ITEMS')(),
    add: createStandardAction('@navMenu/ADD_NAV_MENU_ITEMS')<NavMenuDto[]>(),
    setSelectedMenu: createStandardAction('@navMenu/SET_SELECTED_MENU')<NavMenuDto>(),
  },
  menu: {
    get: createStandardAction('@menu/GET')<number>(),
    add: createStandardAction('@menu/ADD')<Menu[], number>(),
  },
};

export type MenuActions = ActionType<typeof menuActions>;
