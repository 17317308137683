import * as React from 'react';
import * as oidc from 'oidc-client';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import color from './utils/color';

type Props = RouteComponentProps<{ type: string }>;

const Root = styled.div`
  background-color: ${color.BACKGROUND_SECONDARY};
`;

const NavBar = styled.div`
  box-shadow: 0 3px 6px ${color.NEUTRAL};
  background-color: ${color.BACKGROUND};
  width: 100%;
  text-align: center;
  margin-bottom: 6px;
  height: 36px;
`;

const Container = styled.div`
  width: 100%;
  margin: auto;
  max-width: 1400px;
  min-height: 100vh;
  background-color: ${color.BACKGROUND};
`;

const callback: React.FC<Props> = ({ match, history }) => {
  switch (match.params.type) {
    case 'login':
      new oidc.UserManager({}).signinRedirectCallback()
        .then(() => { history.push('/'); })
        .catch( (e: any) => { console.error(e); });
        break;
    case 'refresh':
      new oidc.UserManager({}).signinSilentCallback()
        .catch( (e: any) => {console.error(e); });
      break;
    case 'logout':
      new oidc.UserManager({}).signoutRedirectCallback()
        .then(() => { history.push('/'); })
        .catch( (e: any) => { console.error(e); });
      break;
    default:
      console.error('no callback case, ');
      history.push('/');
  }
  return (
    <Root>
      <NavBar />
      <Container>
        <div>loading...</div>
      </Container>
    </Root>
  );
};

export default callback;
