import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './ducks/store';
import callback from './callback';

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path="/callback/:type" component={callback} />
            <Route path="/" component={App} />
          </Switch>
        </BrowserRouter>
    </Provider>,
  document.getElementById('root') as HTMLElement,
);
registerServiceWorker();
