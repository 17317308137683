import * as React from 'react';
import styled from 'styled-components';
import pallet from '../../../utils/color';

const TableHead = styled.th<{ color: string }>`
  background-color: ${({ color }) => color}23;
  border-bottom: solid 2px ${({ color }) => color};
  text-align: right;

  &:first-child {
    text-align: left;
  }
`;

interface Props {
  color?: string,
}

const TableHeader: React.FC<Props> = ({ color = pallet.PRIMARY, children }) => <TableHead color={color}>{children}</TableHead>;

export default TableHeader;
