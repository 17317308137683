import produce from 'immer';
import { User } from '../../../types/User';
import { adminActions, AdminActions } from '../actions';
import { getType } from 'typesafe-actions';

const initialState: User[] = [];

export const userReducer = produce<User[], AdminActions>(
  (state, action) => {
    switch (action.type) {
      case getType(adminActions.users.add):
        return action.payload;
      case getType(adminActions.users.remove): {
        const index = state.findIndex(user => user.userName === action.payload);
        if (index !== -1) {
          state.splice(index, 1);
        }
        return;
      }
      case getType(adminActions.users.addOne):
        state.push({
          userId: action.payload.email,
          userName: action.payload.email,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          emailConfirmed: action.payload.emailConfirmed,
          isInvitationExpired: action.payload.isInvitationExpired,
        });
        return;
      case getType(adminActions.users.edit): {
        const index = state.findIndex(user => user.userName === action.meta);
        if (index !== -1) {
          state[index] = {
            ...state[index],
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            email: action.payload.email,
          };
        }
        return;
      }
      default:
        return state;
    }
  }, initialState,
);
